import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC, useState } from "react";
import { currentUserVar } from "apollo/cache/auth";
import { PieChart } from "components";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
import numeral from "numeral";
import { classNames } from "utils";
import { currentConfigVar } from "apollo/cache/config";
import { ButtonGroup } from "components/buttons/button-group";
dayjs.extend(weekOfYear);

interface AdmittedLevelChartContainerProps {
  status?: "PENDING" | "SUBMITTED";
  cycle?: string;
}

const LevelChart = ({ status, cycle }: { status?: string; cycle?: string }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = cycle && {
    cycle: { eq: cycle },
  };

  const GET_ADMITTED_STATS = gql`
  query Query(
    $undergraduateFilter: AdmissionFilter
    $graduateFilter: AdmissionFilter
  ) {
    undergraduate: getAdmissionsCount(filter: $undergraduateFilter)
    graduate: getAdmissionsCount(filter: $graduateFilter)
  }
  `;

  const { loading, data } = useQuery(GET_ADMITTED_STATS, {
    variables: {
      undergraduateFilter: {
        applicationLevel: {
          eq: "UNDERGRADUATE",
        },
        ...(status && {
          status: {
            eq: status,
          },
        }),
        ...cycleFilters,
      },
      graduateFilter: {
        applicationLevel: {
          eq: "GRADUATE",
        },
        ...(status && {
          status: {
            eq: status,
          },
        }),
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
    pollInterval,
  });
  const pieData = [
    {
      color: "#017CB8",
      bgColor: "bg-blue-600",
      id: "Undergraduates",
      label: "Undergraduates",
      value: data?.undergraduate || 0,
    },
    {
      color: "#d352e9",
      bgColor: "bg-blue-400",
      id: "Graduates",
      label: "Graduates",
      value: data?.graduate || 0,
    },
  ];

  return (
    <>
      <PieChart data={pieData} />
      <div className="flex flex-row justify-center">
        {pieData?.map?.((item: any) => (
          <div
            key={item?._id}
            className="rounded-lg bg-white p-2 flex space-x-4"
          >
            <div>
              <div
                style={{ backgroundColor: item.color }}
                className={classNames(
                  "rounded-md flex items-center justify-center p-2.5"
                )}
              >
                <span className="text-lg text-white" aria-hidden="true"></span>
              </div>
            </div>
            <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
              <div>
                <dt className="truncate text-xs text-gray-500">
                  {item?.label}
                </dt>
                <dd className="flex items-baseline justify-between">
                  <p className="text-xl font-normal text-gray-900">
                    {numeral(item?.value).format("0,0")}
                  </p>
                </dd>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const TypeChart = ({ status, cycle }: { status?: string; cycle?: string }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const GET_ADMITTED_STATS = gql`
    query Query(
      $localFilter: AdmissionFilter
      $internationalFilter: AdmissionFilter
    ) {
      local: getAdmissionsCount(filter: $localFilter)
      international: getAdmissionsCount(filter: $internationalFilter)
    }
  `;

  const cycleFilters = cycle && {
    cycle: { eq: cycle },
  };

  const { loading, data } = useQuery(GET_ADMITTED_STATS, {
    variables: {
      localFilter: {
        applicationType: {
          eq: "LOCAL",
        },
        ...(status && {
          status: {
            eq: status,
          },
        }),
        ...cycleFilters,
      },
      internationalFilter: {
        applicationType: {
          eq: "INTERNATIONAL",
        },
        ...(status && {
          status: {
            eq: status,
          },
        }),
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
    pollInterval,
  });

  const pieData = [
    {
      color: "#89BD2E",
      bgColor: "bg-blue-600",
      id: "local",
      label: "Local",
      value: data?.local || 0,
    },
    {
      color: "#F6C609",
      bgColor: "bg-blue-400",
      id: "International",
      label: "International",
      value: data?.international || 0,
    },
  ];

  return (
    <>
      <PieChart data={pieData} />
      <div className="flex flex-row justify-center">
        {pieData?.map?.((item: any) => (
          <div
            key={item?._id}
            className="rounded-lg bg-white p-2 flex space-x-4"
          >
            <div>
              <div
                style={{ backgroundColor: item.color }}
                className={classNames(
                  "rounded-md flex items-center justify-center p-2.5"
                )}
              >
                <span className="text-lg text-white" aria-hidden="true"></span>
              </div>
            </div>
            <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
              <div>
                <dt className="truncate text-xs text-gray-500">
                  {item?.label}
                </dt>
                <dd className="flex items-baseline justify-between">
                  <p className="text-xl font-normal text-gray-900">
                    {numeral(item?.value).format("0,0")}
                  </p>
                </dd>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const AdmittedLevelChartContainer: FC<AdmittedLevelChartContainerProps> = ({
  status,
  cycle,
}) => {
  const [chartType, setChartType] = useState("LEVEL");

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Admitted Groups
          </h3>
          <p className="text-xs text-gray-400">
            Displays admitted demography
          </p>
        </div>

        <div className="flex flex-col">
          <ButtonGroup
            buttons={[
              { label: "Level", value: "LEVEL" },
              { label: "Type", value: "TYPE" },
            ]}
            onChangeValue={(value) => setChartType(value || "")}
            selectedValue={chartType}
          />
        </div>
      </div>
      {chartType === "LEVEL" ? (
        <LevelChart status={status} cycle={cycle} />
      ) : (
        <TypeChart status={status} cycle={cycle} />
      )}
    </>
  );
};

export default AdmittedLevelChartContainer;
