import { wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { DepartmentForm, Modal } from 'components';
import { DepartmentFormProps } from 'components/forms/department';
import { DepartmentSchema } from 'components/forms/department/schema';

const CREATE_DEPARTMENT = gql`
mutation CreateDepartment($input: CreateDepartmentInput!) {
  createDepartment(input: $input) {
    _id
  }
}
`

export default function CreateDepartmentContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void}) {
  
  const [createDepartment, { loading }] = useMutation(CREATE_DEPARTMENT)

  const form = useFormik<DepartmentFormProps["form"]["values"]>({
    initialValues: {
      head: "",
      code: "",
      name: "",
      digitalAddress: "",
      officeAddress: "",
      phone: "",
      school: "",
      email: ""
    },
    validationSchema: DepartmentSchema,
    onSubmit: async ({code, ...values}) => {
      await createDepartment({
        variables: {
          input: {
            ...values,
            head: values?.head?._id
          }
        }
      }).then(({ data }) => {
        if (data.createDepartment._id) {
          toast(JSON.stringify({ type: "success", title: "Department Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Department" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Department"
      description="Provide the details to add a new department"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Department..." : "Add Department"}
          </button>

        </>
      )}>
      <DepartmentForm
        form={form}
      />
    </Modal>
  )
}