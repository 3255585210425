import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import config from "config";
import _ from "lodash";
import { wrapClick, wrapImage } from "utils";
import { DocumentIcon, DocumentTextIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { extractFirebaseName } from "utils/general";

axios.defaults.baseURL = config.rest.uri;

interface DocumentUploadProps {
  id: string;
  maxSize?: number;
  minSize?: number;
  disabled?: boolean;
  label?: string;
  values: any;
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  errors?: any;
  touched?: any;
  required?: boolean;
}

interface Upload extends File {
  preview: string;
}


type DocumentType = {
  name: string;
  size?: number;
  file: any;
  isUploaded?: boolean;
  downloadUrl?: string;
};

const DocumentComponent = ({ file, loading }: { file: string, loading?: boolean }) => {
  const [fileDocument, setFileDocument] = useState<DocumentType>();

  useEffect(() => {
    if (file && file !== "") {
      const name = extractFirebaseName(file) || ""
      setFileDocument({
        name: name,
        downloadUrl: file,
        file: undefined,
        size: undefined,
        isUploaded: true
      })
    }
  }, [file]);

  return (
    <div className="w-full h-24 flex flex-row border border-gray-200 rounded-md px-3 py-4 mt-3">
      <span className="p-2 flex-shrink-0 bg-blue-100 self-start rounded-full mr-3">
        <DocumentIcon className="w-5 h-5 text-primary-500" />
      </span>

      <div className="flex flex-col w-full justify-between">
        <div className="flex flex-row justify-between w-full">
          {
            loading ? (
              <span className="font-semibold m-0 text-sm">
                <p className="text-primary-500 underline">Loading...</p>
              </span>
            )
              :
              <span className="font-semibold m-0 text-sm">
                {fileDocument?.name}  (<a className="text-primary-500 underline" target="_blank" href={fileDocument?.downloadUrl}>View</a>)
              </span>
          }
        </div>
        <span className="text-gray-400">
        </span>

        <div className="w-full hidden relative">
          <div
            style={{ width: "100%" }}
            className="rounded-full absolute flex-shrink-0 bg-blue-100 z-10 h-2"
          ></div>
          <div
            style={{ width: !loading ? "100%" : "0%" }}
            className="rounded-full absolute duration-300 flex-shrink-0 bg-primary h-2 z-30"
          ></div>
        </div>
      </div>
    </div>
  )
}


const DocumentUpload: React.FC<DocumentUploadProps> = ({
  maxSize = 1024 * 1024,
  minSize = 1,
  errors,
  values,
  setFieldValue,
  touched,
  id,
  label,
  setFieldTouched,
  setFieldError,
  required = false,
}) => {
  const [files, setFiles] = useState<Upload[]>([]);
  const [loading, setLoading] = useState<boolean>(false)

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      // upload image to server and return url
      if (acceptedFiles.length) {
        setLoading(true);
        const data = new FormData();
        const file = acceptedFiles[0];
        data.append("file", file);
        axios
          .post("/assets/upload", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            if (data) {
              setFieldValue?.(id, data as string);
              setFieldTouched?.(id, false, true);
            }
          })
          .catch((err) => {
            setFieldError?.(id, err.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [id, setFieldValue]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxSize,
    minSize,
    noClick: true,
    noKeyboard: true,
    disabled: loading,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [files]
  );

  const file = _.get(values, id)

  console.log({ file, values })

  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label} {required ? <span className="text-red-500">*</span> : ""}
      </label>
      <div {...getRootProps({ className: "mt-1 flex items-center" })}>
        <input
          {...getInputProps()}
          required={required}
          id="file"
          name="file"
          type="file"
          className="sr-only"
        />
        {!(files?.[0]?.preview || _.get(values, id)) ? (
          <div
            onClick={wrapClick(open)}
            className="space-y-1 w-full h-full border border-gray-400 rounded-md border-dashed flex-col items-center justify-center text-center px-6 pt-5 pb-6">
            <PaperClipIcon className="w-10 h-10 text-gray-500 mx-auto" strokeWidth={1} />
            <div className="flex text-sm justify-center text-gray-600" >
              <span className="text-center cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
                {loading ? "Uploading..." : "Upload a file"}
              </span>
            </div>
            {/* <p className="text-xs text-gray-500">or drag and drop</p> */}
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <div
              onClick={wrapClick(open)}
              className="space-y-1 w-full h-full border border-gray-400 rounded-md border-dashed flex-col items-center justify-center text-center px-6 pt-5 pb-6">
              <div className="self-center align-middle flex flex-row justify-center">
                <PaperClipIcon className="w-10 h-10 text-gray-500" strokeWidth={1} />
              </div>
              <div className="flex text-sm justify-center text-gray-600" >
                <span className="text-center cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
                  {loading ? "Uploading..." : "Upload a file"}
                </span>
              </div>
              {/* <p className="text-xs text-gray-500">or drag and drop</p> */}
            </div>
            <DocumentComponent
              loading={loading}
              file={_.get(values, id) || files?.[0]?.preview}
            />
          </div>
        )}

      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default DocumentUpload;
