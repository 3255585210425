import { wrapClick } from 'utils';
import { gql, useMutation, } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, VendorForm } from 'components';
import { VendorSchema } from 'components/forms/vendor/schema';

const CREATE_VENDOR = gql`
  mutation CreateVendor($input: VendorInput!) {
    createVendor(input: $input) {
      _id
    }
  }
`;

export default function CreateVendorContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const [createVendor, { loading }] = useMutation(CREATE_VENDOR)

  const form = useFormik({
    initialValues: {
      code: "",
      email: "",
      name: "",
      phone: "",
      description: "",
    },
    validationSchema: VendorSchema,
    onSubmit: async ({ code, ...values }) => {
      await createVendor({
        variables: {
          input: {
            ...values
          }
        }
      }).then(({ data }) => {
        if (data.createVendor._id) {
          toast(JSON.stringify({ type: "success", title: "Vendor created successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Vendor" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Vendor"
      description="Provide the details to add a new vendor"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Vendor..." : "Add Vendor"}
          </button>

        </>
      )}>
      <VendorForm
        form={form}
      />
    </Modal>
  )
}