import { gql, useQuery } from "@apollo/client";
import { AdmissionCyclePicker, AdmissionProgrammePicker } from "containers";

import { FormikProps } from "formik";
import { FC, useEffect } from "react";

interface AdmissionTransferFormProps {
  form: FormikProps<{
    destinationAdmissionProgrammeId: string;
    destinationCycleId: string;
    sourceAdmissionProgrammeId: string;
    sourceCycleId: string;
  }>;
}

const GET_ADMISSION_PROGRAMME = gql`
  query GetAdmissionProgramme($filter: AdmissionProgrammeFilter!) {
    programme: getAdmissionProgramme(filter: $filter) {
      _id
      code
      name
      programme {
        _id
      }
    }
  }
`;

const GET_TRANSFER_COUNT = gql`
query Query($filter: AdmissionFilter) {
  getAdmissionsCount(filter: $filter)
}
`;

const AdmissionTransferForm: FC<AdmissionTransferFormProps> = ({ form }) => {
  const { loading, data } = useQuery(GET_ADMISSION_PROGRAMME, {
    variables: {
      filter: {
        ...(form?.values?.sourceCycleId
          ? {
            cycle: {
              eq: form?.values?.sourceCycleId || "",
            },
          }
          : {}),
        ...(form?.values?.sourceAdmissionProgrammeId
          ? {
            _id: {
              eq: form?.values?.sourceAdmissionProgrammeId || "",
            },
          }
          : {}),
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: false,
  });

  const {
    loading: transferCountLoading,
    data: transferCountData,
    refetch: refetchTransferCount,
  } = useQuery(GET_TRANSFER_COUNT, {
    variables: {
      filter: {
        cycle: {
          eq: form?.values?.sourceCycleId || ""
        },
        programme: {
          eq: form?.values?.sourceAdmissionProgrammeId || "",
        }
      }
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: false,
    skip: !form?.values?.sourceCycleId || !form?.values?.sourceAdmissionProgrammeId,
  });



  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Source Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <AdmissionCyclePicker
              id="sourceCycleId"
              label="Source Cycle"
              rawId={true}
              disabled
              {...form}
            />
          </div>

          <div>
            <AdmissionProgrammePicker
              id="sourceAdmissionProgrammeId"
              label="Source Programme"
              rawId={true}
              filter={{
                cycle: {
                  eq: form.values?.sourceCycleId || "",
                },
              }}
              {...form}
            />
          </div>
        </div>
      </div>

      <div>
        <span className="text-xs font-light">Destination Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <AdmissionCyclePicker
              id="destinationCycleId"
              label="Destination Cycle"
              rawId={true}
              filter={{
                _id: {
                  notEq: form.values.sourceCycleId,
                },
                closingDate: {
                  after: new Date().toISOString(),
                },
              }}
              {...form}
            />
          </div>

          {form?.values?.destinationCycleId &&
            form?.values?.sourceAdmissionProgrammeId && (
              <div>
                <AdmissionProgrammePicker
                  id="destinationAdmissionProgrammeId"
                  label="Destination Programme"
                  rawId={true}
                  filter={{
                    ...(form?.values?.destinationCycleId
                      ? {
                        cycle: {
                          eq: form?.values?.destinationCycleId || "",
                        },
                      }
                      : {}),
                    ...(data?.programme?.programme?._id
                      ? {
                        programme: {
                          eq: data?.programme?.programme?._id || "",
                        },
                      }
                      : {}),
                  }}
                  disabled={loading}
                  {...form}
                />
              </div>
            )}

          {form?.values?.sourceAdmissionProgrammeId &&
            form?.values?.sourceCycleId && (
              <div className="mt-5 w-full col-span-2">
                <h1 className="font-medium mb-3">Transfer count</h1>
                <h2>
                  {transferCountData?.getAdmissionsCount || 0} applicants are being transferred from this cycle
                </h2>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default AdmissionTransferForm;
