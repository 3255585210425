import { useReactiveVar, gql, useLazyQuery } from "@apollo/client";
import { currentUserVar } from "apollo/cache/auth";
import { RadioInput, TextArea } from "components/core"
import { AdmissionProgrammePicker, CampusPicker, DepartmentPicker, SchoolPicker } from "containers";
import { FormikProps } from "formik"
import { FC, useEffect } from "react"
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import _ from "lodash";

interface OfferAdmissionFormProps {
  form: FormikProps<{
    school: any;
    department: any;
    programme: any;
    campus: any;
    level: any;
    offeringType: any;
    offeredProgrammeRemark: any;
  }>
}

const GET_ADMISSION_PROGRAMME = gql`
query GetAdmissionProgramme($filter: AdmissionProgrammeFilter!) {
  admissionProgramme:getAdmissionProgramme(filter: $filter) {
    _id
    offerings {
      types
    }
  }
}
`

const OfferAdmissionsForm: FC<OfferAdmissionFormProps> = ({ form }) => {
  const { params } = useMatch<LocationGenerics>();
  const currentUser = useReactiveVar(currentUserVar);

  const [getAdmissionProgramme, { loading, data }] = useLazyQuery(GET_ADMISSION_PROGRAMME)

  useEffect(() => {
    if (form?.values?.programme && form?.values?.campus) {
      getAdmissionProgramme({
        variables: {
          filter: {
            _id: {
              eq: form?.values?.programme
            },
            "offerings": {
              "campus": {
                "eq": form?.values?.campus
              }
            }
          }
        }
      })
    }
  }, [form?.values]);


  const offeringTypes = data?.admissionProgramme?.offerings?.[0]?.types || []


  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <div className="grid grid-cols-1 gap-6 mt-2">
          <div className="col-span-1">
            <CampusPicker
              id="campus"
              label="Campus"
              placeholder="e.g. Kumasi Campus"
              required={true}
              disabled={true}
              {...form}
              rawId={true}
            />
          </div>
          <div className="col-span-1">
            <SchoolPicker
              id="school"
              label="School"
              placeholder="e.g. Accra East School"
              required={true}
              disabled={!!currentUser?.school?._id}
              {...form}
              rawId={true}
            />
          </div>
          <div className="col-span-1">
            <DepartmentPicker
              id="department"
              label="Department"
              placeholder="e.g. Computer Science"
              required={true}
              {...form}
              disabled={!!currentUser?.department?._id}
              rawId={true}
            />
          </div>
          <div className="col-span-1">
            <AdmissionProgrammePicker
              id="programme"
              label="Programme"
              placeholder="e.g. Computer Science"
              required={true}
              {...form}
              disabled={!form?.values?.department}
              filter={{
                cycle: { eq: params.admissionCycle as string },
                ...(form?.values?.department && {
                  department: {
                    eq: form?.values?.department
                  }
                }),
                ...(form?.values?.level && {
                  level: {
                    eq: form?.values?.level
                  }
                }),
                deleted: { eq: false }
              }}
              rawId={true}
            />
          </div>
          {
            form.values?.programme &&
            <div className="col-span-1">
              <RadioInput
                id="offeringType"
                label="Offering Type"
                required={true}
                options={
                  offeringTypes.map((type: string) => ({ label: _.capitalize(type), value: type })) || []
                }
                {...form}
              />
            </div>
          }

          <div className="col-span-1">
            <TextArea
              id="offeredProgrammeRemark"
              label="Offered Programme Remark"
              placeholder="eg. You do not qualify for..."
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferAdmissionsForm