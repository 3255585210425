import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { SelectInput, Shimmers, TableComponent } from 'components';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo } from 'react'
import { useMatch, useMatches, useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, classNames, useDownloadFile, withPermissions } from 'utils';
import config from 'config';
import { currentConfigVar } from 'apollo/cache/config';
import ActionButton, { Action } from 'components/buttons/action-button';
import lodash from 'lodash';
import { useToggle } from 'react-use';
import ViewAdmittedContainer from './view';
import AdmissionProgrammePickerContainer from 'containers/admission-programme-picker';
import { currentUserVar } from 'apollo/cache/auth';
import toast from 'react-hot-toast';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import EnrollContainer from './enrolled';
import { ExportDataContainer, SchoolPicker } from 'containers';
import _ from 'lodash';
import DownloadProfile from './download-profile';

const GET_ADMISSIONS = gql`
query GetAdmissions($filter: AdmissionFilter, $pagination: Pagination, $populate: [String], $sort: AdmissionSort, $search: SearchOperator) {
  items:getAdmissions(filter: $filter, pagination: $pagination, populate: $populate, sort: $sort, search: $search) {
    _id
    applicant {
      code
      email
      _id
    }
    code
    programme {
      name
      _id
      code
      department {
        code
        name
      }
      level
    }
    bankName
    bankBranch
    updatedAt
    createdAt
    application {
      _id
      personalInformation {
        firstName
        lastName
      }
    }
  }
  itemsCount:getAdmissionsCount(filter: $filter, search: $search )
}
`

const CREATE_EXPORT = gql`
  query GetExportFileUrl($filter: JSON){
    url: getAdmissionsFileUrl(filter: $filter)
  }
`

const EnrollmentPage: FC = () => {
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [admissionProgramme, setAdmissionProgramme] = useUrlState("admissionProgramme");
  const [voucherClassesExpanded, toggleVoucherClassesExpanded] = useToggle(false)
  const [level, setLevel] = useUrlState("level");
  const [school, setSchool] = useUrlState("school");
  const matches = useMatches<LocationGenerics>()
  const cycle = matches?.[0]?.params?.admissionCycle

  useEffect(() => {
    setSchool(currentUser?.school?._id)
  }, [currentUser]);

  const variables = useMemo(() => ({
    pagination: {
      limit: searchParams.pageSize || 12,
      skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
    },
    ...((searchParams?.search || "")?.length > 0 ? {
      search: {
        query: searchParams?.search,
        options: ["CaseInsensitive"],
        fields: ["applicationName", "applicationEmail", "applicationCode"]
      }
    } : {}),
    filter: {
      cycle: {
        eq: params.admissionCycle
      },
      ...(admissionProgramme && { programme: { eq: admissionProgramme } }),
      ...(school && { school: { eq: school } }),
      ...(level && { applicationLevel: { eq: level } }),
      ...({ enrolled: { eq: true } })
    },
    sort: {

    },
    populate: ["applicant", "programme", "programme.department", "application.personalInformation"]
  }), [searchParams]);

  const { data, loading, networkStatus, refetch } = useQuery(GET_ADMISSIONS, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
        ...(!lodash.isNumber(old?.month) ? { month: dayjs().month() } : {}),
        ...(!old?.year ? { year: dayjs().year() } : {}),
      })
    })
  }, [navigate])

  const [createDataExport, { loading: loadingExport }] = useLazyQuery(CREATE_EXPORT, {
    fetchPolicy: "no-cache"
  })

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(JSON.stringify({ type: "error", title: "An error occurred downloading file" }))
    },
    getFileName: () => new Date().toISOString() + "Admissions" + "_.csv",
  });

  const handleExportEnrolled = () => {
    createDataExport({
      variables: {
        filter: variables?.filter,
      },
      fetchPolicy: "no-cache"
    }).then(({ data }) => {
      console.log("datum", data)
      if (data.url) {
        window.open(data.url, "_blank");
        // downloadAction(`/public/${data.url}`)
      }
      else {
        toast(JSON.stringify({ type: "error", title: "Could not export file" }))
      }
    })
  }

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-full bg-gray-50">

      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'accepted'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                {
                  school && (
                    <AdmissionProgrammePickerContainer
                      id="admissionProgramme"
                      labelHidden={true}
                      rawId={true}
                      setFieldValue={(field: any, value: string) =>
                        setAdmissionProgramme(value)
                      }
                      filter={{
                        cycle: { eq: params.admissionCycle as string },
                        deleted: { eq: false },
                        ...(school && { school: { eq: school } }),
                      }}
                      values={{}}
                    />
                  )
                }

                {
                  !currentUser?.school?._id && (
                    <SchoolPicker
                      id="school"
                      labelHidden={true}
                      rawId={true}
                      setFieldValue={(field: any, value: string) =>
                        setSchool(value)
                      }
                      filter={{
                        cycle: { eq: params.admissionCycle as string },
                        deleted: { eq: false },
                        ...(school && { school: { eq: school } }),
                      }}
                      disabled={!!currentUser?.school?._id}
                      values={{ school }}
                    />
                  )
                }

                <SelectInput
                  id="level"
                  label=""
                  labelHidden
                  placeholder="Filter Level"
                  options={["UNDERGRADUATE", "GRADUATE"]}
                  handleChange={(event: any) => {
                    setLevel(event.target.value)
                  }}
                  handleBlur={() => null}
                  values={{ level }}
                />
                {
                  withPermissions(["*:*", "enrollment:*", "enrollment:export"])(
                    <button
                      type="button"
                      disabled={loading}
                      className="w-full justify-center h-[38px] gap-x-2 flex flex-row rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleExportEnrolled}
                    >
                      <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
                      {loadingExport ? "Downloading..." : "G-Tech Sheet"}
                    </button>
                  )
                }
              </div>
            )}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="Admission"
                variables={variables}
              />
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Applicant
                </th>
                {
                  !admissionProgramme &&
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Programme
                  </th>
                }
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Level
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Bank
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Paid On
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100 uppercase">{[item?.application?.personalInformation?.lastName, item?.application?.personalInformation?.firstName].join(" ") || "N/A"}</div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">{item?.applicant?.email || "N/A"}</div>
                </td>
                {
                  !admissionProgramme &&
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">{item?.programme?.name || "N/A"}</div>
                  </td>
                }
                <td className="whitespace-nowrap px-6 py-4 text-sm text-center text-gray-500 border-b border-gray-200 ">
                  <span
                    className={classNames(
                      item?.programme?.level === "GRADUATE" ? `bg-orange-100 text-orange-800 dark:bg-[#F87A17] dark:text-gray-50` : "",
                      item?.programme?.level === "UNDERGRADUATE" ? `bg-primary-100 text-primary-800 dark:bg-[#657383] dark:text-gray-50` : "",
                      !item?.programme?.level ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                      <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                    </svg>
                    <span>
                      {item?.programme?.level || "Unknown"}
                    </span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="text-gray-900 dark:text-gray-100 text-center">{item?.bankName || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400 text-center">{_.startCase(item?.bankBranch) || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-center">
                  <div className="text-gray-900 dark:text-gray-100">{item?.createdAt ? dayjs(item?.createdAt).format("MMM DD, YYYY - HH:mm") : "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action='download'
                      onClick={dispatchAction(item?._id, "download")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      <EnrollContainer
        open={modal === "assign"}
        setOpen={(val: boolean) => setModal(val ? "assign" : undefined)}
      />

      {!!searchParams.id?.length && (
        <>
          <ViewAdmittedContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />

          <DownloadProfile
            open={modal === "download"}
            setOpen={(val: boolean) => setModal(val ? "download" : undefined)}
          />
        </>

      )}
    </main>
  )
}

export default EnrollmentPage;
