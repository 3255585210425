import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate, useSearch } from "react-location";
import { withPermissions, wrapClick } from "utils";

import { Action } from "components/buttons/action-button";
import AdmittedView from "components/cards/admitted-applicant-view";
import { LocationGenerics } from "router/location";
import { Modal } from "components";
import { currentConfigVar } from "apollo/cache/config";

const GET_ADMISSION = gql`
query GetAdmission($filter: AdmissionFilter!, $populate: [String]) {
  admission: getAdmission(filter: $filter, populate: $populate) {
    _id
    applicant {
      code
      email
      _id
    }
    code
    programme {
      name
      _id
      code
      department {
        code
        name
      }
      level
    }
   campus {
      _id
      name
   }
    bankTransactionImage
    updatedAt
    createdAt
    application {
      code
      personalInformation {
        firstName
        lastName
        profileImageUrl
      }
      _id
    }
    shortlist {
      startingYear
      offeredProgramme {
        _id
        name
      }
      offeredProgrammeRemark
    }
    department {
      name
      _id
    }
    school {
      _id
      name
    }
    createdBy {
      _id
      name
    }
    lastUpdatedBy {
      _id
      name
    }
    indexNumber
  }
}
`;

export default function ViewAdmissionsContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_ADMISSION, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["programme", "applicant", "programme.department", "application.personalInformation", "shortlist", "shortlist.offeredProgramme", "department", "school", "createdBy", "campus"]
    },
    notifyOnNetworkStatusChange: false,
    // pollInterval,
    fetchPolicy: 'network-only'
  });

  // console.log({data})

  const navigateItem = (action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: action
      })
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Admitted Applicant Information"
      description="Details of the admitted applicant are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "admissions:*", "admissions:manage"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={wrapClick(navigateItem("download"))}
              disabled={!!!data?.admission?.indexNumber}
            >
              Download Admission Letter
            </button>
          )}
        </>
      )}
    >
      <AdmittedView
        admitted={data?.admission}
      />
    </Modal>
  );
}