import { AvatarUpload, RichTextArea, SelectInput, TextArea, TextInput, UploadArea } from "components/core"
import { FormikProps } from "formik"
import lodash from "lodash";
import dayjs from "dayjs";
import { FC } from "react"
import DocumentUpload from "components/core/document-upload";

interface AdmissionCycleFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    noticeToApplicants: string;
    openingDate: string;
    description: string;
    closingDate: string;
    supplementaryDocuments: string;
    generalEntryRequirements: string;
    academicCalendar: {
      matriculationDate: string;
      openingDate: string;
      orientationDate: string;
      year: string;
    },
    schoolFeesDates: {
      commitmentFeeDate: string,
      outstandingFeesDate: string,
      provisionalRegistrationDate: string
    },
    signatures: {
      undergraduateName: string,
      undergraduatePortfolio: string,
      undergraduateUrl: string,
      graduateName: string
      graduatePortfolio: string
      graduateUrl: string
    }
  }>;
}

const AdmissionCycleForm: FC<AdmissionCycleFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Admission Cycle Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <TextInput
              id="code"
              label="Code"
              type="text"
              placeholder="eg. 09"
              disabled={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="openingDate"
              label="Opening Date"
              type="datetime-local"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="closingDate"
              label="Closing Date"
              type="datetime-local"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Residential"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <TextArea
              id="description"
              label="Description"
              placeholder="eg. Admission Cycle for 2024 2025 academic year...."
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <RichTextArea
              id="noticeToApplicants"
              label="Notice To Applicants"
              placeholder="eg. Special tariff for the needy"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <RichTextArea
              id="generalEntryRequirements"
              label="General Entry Requirements"
              placeholder="eg. Special tariff for the needy"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className='text-xs font-light'>Academic Calendar Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <SelectInput
              id="academicCalendar.year"
              label="Academic Year"
              placeholder="eg. 09"
              options={[
                { label: "- select -", value: "" },
                ...lodash.times(3, (idx) => `${dayjs().add(idx, "years").year()}/${dayjs().add(idx + 1, "years").year()}`)
              ]}
              required={true}
              {...form} />
          </div>

          <div>
            <TextInput
              id="academicCalendar.openingDate"
              label="Opening Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="academicCalendar.orientationDate"
              label="Orientation Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="academicCalendar.matriculationDate"
              label="Maticulation  Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>

      <div className="pt-6">
        <span className='text-xs font-light'>School Fees Dates Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <TextInput
              id="schoolFeesDates.commitmentFeeDate"
              label="Commitment Fee Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="schoolFeesDates.provisionalRegistrationDate"
              label="Provisional Registration Fee Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="schoolFeesDates.outstandingFeesDate"
              label="Outstanding Fees Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>

      <div className="pt-6">
        <span className='text-xs font-light'>Signatures</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <AvatarUpload id="signatures.undergraduateUrl" label="Undergraduate Signature" {...form} />
          </div>
          <div>
            <AvatarUpload id="signatures.graduateUrl" label="Graduate Signature" {...form} />
          </div>
          <div>
            <TextInput
              id="signatures.undergraduateName"
              label="Undergraduate Signatory Name"
              type="text"
              placeholder="eg. Mrs. Flora Sarpong"
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="signatures.graduateName"
              label="Graduate Signatory Name"
              type="text"
              placeholder="eg. Mr. Kofi Blebo"
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="signatures.undergraduatePortfolio"
              label="Undergraduate Portfolio"
              type="text"
              placeholder="eg. Director, Academic Student Affairs"
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="signatures.graduatePortfolio"
              label="Graduate Portfolio"
              type="text"
              placeholder="eg. Director, Academic Affairs"
              {...form}
            />
          </div>
        </div>
      </div>


      <div className="pt-6">
        <span className='text-xs font-light'>Supplementary Sheets</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div className="col-span-2 h-36 w-full">
            <DocumentUpload id="supplementaryDocuments" label="Supplementary Sheet" {...form} />
          </div>
        </div>
      </div>

    </div>
  )
}

export default AdmissionCycleForm
