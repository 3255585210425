import {  TextInput } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface ShortlistFormProps {
  form: FormikProps<{
    code: any;
  }>;
}

const BeginShortlistForm: FC<ShortlistFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <p className="text-base font-light">Please enter <span className="font-semibold text-primary-600">"BEGIN"</span> to start shortlisting</p>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div className="col-span-2">
            <TextInput
              id="code"
              label="Code"
              type="text"
              placeholder="Enter code here"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeginShortlistForm