import { FC } from 'react';
import { Avatar } from 'components/core';
import { classNames } from 'utils';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import config from 'config';
import _ from 'lodash';
import parseHtml from "html-react-parser";
import { EXAMINATION_GRADING, ExaminationType } from 'utils/general';

interface AdmissionProgrammeViewProps {
  admissionProgramme: {
    code: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    duration: string;
    school: {
      _id: string;
      code: string;
      name: string;
    };
    level: string;
    department: {
      _id: string;
      code: string;
      name: string;
      description: string;
      level: string;
    };
    programme: {
      _id: string;
      code: string;
      name: string;
      description: string;
      level: string;
    };
    tracks: string[];
    offerings: {
      campus: {
        _id: string;
        code: string;
        name: string;
      };
      types: string[];
    }[];
    requirements: {
      type: string;
      description: string;
      specifications: {
        mandatory: boolean
        subject: string;
        requiredGrade: number;
      }[];
      defaultMinimumGrade?: number;
      numberOfPassedSubjects?: number;
    }[];
    requiresInterview: boolean;
    maximumInterviewScore: number;
    hasAdmissionQuota: boolean;
    admissionQuota: number;
    requiredInterviewScore: number;
    interviewScoreWeight: number;
    requiresExamination: boolean;
    maximumExaminationScore: number;
    requiredExaminationScore: number;
    examinationScoreWeight: number;
    createdBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      gender: string;
      role: string;
      meta: {
        isFirstLogin: boolean;
        isSuspended: boolean;
      };
    };
    lastUpdatedBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      gender: string;
      role: string;
      meta: {
        isFirstLogin: boolean;
        isSuspended: boolean;
      };
    };
  };
}

const AdmissionProgrammeView: FC<AdmissionProgrammeViewProps> = ({ admissionProgramme }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className=''>
        <span className='text-xs font-light'>Programme Information</span>

        <div className="mt-2 grid grid-cols-3 gap-6">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Programme Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.programme?.code || "N/A"}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Programme Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.name || "N/A"}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Department Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.department?.name || "N/A"}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Available Tracks
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.tracks?.join(", ") || "N/A"}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Requirement Types
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.requirements?.map(requirement => requirement.type)?.join(", ") || "N/A"}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Programme Duration (Months)
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.duration || "Not set"}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Admission Quota
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.admissionQuota || "Not set"}
            </div>
          </div>
          <div className="col-span-3">
            <span className="block text-sm font-light text-gray-700">
              Available Offerings
            </span>
            <div className="mt-1 w-full ">
              <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Campus
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900 w-[15%]"
                    >
                      Day
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900 w-[15%]"
                    >
                      Evening
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center w-[15%]"
                    >
                      Weekend
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {admissionProgramme?.offerings?.map((step: any, idx: number) => (
                    <tr key={idx} className="divide-x divide-gray-200">
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step?.campus?.name || "N/A"}</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-700">{step?.types?.includes("DAY") ? "Yes" : "No"}</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-700">{step?.types?.includes("EVENING") ? "Yes" : "No"}</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-700">{step?.types?.includes("WEEKEND") ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Requirements Information</span>
        <div className="mt-2 divide-y divide-gray-200 space-y-6">
          {admissionProgramme?.requirements?.map((requirement: any) => (
            <div className="grid grid-cols-1 gap-6 pt-6 first:pt-0">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Type
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {_.startCase(requirement?.type || "") || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Description
                </span>
                <div className="mt-1 block w-full sm:text-sm rounded border border-gray-200 p-3">
                  {parseHtml(requirement?.description || "N/A")}
                </div>
              </div>
              {
                (!!requirement?.defaultMinimumGrade && _.isNumber(requirement?.defaultMinimumGrade)) && (
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Default Minimum Grade
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {
                        EXAMINATION_GRADING?.[requirement?.type as ExaminationType]?.find((grade) => grade.value === requirement?.defaultMinimumGrade)?.label || "N/A"
                      }
                    </div>
                  </div>
                )
              }
              
              {
                (!!requirement?.numberOfPassedSubjects && _.isNumber(requirement?.numberOfPassedSubjects)) && (
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Minimum Number of Passed Subjects
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {
                        _.toString(requirement?.numberOfPassedSubjects) || "N/A"
                      }
                    </div>
                  </div>
                )
              }
              {["ALEVEL", "OLEVEL", "WASSCE", "SSSCE"].includes(requirement?.type) && (
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Specifications
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[80%]"
                          >
                            Subject
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-[20%]"
                          >
                            Required Grade
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-[20%]"
                          >
                            Mandatory
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {requirement?.specifications?.map((step: any, idx: string) => (
                          <tr key={idx} className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step?.subject || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{EXAMINATION_GRADING?.[requirement?.type as ExaminationType]?.find((grade) => grade.value === step?.requiredGrade)?.label}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{(step.mandatory ? "Yes" : "No") || "N/A"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Extra Requirement Details</span>

        <div className="mt-2 grid grid-cols-3 gap-6">

          <div>
            <span className="block text-sm font-light text-gray-700">
              Requires Entrance Examination ?
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.requiresExamination ? "Yes" : "No"}
            </div>
          </div>

          {admissionProgramme?.requiresExamination && (
            <>
              <div className="col-start-1">
                <span className="block text-sm font-light text-gray-700">
                  Maximum Examination Score
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {admissionProgramme?.maximumExaminationScore || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Required Examination Score
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {admissionProgramme?.requiredExaminationScore || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Examination Score Weight
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {admissionProgramme?.examinationScoreWeight || "N/A"}%
                </div>
              </div>
            </>
          )}

          <div>
            <span className="block text-sm font-light text-gray-700">
              Requires Entrance Interview ?
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionProgramme?.requiresInterview ? "Yes" : "No"}
            </div>
          </div>

          {admissionProgramme?.requiresInterview && (
            <>
              <div className="col-start-1">
                <span className="block text-sm font-light text-gray-700">
                  Maximum Interview Score
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {admissionProgramme?.maximumInterviewScore || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Required Interview Score
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {admissionProgramme?.requiredInterviewScore || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Interview Score Weight
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {admissionProgramme?.interviewScoreWeight || "N/A"}%
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdmissionProgrammeView;