import * as Yup from 'yup';
import { phoneNumberValidation } from '../admin/schema';

export const DepartmentSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is a required field'),
  name: Yup.string().required('Name is a required field'),
  phone: phoneNumberValidation(),
  code: Yup.string(),
  head: Yup.object(),
  school: Yup.string().required('School is a required field'),
  digitalAddress: Yup.string().required('Digital Address is a required field'),
  officeAddress: Yup.string().required('Office Address is a required field'),
})

export const DepartmentInitialValues = {
  email: "",
  name: "",
  phone: "",
  code: "",
  head: "",
  school: "",
  digitalAddress: "",
  officeAddress: "",
}