import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, AdmissionCycleForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';
import dayjs from 'dayjs';
import { AdmissionCycleFormSchema } from 'components/forms/admission-cycle/schema';

const GET_ADMISSION_CYCLE = gql`
query GetAdmissionCycleLean($filter: AdmissionCycleFilter!) {
  admissionCycle: getAdmissionCycle(filter: $filter) {
    _id
    academicCalendar {
      year
      openingDate
      orientationDate
      matriculationDate
    }
    supplementaryDocuments
    closingDate
    code
    description
    generalEntryRequirements
    name
    noticeToApplicants
    openingDate
    schoolFeesDates {
      commitmentFeeDate
      outstandingFeesDate
      provisionalRegistrationDate
    }
    signatures {
      undergraduateName
      undergraduatePortfolio
      undergraduateUrl
      graduateName
      graduatePortfolio
      graduateUrl
    }
  }
}
`

const UPDATE_ADMISSION_CYCLE = gql`
  mutation UpdateAdmissionCycle($id: ID!, $input: AdmissionCycleInput!) {
    admissionCycle: updateAdmissionCycle(id: $id, input: $input) {
      _id
    }
  }
`

export default function UpdateAdmissionCycleContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const cycleId = searchParams.id || params.admissionCycle
  const { data, loading } = useQuery(GET_ADMISSION_CYCLE, {
    variables: {
      filter: {
        _id: {
          eq: cycleId
        }
      }
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache'
  });
  const [updateAdmissionCycle, { loading: updating }] = useMutation(UPDATE_ADMISSION_CYCLE)

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      noticeToApplicants: "",
      openingDate: "",
      description: "",
      closingDate: "",
      supplementaryDocuments: "",
      generalEntryRequirements: "",
      academicCalendar: {
        matriculationDate: "",
        openingDate: "",
        orientationDate: "",
        year: "",
      },
      schoolFeesDates: {
        commitmentFeeDate: "",
        outstandingFeesDate: "",
        provisionalRegistrationDate: ""
      },
      signatures: {
        undergraduateName: "",
        undergraduatePortfolio: "",
        undergraduateUrl: "",
        graduateName: "",
        graduatePortfolio: "",
        graduateUrl: ""
      }
    },
    validationSchema: AdmissionCycleFormSchema,
    onSubmit: async (values) => {
      await updateAdmissionCycle({
        variables: {
          id: cycleId,
          input: {
            ...lodash.omit(values, ["code"])
          }
        }
      }).then(({ data }) => {
        if (data.admissionCycle._id) {
          toast(JSON.stringify({ type: "success", title: "Admission Cycle Edited Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Admission Cycle" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      code: data?.admissionCycle?.code || "",
      name: data?.admissionCycle?.name || "",
      description: data?.admissionCycle?.description || "",
      noticeToApplicants: data?.admissionCycle?.noticeToApplicants || "",
      openingDate: data?.admissionCycle?.openingDate ? dayjs(data?.admissionCycle?.openingDate).format("YYYY-MM-DDTHH:mm") : "",
      closingDate: data?.admissionCycle?.closingDate ? dayjs(data?.admissionCycle?.closingDate).format("YYYY-MM-DDTHH:mm") : "",
      generalEntryRequirements: data?.admissionCycle?.generalEntryRequirements || "",
      supplementaryDocuments: data?.admissionCycle?.supplementaryDocuments?.[0] || "",
      academicCalendar: {
        openingDate: data?.admissionCycle?.academicCalendar?.openingDate ? dayjs(data?.admissionCycle?.academicCalendar?.openingDate).format("YYYY-MM-DD") : "",
        matriculationDate: data?.admissionCycle?.academicCalendar?.matriculationDate ? dayjs(data?.admissionCycle?.academicCalendar?.matriculationDate).format("YYYY-MM-DD") : "",
        orientationDate: data?.admissionCycle?.academicCalendar?.orientationDate ? dayjs(data?.admissionCycle?.academicCalendar?.orientationDate).format("YYYY-MM-DD") : "",
        year: data?.admissionCycle?.academicCalendar?.year || "",
      },
      schoolFeesDates: {
        commitmentFeeDate: data?.admissionCycle?.schoolFeesDates?.commitmentFeeDate ? dayjs(data?.admissionCycle?.schoolFeesDates?.commitmentFeeDate).format("YYYY-MM-DD") : "",
        outstandingFeesDate: data?.admissionCycle?.schoolFeesDates?.outstandingFeesDate ? dayjs(data?.admissionCycle?.schoolFeesDates?.outstandingFeesDate).format("YYYY-MM-DD") : "",
        provisionalRegistrationDate: data?.admissionCycle?.schoolFeesDates?.provisionalRegistrationDate ? dayjs(data?.admissionCycle?.schoolFeesDates?.provisionalRegistrationDate).format("YYYY-MM-DD") : "",
      },
      signatures: {
        undergraduateName: data?.admissionCycle?.signatures?.undergraduateName,
        undergraduatePortfolio: data?.admissionCycle?.signatures?.undergraduatePortfolio,
        undergraduateUrl: data?.admissionCycle?.signatures?.undergraduateUrl,
        graduateName: data?.admissionCycle?.signatures?.graduateName,
        graduatePortfolio: data?.admissionCycle?.signatures?.graduatePortfolio,
        graduateUrl: data?.admissionCycle?.signatures?.graduateUrl
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.admissionCycle, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Admission Cycle Information"
      description="Provide the details to update admission cycle"
      size='4xl'
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? "Editing Admission Cycle..." : "Edit Admission Cycle"}
          </button>

        </>
      )}>
      {data?.admissionCycle?._id && (
        <AdmissionCycleForm
          form={form}
        />
      )}
    </Modal>
  )
}