import _ from 'lodash';
import { FC } from 'react'
import dayjs from 'dayjs';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import parseHtml from "html-react-parser";

interface AdmissionCycleViewProps {
  admissionCycle: {
    _id: string;
    code: string;
    name: string;
    status: string;
    description: string;
    noticeToApplicants: string;
    generalEntryRequirements: string;
    openingDate: string;
    closingDate: string;
    academicCalendar: {
      year: string;
      openingDate: string;
      orientationDate: string;
      matriculationDate: string;
    };
    schoolFeesDates: {
      commitmentFeeDate: string,
      outstandingFeesDate: string,
      provisionalRegistrationDate: string
    }
    createdBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    };
    lastUpdatedBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    }
    createdAt: string;
    updatedAt: string;
  };
}

const AdmissionCycleView: FC<AdmissionCycleViewProps> = ({ admissionCycle }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Admission Cycle Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Opening Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.openingDate ? dayjs(admissionCycle?.openingDate).format(dateFormat) : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Closing Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.closingDate ? dayjs(admissionCycle?.closingDate).format(dateFormat) : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Status
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(admissionCycle?.status || "") || "N/A"}
            </div>
          </div>

          <div className='col-span-4'>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.name || "N/A"}
            </div>
          </div>

          <div className='col-span-4'>
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.description || "N/A"}
            </div>
          </div>

          <div className='col-span-4'>
            <span className="block text-sm font-light text-gray-700">
              Notice To Applicants
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {parseHtml(admissionCycle?.noticeToApplicants || "N/A")}
            </div>
          </div>

          <div className='col-span-4'>
            <span className="block text-sm font-light text-gray-700">
              General Entry Requirements
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {parseHtml(admissionCycle?.generalEntryRequirements || "N/A")}
            </div>
          </div>

        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Academic Calendar Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Academic Year
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.academicCalendar.year || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Opening Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.academicCalendar?.openingDate ? dayjs(admissionCycle?.academicCalendar?.openingDate).format(dateFormat) : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Orientation Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.academicCalendar?.orientationDate ? dayjs(admissionCycle?.academicCalendar?.orientationDate).format(dateFormat) : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Maticulation Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.academicCalendar?.matriculationDate ? dayjs(admissionCycle?.academicCalendar?.matriculationDate).format(dateFormat) : "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>School Fees Dates</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Provisional Fees Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.schoolFeesDates?.provisionalRegistrationDate ? dayjs(admissionCycle?.schoolFeesDates?.provisionalRegistrationDate).format(dateFormat) : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Outstanding Fees Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.schoolFeesDates?.outstandingFeesDate ? dayjs(admissionCycle?.schoolFeesDates?.outstandingFeesDate).format(dateFormat) : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Commitment Fee Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.schoolFeesDates?.commitmentFeeDate ? dayjs(admissionCycle?.schoolFeesDates?.commitmentFeeDate).format(dateFormat) : "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Meta Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.createdAt ? dayjs(admissionCycle?.createdAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.createdBy?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.updatedAt ? dayjs(admissionCycle?.updatedAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admissionCycle?.lastUpdatedBy?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdmissionCycleView