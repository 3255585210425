import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, UserForm } from 'components';
import * as Yup from 'yup';
import { UserSchema } from 'components/forms/user/schema';

const CREATE_USER = gql`
mutation CreateUser($input: UserInput!) {
  createUser(input: $input) {
    _id
    code
    createdAt
  }
}
`

export default function CreateStaffContainer({ open, setOpen, refetch, role }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; role?: string }) {
  const [createUser, { loading }] = useMutation(CREATE_USER)



  const form = useFormik({
    initialValues: {
      name: "",
      staffId: "",
      phone: "",
      gender: "",
      email: "",
      profileImageUrl: "",
      role: role || "",
      school: "",
      department: "",
      campus: "",
    },
    validationSchema: UserSchema,
    onSubmit: async ({ school, department, campus, ...values }) => {
      await createUser({
        variables: {
          input: {
            ...values,
            ...(school && { school }),
            ...(campus && { campus }),
            ...(department && { department })
          }
        }
      }).then(({ data }) => {
        if (data.createUser._id) {
          toast(JSON.stringify({ type: "success", title: "User Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create User" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    if (role) {
      form.setFieldValue("role", role)
    }
  }, [role])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New User"
      description="Provide the details to add a new user"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding User..." : "Add User"}
          </button>

        </>
      )}>
      <UserForm
        form={form}
      />
    </Modal>
  )
}