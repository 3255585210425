import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";
import { currentUserVar } from "apollo/cache/auth";

interface VoucherClassPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
  filter?: {
    cycle?: string
  }
}

const GET_VOUCHERCLASSS = gql`
query GetVoucherClasses($filter: VoucherClassFilter) {
  voucherClasses: getVoucherClasses(filter: $filter) {
    _id
    name
    description
    code
    price
  }
}
`;

const VoucherClassPickerContainer: FC<VoucherClassPickerContainerProps> = ({
  id,
  label,
  rawId,
  filter,
  ...form
}) => {

  const currentUser = useReactiveVar(currentUserVar);
  const { loading, data, refetch } = useQuery(GET_VOUCHERCLASSS, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        ...(filter?.cycle && { cycle: { eq: filter?.cycle } })
      }
    }
  });

  return (
    <SearchSelectInput
      id={id ?? "voucherclass"}
      label={label ?? "voucherclass"}
      placeholder="Select Class"
      optionsLoading={loading}
      options={(data?.voucherClasses ?? [])?.map((voucherclass: any) => ({
        label: {
          title: voucherclass.name as string,
        },
        value: rawId ? voucherclass?._id : voucherclass,
      }))}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default VoucherClassPickerContainer;
