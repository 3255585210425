import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, TuitionForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { TuitionInitialValues, TuitionSchema } from 'components/forms/tuition/schema';

const GET_TUITION = gql`
query GetTuitionLean($filter: TuitionFilter!) {
  tuition: getTuition(filter: $filter) {
    _id
    code
    name
    description
    fees {
      fileUrl
      type
    }
  }
}        
`;

const UPDATE_TUITION = gql`
  mutation UpdateTuition($id: ID!, $input: TuitionInput!) {
    tuition: updateTuition(id: $id, input: $input) {
      _id
    }
  }
`

export default function UpdateTuitionContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_TUITION, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      }
    },
    notifyOnNetworkStatusChange: false
  });
  const [updateTuition, { loading: updating }] = useMutation(UPDATE_TUITION)
  const { params } = useMatch<LocationGenerics>();

  const form = useFormik({
    initialValues: TuitionInitialValues,
    validationSchema: TuitionSchema,
    onSubmit: async ({ code, ...values }) => {
      await updateTuition({
        variables: {
          id: searchParams.id,
          input: {
            cycle: params?.admissionCycle,
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data?.tuition?._id) {
          toast(JSON.stringify({ type: "success", title: "Tuition updated successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not update Tuition" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {

    const values = {
      name: data?.tuition?.name || "",
      code: data?.tuition?.code || "",
      description: data?.tuition?.description || "",
      fees: data?.tuition?.fees?.map((fee: any) => ({
        type: fee?.type,
        fileUrl: fee?.fileUrl
      })) || []
    }

    form.setValues(values)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.tuition, searchParams.id, open])

  useEffect(() => {
    console.log({ form: form?.values, data })
  }, [form?.values]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Edit Tuition Information"
      description="Provide the details to update tuition"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? "Editing Tuition..." : "Edit Tuition"}
          </button>

        </>
      )}>
      {data?.tuition?._id && (
        <TuitionForm
          form={form}
        />
      )}
    </Modal>
  )
}