import { SelectInput, TextArea, TextInput } from "components/core"
import TagArea from "components/core/tag-area";
import AdmissionCyclePickerContainer from "containers/admission-cycle-picker";
import { FormikProps } from "formik"
import { FC } from "react"

interface MailingFormProps {
  form: FormikProps<{
    subject: string;
    cycle: string;
    message: string;
    recipientType: string;
    recipientsApplicationCodes: any[]
  }>;
}

const MailingForm: FC<MailingFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Mailing Information</span>
        <div className="grid grid-cols-1 gap-6 mt-2">

        <div>
            <SelectInput
              id="recipientType"
              label="Recipient Type"
              placeholder="Choose who receives this email"
              options={["ALL", "APPLICATIONS", "SUBMITTED", "PAID", "APPLY_NOT_SUBMITTED", "SUBMITTED_NOT_PAID", "ADMITTED"]}
              {...form}
              required={true}
            />
          </div>

          <div>
            <AdmissionCyclePickerContainer
              id="cycle"
              label="Cycle"
              rawId={true}
              {...form}
              disabled={form?.values?.recipientType === "APPLICATIONS"}
              required={true}
            />
          </div>
  
          {
            form?.values?.recipientType === "APPLICATIONS" && (
              <div>
                <TagArea
                  id="recipientsApplicationCodes"
                  label="Applications"
                  placeholder="Choose who receives this by entering application code"
                  required={true}
                  {...form}
                />
              </div>
            )
          }

          <div>
            <TextInput
              id="subject"
              label="Subject"
              type="text"
              placeholder="e.g. Admissions Closing Date"
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextArea
              id="message"
              label="Message"
              placeholder="eg. All applicants should make sure all photos..."
              {...form}
              required={true}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default MailingForm