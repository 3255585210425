import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Shimmers, TableComponent } from 'components';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo } from 'react'
import { useMatch, useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, classNames, withPermissions } from 'utils';
import config from 'config';
import UpdateShortlistContainer from './update';
import { SelectInput } from 'components/core';
import { currentConfigVar } from 'apollo/cache/config';
import ActionButton, { Action } from 'components/buttons/action-button';
import { ExportDataContainer } from 'containers';
import lodash from 'lodash';
import { useToggle } from 'react-use';
import ViewShortlistedContainer from './view';
import AdmissionProgrammePickerContainer from 'containers/admission-programme-picker';
import StartAdmissionsContainer from './start-admissions';
import ReviewShortlistedContainer from './review';
import { currentUserVar } from 'apollo/cache/auth';
import OfferProgrammeContainer from './programme-offer';
import SchoolPickerContainer from 'containers/school-picker';

const GET_SHORTLIST = gql`
query GetShortlistings($filter: ShortlistFilter, $search: SearchOperator, $populate: [String], $pagination: Pagination, $sort: ShortlistSort) {
  items: getShortlistings(filter: $filter, search: $search, populate: $populate, pagination: $pagination, sort: $sort) {
    _id
    status
    applicant {
      _id
      code
      email
    }
    cycle {
      _id
      name
    }
    examinationScore
    interviewScore
    programme {
      _id
      name
      level
      department {
        _id
        name
        code
      }
      requiresExamination
      requiresInterview
    }
    application {
      _id
      personalInformation {
        lastName
        firstName
      }
    }
    createdAt
  }
  itemsCount: getShortlistCount(filter: $filter, search: $search )
}
`

const ShortlistedPage: FC = () => {
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [admissionProgramme, setAdmissionProgramme] = useUrlState("admissionProgramme");
  const [status, setStatus] = useUrlState("status");
  const [school, setSchool] = useUrlState("school");
  const [level, setLevel] = useUrlState("level");

  const [voucherClassesExpanded, toggleVoucherClassesExpanded] = useToggle(false)

  const disableReview = ({ requiresInterview, interviewScore, requiresExamination, examinationScore }: { requiresInterview: any, interviewScore: any, requiresExamination: any, examinationScore: any }) => {
    if (requiresInterview) {
      return !interviewScore
    }
    if (requiresExamination) {
      return !examinationScore
    }
    return false
  }

  useEffect(() => {
    setSchool(currentUser?.school?._id)
    setStatus("PENDING")
  }, [currentUser]);

  const variables = useMemo(() => ({
    pagination: {
      limit: searchParams.pageSize || 12,
      skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
    },
    ...((searchParams?.search || "")?.length > 0 ? {
      search: {
        query: searchParams?.search,
        options: ["CaseInsensitive"],
        fields: ["applicant.email", "application.code", "applicationName", "applicationEmail", "applicationCode"]
      }
    } : {}),
    filter: {
      cycle: {
        eq: params.admissionCycle
      },
      ...(admissionProgramme && { programme: { eq: admissionProgramme } }),
      ...(school && { school: { eq: school } }),
      ...(status && { status: { eq: status } }),
      ...(level && { applicationLevel: { eq: level } }),
    },
    sort: {
      createdAt: "ascending"
    },
    populate: ["applicant", "cycle", "programme", "programme.department", "application", "application.personalInformation", "application.educationalHistoryInformation"]
  }), [searchParams]);

  const { data, loading, networkStatus, refetch } = useQuery(GET_SHORTLIST, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
        ...(!lodash.isNumber(old?.month) ? { month: dayjs().month() } : {}),
        ...(!old?.year ? { year: dayjs().year() } : {}),
      })
    })
  }, [navigate])

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-full bg-gray-50">
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'shortlist'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="Shortlist"
                variables={variables}
              />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                {
                  school && (
                    <AdmissionProgrammePickerContainer
                      id="admissionProgramme"
                      labelHidden={true}
                      rawId={true}
                      setFieldValue={(field: any, value: string) =>
                        setAdmissionProgramme(value)
                      }
                      filter={{
                        cycle: { eq: params.admissionCycle as string },
                        deleted: { eq: false },
                        ...(school && { school: { eq: school } }),
                      }}
                      values={{}}
                    />
                  )
                }

                {
                  !currentUser?.school?._id && (
                    <SchoolPickerContainer
                      id="school"
                      labelHidden={true}
                      rawId={true}
                      setFieldValue={(field: any, value: string) =>
                        setSchool(value)
                      }
                      filter={{
                        cycle: { eq: params.admissionCycle as string },
                        deleted: { eq: false },
                        ...(school && { school: { eq: school } }),
                      }}
                      disabled={!!currentUser?.school?._id}
                      values={{ school }}
                    />
                  )
                }

                <SelectInput
                  id="level"
                  label=""
                  labelHidden
                  placeholder="Filter Level"
                  options={["UNDERGRADUATE", "GRADUATE"]}
                  handleChange={(event: any) => {
                    setLevel(event.target.value)
                  }}
                  handleBlur={() => null}
                  values={{ level }}
                />
                <SelectInput
                  handleBlur={() => null}
                  handleChange={(e: any) => setStatus(e.target.value)}
                  id="status"
                  label="Status"
                  placeholder='Filter Status'
                  labelHidden
                  options={[
                    { label: "Pending", value: "PENDING" },
                    { label: "Approved", value: "APPROVED" },
                    { label: "Declined", value: "DECLINED" },
                  ]}
                  values={{ status }}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Applicant
                </th>
                {
                  !admissionProgramme &&
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Programme
                  </th>
                }
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Level
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Date Added
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={3} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100 uppercase">{[item?.application?.personalInformation?.lastName, item?.application?.personalInformation?.firstName].join(" ") || "N/A"}</div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">{item?.applicant?.email || "N/A"}</div>
                </td>
                {
                  !admissionProgramme &&
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">{item?.programme?.name || "N/A"}</div>
                  </td>
                }
                <td className="whitespace-nowrap px-6 py-4 text-sm text-center text-gray-500 border-b border-gray-200 ">
                  <span
                    className={classNames(
                      item?.programme?.level === "GRADUATE" ? `bg-orange-100 text-orange-800 dark:bg-[#F87A17] dark:text-gray-50` : "",
                      item?.programme?.level === "UNDERGRADUATE" ? `bg-primary-100 text-primary-800 dark:bg-[#657383] dark:text-gray-50` : "",
                      !item?.programme?.level ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                      <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                    </svg>
                    <span>
                      {item?.programme?.level || "Unknown"}
                    </span>
                  </span>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <span
                    className={classNames(
                      item?.status === "APPROVED" ? `bg-green-100 text-green-700 dark:bg-[#F87A17] dark:text-gray-50` : "",
                      item?.status === "DECLINED" ? `bg-red-100 text-red-800 dark:bg-[#657383] dark:text-gray-50` : "",
                      !item?.status ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                      "inline-flex rounded-full px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                      <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                    </svg>
                    <span>
                      {item?.status || "Pending"}
                    </span>
                  </span>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-center text-gray-500 border-b border-gray-200">
                  <div className="text-gray-400 dark:text-gray-100">{dayjs(item?.createdAt).format(dateFormat + " HH:mm a") || "N/A"}</div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>

                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["*:*", "shortlist:*", "shortlist:review"])(
                      <ActionButton
                        action='approve'
                        onClick={dispatchAction(item?._id, "update")}
                        disabled={!item?.programme?.requiresExamination && !item?.programme?.requiresInterview}
                      />
                    )}

                    {withPermissions(["*:*", "shortlist:*", "shortlist:approve"])(
                      <ActionButton
                        action='investigate'
                        disabled={disableReview({
                          requiresExamination: item?.programme?.requiresExamination,
                          requiresInterview: item?.programme?.requiresInterview,
                          examinationScore: item?.examinationScore,
                          interviewScore: item?.interviewScore
                        })}
                        onClick={dispatchAction(item?._id, "investigate")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>

      </div>


      {withPermissions(["*:*", "shortlist:*", "shortlist:initiate"])(
        <StartAdmissionsContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}

      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "shortlist:*", "shortlist:update"])(
            <UpdateShortlistContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "shortlist:*", "shortlist:programme-offer"])(
            <OfferProgrammeContainer
              open={modal === "offer"}
              setOpen={(val: boolean) => setModal(val ? "offer" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewShortlistedContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <ReviewShortlistedContainer
            open={modal === "investigate"}
            setOpen={(val: boolean) => setModal(val ? "investigate" : undefined)}
          />
        </>
      )}
    </main>
  )
}

export default ShortlistedPage;
