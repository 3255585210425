import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC, useEffect } from "react";
import { SearchSelectInput } from "components/core";
import { currentUserVar } from "apollo/cache/auth";

interface CampusPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
}

const GET_CAMPUSES = gql`
query GetCampuses {
  getCampuses {
    _id
    code
    name
  }
}
`;

const CampusPickerContainer: FC<CampusPickerContainerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {

  const currentUser = useReactiveVar(currentUserVar);
  const { loading, data, refetch } = useQuery(GET_CAMPUSES, {
    notifyOnNetworkStatusChange: false,
    variables: {

    }
  });

  useEffect(() => {
    if (currentUser?.campus) {
      form?.setFieldValue?.(
        id,
        rawId
          ? currentUser.campus?._id
          : {
            _id: currentUser.campus._id,
            code: currentUser.campus.code,
            name: currentUser.campus.name,
          }
      );
    }
  }, [currentUser]);

  return (
    <SearchSelectInput
      id={id ?? "campus"}
      label={label ?? "campus"}
      placeholder="Select Campus"
      optionsLoading={loading}
      options={(data?.getCampuses ?? [])?.map((campus: any) => ({
        label: {
          title: campus.name as string,
        },
        value: rawId ? campus?._id : campus,
      }))}
      {...form}
      disabled={form.disabled || !!currentUser.campus}
    />
  );
};

export default CampusPickerContainer;
