import { classNames, wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import BeginShortlistForm from 'components/forms/begin-shortlist';

const BEGIN_SHORTLISTING = gql`
mutation InitiateShortlisting($input: InitiateShortlistingInput!) {
  initiateShortlisting(input: $input) {
    applications
  }
}
`

export default function StartShortlistContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const { params } = useMatch<LocationGenerics>();
  const [beginShortlist, { loading }] = useMutation(BEGIN_SHORTLISTING)

  const form = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: async (values) => {
      if (values?.code !== "BEGIN") {
        return toast(JSON.stringify({ type: "error", title: "Please enter" }))
      }
      await beginShortlist({
        variables: {
          input: {
            cycleId: params.admissionCycle
          }
        }
      }).then(({ data }) => {
        if (data?.initiateShortlisting) {
          toast(JSON.stringify({ type: "success", title: `Shortlisting Started` }))
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not start shortlist sequence" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Initiate Shortlisting"
      description="Enter the code below to start shortlisting candidates"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || form?.values.code !== "BEGIN"}
            className={classNames(
              loading ? "cursor-not-allowed" : "cursor-pointer",
              "w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 disabled:bg-gray-400 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            )}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Starting Shortlisting..." : "Start Shortlisting"}
          </button>
        </>
      )}
    >
      <BeginShortlistForm
        form={form}
      />
    </Modal>
  )
}