import { XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { RadioInput, TextArea, TextInput } from "components/core"
import { ProgrammePicker } from "containers";
import { FormikProps } from "formik"
import lodash from "lodash";
import { FC } from "react"
import { wrapClick } from "utils";

interface ShortlistFormProps {
  form: FormikProps<{
    shortlistId: any;
    examinationScore: string;
    interviewScore: string;
  }>;
  data: {
    maximumExaminationScore: number
    maximumInterviewScore: number
    requiresInterview: boolean
    requiresExamination: boolean
  }
}

const ShortlistForm: FC<ShortlistFormProps> = ({ form, data }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Shortlist Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">

          <div className="col-span-1">
            <TextInput
              id="interviewScore"
              label="Interview Score"
              type="number"
              placeholder="e.g. 50"
              required={true}
              disabled={data?.requiresInterview}
              max={data?.maximumInterviewScore}
              {...form}
            />
          </div>

          <div className="col-span-1">
            <TextInput
              id="examinationScore"
              label="Examination Score"
              type="number"
              placeholder="e.g. 78"
              required={true}
              disabled={data?.requiresExamination}
              max={data?.maximumExaminationScore}
              {...form}
            />
          </div>

        </div>
      </div>

    </div>
  )
}

export default ShortlistForm