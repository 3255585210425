import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import { currentUserVar } from "apollo/cache/auth";
import { PieChart } from "components";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
import numeral from "numeral";
import { classNames } from "utils";
import { MoonLoader } from "react-spinners";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { currentConfigVar } from "apollo/cache/config";
import BarChart from "components/charts/bar-chart";
import { getRandomHexColor } from "utils/get-random-color";
import _ from "lodash";
import { toAbbreviation } from "utils/general";
dayjs.extend(weekOfYear);

interface AdmittedSchoolChartContainerProps {
  cycle?: string;
}

const GET_APPLICATIONS_STATS = gql`
  query Query($filter: CountBySchoolFilter) {
    countApplicationsBySchool(filter: $filter)
  }
`;

const GET_SHORTLIST_STATS = gql`
  query Query($filter: CountBySchoolFilter) {
    countShortlistedBySchool(filter: $filter)
  }
`;

const GET_ADMITTED_STATS = gql`
  query Query($filter: CountBySchoolFilter) {
    countAdmittedBySchool(filter: $filter)
  }
`;

const AdmittedSchoolChartContainer: FC<AdmittedSchoolChartContainerProps> = ({
  cycle,
}) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = cycle && { cycle: { eq: cycle } };


  const { loading: applicationsLoading, data: applicationsData } = useQuery(
    GET_APPLICATIONS_STATS,
    {
      variables: {
        filter: {
          ...cycleFilters,
        },
      },
      notifyOnNetworkStatusChange: false,
      fetchPolicy: "no-cache",
      pollInterval,
    }
  );


  const { loading: shortlistedLoading, data: shortlistedData } = useQuery(
    GET_SHORTLIST_STATS,
    {
      variables: {
        filter: {
          ...cycleFilters,
        },
      },
      notifyOnNetworkStatusChange: false,
      fetchPolicy: "no-cache",
      pollInterval,
    }
  );

  const { loading, data } = useQuery(GET_ADMITTED_STATS, {
    variables: {
      filter: {
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
    pollInterval,
  });

  const colors = [
    "#5e95fc",
    "#27d163",
    "#FFD36C",
    "#F8649F",
    "#72DBDB",
    "#A955A7",
  ].reverse();

  const shortlistedColors = [
    "#2c74fb",
    "#1fa64f",
    "#ffc439",
    "#f63381",
    "#49d1d1",
    "#874485",
  ].reverse();

  const admittedColors = [
    "#0556ef",
    "#177b3b",
    "#ffb506",
    "#ec0a64",
    "#2fb8b8",
    "#653364",
  ].reverse();

  const barDataApplications = applicationsData?.countApplicationsBySchool
    ?.sort((a: any, b: any) => a?.count - b?.count)
    ?.map((school: any, index: number) => ({
      school: school?.name,
      [`applications`]: school?.count,
      [`applicationsColor`]: colors?.[index] || getRandomHexColor(),
    }));

  const barDataShortlisted = shortlistedData?.countShortlistedBySchool?.map(
    (school: any, index: number) => ({
      school: school?.name,
      [`shortlisted`]: school?.count,
      [`shortlistedColor`]: shortlistedColors?.[index] || getRandomHexColor(),
    })
  );

  const barData = data?.countAdmittedBySchool?.map(
    (school: any, index: number) => ({
      school: school?.name,
      [`admitted`]: school?.count,
      [`admittedColor`]: admittedColors?.[index] || getRandomHexColor(),
    })
  );

  const mergedBarData = barDataApplications?.map((data: any, index: number) => {

    const foundAdmitted = barData?.find(
      (admittedData: any) => admittedData.school === data?.school
    );

    const foundShortlisted = barDataShortlisted?.find(
      (shortlistedData: any) => shortlistedData.school === data?.school
    );

    return {
      ...data,
      ...{
        ...foundShortlisted,
        [`shortlistedColor`]: shortlistedColors?.[index] || getRandomHexColor(),
      },
      ...{
        ...foundAdmitted,
        [`admittedColor`]: admittedColors?.[index] || getRandomHexColor(),
      },
    };
  });

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Schools
          </h3>
          <p className="text-xs text-gray-400">Displays admitted by school</p>
        </div>

        <div>
          {loading && <MoonLoader size={20} />}
        </div>
      </div>

      <BarChart
        keys={["applications", "shortlisted", "admitted"]}
        indexBy="school"
        xLabel="Schools"
        yLabel="No. of Applicants"
        layout="vertical"
        groupMode="grouped"
        data={mergedBarData || []}
        chartLabel={(data) => ``}
        xLabelFormat={(value) => toAbbreviation(value)}
        tooltipLabel={(data) => `${data?.indexValue} (${data?.id})`}
      />
    </>
  );
};

export default AdmittedSchoolChartContainer;
