import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Shimmers, TableComponent } from 'components';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo } from 'react'
import { useMatches, useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, withPermissions, classNames } from 'utils';
import config from 'config';
import ViewAdmissionProgrammeContainer from './view';
import { ExportDataContainer, SchoolPicker, VoucherClassPicker } from 'containers';
import { currentConfigVar } from 'apollo/cache/config';
import { SelectInput } from 'components/core';
import ActionButton, { Action } from 'components/buttons/action-button';
import lodash from 'lodash';
import CreateAdmissionProgrammeContainer from './create';
import UpdateAdmissionProgrammeContainer from './update';
import ReassignAdmissionProgrammeContainer from './reassign';
import { currentUserVar } from 'apollo/cache/auth';
import ConfigureAdmissionProgrammeContainer from './configure';
import SuspendAdmissionProgrammeContainer from './suspend';

const GET_ADMISSION_PROGRAMMES = gql`
query GetAdmissionProgrammes($filter: AdmissionProgrammeFilter, $pagination: Pagination, $search: SearchOperator, $sort: AdmissionProgrammeSort, $populate: [String]) {
  items: getAdmissionProgrammes(filter: $filter, pagination: $pagination, search: $search, sort: $sort, populate: $populate) {
    _id
    code
    name
    level
    tracks
    school {
      _id
      code
      name
    }
    createdAt
    updatedAt
    voucherClass {
      _id
      name
    }
    tuition {
      _id
      name
    }
  }
  itemsCount: getAdmissionProgrammesCount(filter: $filter, search: $search)
}
`

const AdmissionProgrammesPage: FC = () => {
  const { pollInterval, dateFormat } = useReactiveVar(currentConfigVar)
  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }
  const currentUser = useReactiveVar(currentUserVar);

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [school, setSchool] = useUrlState("school");
  const [level, setLevel] = useUrlState("level");
  const [status, setStatus] = useUrlState('status');
  const [voucherClass, setVoucherClass] = useUrlState("class");

  const matches = useMatches<LocationGenerics>()
  const cycle = matches?.[0]?.params?.admissionCycle

  const variables = useMemo(() => ({
    pagination: {
      limit: searchParams.pageSize || 12,
      skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
    },
    ...((searchParams?.search || "")?.length > 0 ? {
      search: {
        query: searchParams?.search,
        options: ["CaseInsensitive"],
        fields: ["code", "name"]
      }
    } : {}),
    filter: {
      ...(school && { school: { eq: school } }),
      ...(cycle && { cycle: { eq: cycle } }),
      ...(level && { level: { eq: level } }),
      ...(voucherClass && { voucherClass: { eq: voucherClass } }),
      ...(status && { hidden: { eq: status === "ACTIVE" ? false : true } }),
      deleted: { eq: false }
    },
    sort: {

    },
    populate: ["school", "voucherClass","tuition"]
  }), [searchParams, cycle, level]);

  const { data, loading, networkStatus, refetch } = useQuery(GET_ADMISSION_PROGRAMMES, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    setSchool(currentUser?.school?._id)
  }, [currentUser]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      })
    })
  }, [navigate])

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-full bg-gray-50">
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'programmes'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="AdmissionProgramme"
                variables={variables}
              />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">

                <SchoolPicker
                  id="school"
                  labelHidden={true}
                  placeholder='Filter School'
                  setFieldValue={(_field: string, value: string) => {
                    setSchool(value);
                  }}
                  disabled={!!currentUser?.school}
                  values={{ school }}
                  rawId={true}
                />

                <VoucherClassPicker
                  id="voucherClass"
                  labelHidden={true}
                  placeholder='Filter Class'
                  setFieldValue={(_field: string, value: string) => {
                    setVoucherClass(value);
                  }}
                  values={{ voucherClass }}
                  filter={{
                    cycle: cycle
                  }}
                  rawId={true}
                />

                <SelectInput
                  handleBlur={() => null}
                  handleChange={(e: any) => setLevel(e.target.value)}
                  id="level"
                  label="Level"
                  placeholder='Filter Level'
                  labelHidden
                  options={[
                    { label: "Graduate", value: "GRADUATE" },
                    { label: "Undergraduate", value: "UNDERGRADUATE" },
                  ]}
                  values={{ level }}
                  required={true}
                />

                <SelectInput
                  handleBlur={() => null}
                  handleChange={(e: any) => setStatus(e.target.value)}
                  id="status"
                  label="Statuus"
                  placeholder='Filter Status'
                  labelHidden
                  options={[
                    { label: "Active", value: "ACTIVE" },
                    { label: "Suspended", value: "SUSPENDED" },
                  ]}
                  values={{ status }}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Programme
                </th>
                {
                  !level &&
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Level
                  </th>
                }

                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Class
                </th>

                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Tuition
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Added At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                {
                  !level &&
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                }
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={3} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">{item?.code || "N/A"}</div>
                </td>
                <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100 line-clamp-2 max-w-[400px] flex flex-wrap">{item?.name}</div>
                  {
                    !school &&
                    <div className="font-medium text-gray-400 dark:text-gray-100">{item?.school?.name || "N/A"}</div>
                  }
                </td>
                {
                  !level &&
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <span
                      className={classNames(
                        item?.level === "GRADUATE" ? `bg-orange-100 text-orange-800 dark:bg-[#F87A17] dark:text-gray-50` : "",
                        item?.level === "UNDERGRADUATE" ? `bg-primary-100 text-primary-800 dark:bg-[#657383] dark:text-gray-50` : "",
                        !item?.level ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                        <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                      </svg>
                      <span>
                        {item?.level || "Unknown"}
                      </span>
                    </span>
                  </td>
                }
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="text-gray-500 dark:text-gray-400">{item?.voucherClass?.name || "Not Set"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="text-gray-500 dark:text-gray-400">{item?.tuition?.name || "Not Set"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="text-gray-500 dark:text-gray-400">{dayjs(item?.createdAt).format(dateFormat)}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:configure"])(
                      <ActionButton
                        action='configure'
                        onClick={dispatchAction(item?._id, "configure")}
                      />
                    )}
                    {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:update"])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                    {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:suspend"])(
                      <ActionButton
                        action='suspend'
                        onClick={dispatchAction(item?._id, "suspend")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:create"])(
        <CreateAdmissionProgrammeContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:update"])(
            <UpdateAdmissionProgrammeContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:configure"])(
            <ConfigureAdmissionProgrammeContainer
              open={modal === "configure"}
              setOpen={(val: boolean) => setModal(val ? "configure" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:assign"])(
            <ReassignAdmissionProgrammeContainer
              open={modal === "assign"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:suspend"])(
            <SuspendAdmissionProgrammeContainer
              open={modal === "suspend"}
              setOpen={(val: boolean) => setModal(val ? "suspend" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewAdmissionProgrammeContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  )
}

export default AdmissionProgrammesPage;
