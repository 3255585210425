import { useEffect, useState } from 'react'
import { wrapClick } from 'utils';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { ApplicationView, Modal, TextInput, VoucherClassForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';
import ShortlistForm from 'components/forms/shortlist';
import Loader from 'components/layouts/loader';

const GET_APPLICATION = gql`
query GetApplication($filter: ApplicationFilter!, $populate: [String]) {
  application :getApplication(filter: $filter, populate: $populate) {
    _id
    applicant {
      code
      email
    }
    code
    educationalHistoryInformation {
      history {
        programme
        institution
        cgpa
        class
        gpaScale
        graduatingYear
        graduatingMonth
        idNumber
      }
      type
    }
    level
    personalInformation {
      firstName
      email
      dateOfBirth
      gender
      lastName
      maritalStatus
      middleName
      nationality
      phone
      secondaryPhone
      profileImageUrl
    }
    programmeInformation {
      preferredCampus {
        _id
        name
      }
      preferredOfferingType
      programmeSelections {
        programme {
          department {
            name
          }
          school {
            name
          }
          name
          requiresInterview
          examinationScoreWeight
          interviewScoreWeight
          level
        }
      }
    }
    status
    referencesInformation {
      academic {
        email
        name
        organization
        phone
        title
      }
      nonAcademic {
        email
        name
        organization
        phone
        title
      }
    }
  }
}       
`;

const ENROLL_ADMISSION = gql`
mutation EnrollAdmission($input: EnrollAdmissionInput!) {
  enrollAdmission(input: $input) {
    _id
  }
}
`

export default function EnrollContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const [searchIndex, setSearchIndex] = useState("");
  const [getApplication, { data, loading }] = useLazyQuery(GET_APPLICATION, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only"
  });
  const [enrollAdmission, { loading: updating }] = useMutation(ENROLL_ADMISSION)

  const form = useFormik({
    initialValues: {
      shortlistId: "",
    },
    onSubmit: async (values) => {
      await enrollAdmission({
        variables: {
          input: {
            applicationId: data?.application?._id,
            enrolled: true,
          }
        }
      }).then(({ data }) => {
        if (data?.enrollAdmission?._id) {
          toast(JSON.stringify({ type: "success", title: "accepted applicant successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not update accepted applicant" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.shortlist, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Add Accepted"
      description="Provide the details the Index number of the applicant you want to accept"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? "Accepting..." : "Accept Applicant"}
          </button>
        </>
      )}>
      <div className="grid grid-cols-2">
        <div className="flex flex-row col-span-2 w-full">
          <TextInput
            id="index"
            type="text"
            placeholder="eg. 224073883"
            handleChange={(e: any) => setSearchIndex(e.target.value)}
            handleBlur={(e: any) => null}
            values={{ index: searchIndex }}
          />
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center mt-1 rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(() => getApplication({
              variables: {
                filter: {
                  gimpaIndexNumber: {
                    eq: searchIndex
                  }
                }
              }
            }))}
          >
            {updating ? "Searching..." : "Search"}
          </button>
        </div>
      </div>
      {
        loading ?
          <Loader />
          :
          data?.application ?
            <ApplicationView
              application={data?.application}
              invoice={null}
            />
            :
            null
      }
    </Modal>
  )
}