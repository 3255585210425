import { gql, useLazyQuery } from "@apollo/client";
import { Avatar, TextInput } from "components/core"
import { UsersPicker } from "containers";
import { FormikProps } from "formik"
import { FC, useEffect } from "react"

interface SchoolFormProps {
  form: FormikProps<{
    dean: {
      _id: string;
      code: string;
      email: string;
      name: string;
      profileImageUrl: string
    };
    digitalAddress: string;
    email: string;
    name: string;
    officeAddress: string;
    phone: string;
    yearEstablished: string;
  }>;
}

const GET_USER = gql`
query GetUser($filter: UserFilter!) {
  getUser(filter: $filter) {
    _id
    profileImageUrl
    name
    staffId
  }
}
`;

const SchoolForm: FC<SchoolFormProps> = ({ form }) => {


  const [getUser, { loading, data: user }] = useLazyQuery(GET_USER, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getUser(
      {
        variables: {
          filter: { ...(form?.values?.dean?._id ? { _id: { eq: form?.values?.dean?._id } } : null) }
        },
      }
    )
  }, [form]);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">School Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <TextInput
              id="code"
              label="School Code"
              type="text"
              placeholder="e.g. 07"
              disabled={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="name"
              label="School Name"
              type="text"
              placeholder="e.g. Accra West School"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Dean Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <div className="col-span-1">
              <Avatar
                alt={form?.values?.dean?._id  && user?.getUser?.name || "user_image"}
                src={form?.values?.dean?._id && user?.getUser?.profileImageUrl || ""}
                size="xl"
              />
            </div>
            <div className='col-span-2'>
              <span className="block text-sm font-light text-gray-700">
                Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {form?.values?.dean?._id && user?.getUser?.name || "N/A"}
              </div>
            </div>
          </div>
          <div>
            <UsersPicker
              id="dean._id"
              label="Dean"
              placeholder="Select Dean or search by name"
              required={true}
              filter={{
                role: {
                  eq: "DEAN"
                }
              }}
              rawId
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">

          <div className="">
            <TextInput
              id="phone"
              label="Phone Number"
              type="text"
              placeholder="e.g. 0244923099"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="email"
              label="Email Address"
              type="text"
              placeholder="e.g. Solomon Nana Kweku Bekoe"
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="digitalAddress"
              label="Digital Address"
              type="text"
              placeholder="e.g. AE-0223-1090"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="officeAddress"
              label="Office Address"
              type="text"
              placeholder="No. 13 Anunte Drive, Adenta Housing Down"
              required={true}
              {...form}
            />
          </div>

        </div>
      </div>

    </div>
  )
}

export default SchoolForm