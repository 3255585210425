import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, UserForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { UserSchema } from 'components/forms/user/schema';

export const GET_USER = gql`
query GetUser($filter: UserFilter! , $populate: [String]) {
  user:getUser(filter: $filter,populate: $populate) {
    _id
    code
    createdAt
    email
    staffId
    gender
    meta {
      isFirstLogin
      isSuspended
    }
    name
    phone
    profileImageUrl
    role
    school {
      name
      _id
      code
    }
    campus {
      _id
      code
      name
    }
    department {
      name
      _id
      code
    }
    updatedAt
  }
}
`

export const UPDATE_USER = gql`
mutation UpdateUser($updateUserId: ID!, $input: UserInput!) {
  updateUser(id: $updateUserId, input: $input) {
    _id
    code
  }
}
`

export default function UpdateStaffContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_USER, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      }
    },
    notifyOnNetworkStatusChange: false
  });

  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER)

  const form = useFormik({
    initialValues: {
      name: "",
      staffId: "",
      gender: "",
      phone: "",
      email: "",
      profileImageUrl: "",
      role: searchParams.role || "",
      school: "",
      department: "",
      campus: ""
    },
    validationSchema: UserSchema,
    onSubmit: async ({ school, department, campus, ...values }) => {
      await updateUser({
        variables: {
          updateUserId: searchParams.id,
          input: {
            ...values,
            ...(school && { school }),
            ...(campus && { campus }),
            ...(department && { department })
          }
        }
      }).then(({ data }) => {
        if (data.updateUser._id) {
          toast(JSON.stringify({ type: "success", title: "Staff Edited Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Staff" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      name: data?.user?.name || "",
      staffId: data?.user?.staffId || "",
      gender: data?.user?.gender || "",
      phone: data?.user?.phone || "",
      email: data?.user?.email || "",
      profileImageUrl: data?.user?.profileImageUrl || "",
      role: data?.user?.role || "",
      school: data?.user?.school?._id || "",
      department: data?.user?.department?._id || "",
      campus: data?.user?.campus?._id || "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user, searchParams.id, open])
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Edit User Information"
      description="Provide the details to update user"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={updating}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? "Editing User..." : "Edit User"}
          </button>

        </>
      )}>
      {data?.user?._id && (
        <UserForm
          form={form}
        />
      )}
    </Modal>
  )
}