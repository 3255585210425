import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { currentUserVar } from "apollo/cache/auth";
import { SchoolPicker, DepartmentPicker, ProgrammePicker, AdmissionProgrammePicker } from "containers";
import { FormikProps } from "formik"
import { FC, useEffect, useState } from "react"

export interface ReassignAdmissionProgrammeFormProps {
  form: FormikProps<{
    admissionProgramme: string
    name: string
    programme: string;
    department: string;
    school: string;
  }>;
  programme?: any
}


const GET_ADMISSION_PROGRAMME_LITE = gql`
query GetAdmissionProgramme($filter: AdmissionProgrammeFilter!, $populate: [String]) {
  admissionProgramme: getAdmissionProgramme(filter: $filter, populate: $populate) {
    _id
    code
    name
  }
}
`


const ReassignAdmissionProgrammeForm: FC<ReassignAdmissionProgrammeFormProps> = ({ form, programme }) => {
  const currentUser = useReactiveVar(currentUserVar)

  const [name, setName] = useState("")

  const [getAdmissionProgramme, { data: admissionsProgrammeData }] = useLazyQuery(GET_ADMISSION_PROGRAMME_LITE, {
    variables: {
      filter: {
        programme: {
          eq: programme?._id || ""
        }
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
  });

  const hasDifferentName = admissionsProgrammeData?.admissionProgramme?.name !== programme?.name
  const hasSourceProgrammeName = admissionsProgrammeData?.admissionProgramme?.name === name

  useEffect(() => {
    if (programme?._id) {
      getAdmissionProgramme()
    }
  }, [programme?._id]);

  useEffect(() => {
    setName(admissionsProgrammeData?.admissionProgramme?.name)
  }, [admissionsProgrammeData])


  const updateNameOnForm = () => {
    setName(programme?.name)
    form.setFieldValue("name", programme?.name)
  }

  const notNull = (value: any) => value

  

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Admission Programme Information</span>
        <div className="grid grid-cols-1 gap-6 mt-2">
          {
            hasDifferentName && (
              <div className="grid grid-cols-6 gap-2">
                <div className="w-full col-span-5">
                  <input
                    value={name}
                    disabled
                    className=" border-gray-400 px-3 text-sm border w-full py-2 rounded-md"
                  />
                </div>
                <div className="col-span-1">
                  <button
                    type="button"
                    disabled={!hasSourceProgrammeName}
                    className="text-sm text-white bg-primary-600 px-3 py-2.5 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                    onClick={() => updateNameOnForm()}
                  >
                    Update Name
                  </button>
                </div>
              </div>
            )
          }
          <div className="">
            <AdmissionProgrammePicker
              id="admissionProgramme"
              label="Admission Programme"
              placeholder="e.g. Accra East School"
              required={true}
              {...form}
              rawId={true}
              disabled={true}
            />
          </div>

          <div>
            <SchoolPicker
              id="school"
              label="School"
              placeholder="e.g. Accra East School"
              required={true}
              {...form}
              rawId={true}
              disabled={!!currentUser?.school?._id}
              filter={{ _id: { in: [form?.values?.school, programme?.school?._id].filter(notNull) } }}
            />
          </div>

          <div>
            <DepartmentPicker
              id="department"
              label="Department"
              placeholder="e.g. Computer Science"
              required={true}
              rawId={true}
              filter={{ _id: { in: [form?.values?.department, programme?.department?._id].filter(notNull) } }}
              {...form}
            />
          </div>

        </div>
      </div>

    </div>
  )
}

export default ReassignAdmissionProgrammeForm