import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import { currentUserVar } from "apollo/cache/auth";
import { PieChart } from "components";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
import numeral from "numeral";
import { classNames } from "utils";
import { MoonLoader } from "react-spinners";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { currentConfigVar } from "apollo/cache/config";
import BarChart from "components/charts/bar-chart";
import { getRandomHexColor } from "utils/get-random-color";
import { CirclePacking } from "@nivo/circle-packing";
import CirclePackingChart from "components/charts/circle-packing-chart";
import { toAbbreviation } from "utils/general";
dayjs.extend(weekOfYear);

interface ShortlistedDepartmentCirclesChartProps {
  cycle?: string;
}

const GET_SHORTLISTED_STATS = gql`
  query Query {
    countShortlistedByDepartment
  }
`;

const GET_APPLICATIONS_STATS = gql`
  query Query {
    countApplicationsByDepartment
  }
`;

const ShortlistedDepartmentCirclesChartContainer: FC<
  ShortlistedDepartmentCirclesChartProps
> = ({ cycle }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = cycle && { cycle: { eq: cycle } };

  const { loading, data } = useQuery(GET_SHORTLISTED_STATS, {
    variables: {
      filter: {
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
    pollInterval,
  });

  const { loading: loadingApplications, data: applicationData } = useQuery(
    GET_APPLICATIONS_STATS,
    {
      variables: {
        filter: {
          ...cycleFilters,
        },
      },
      notifyOnNetworkStatusChange: false,
      fetchPolicy: "no-cache",
      pollInterval,
    }
  );

  const colors = [
    "#5E95FC",
    "#27d163",
    "#FFD36C",
    "#F8649F",
    "#72DBDB",
    "#A955A7",
  ];

  const circlesData = data?.countShortlistedByDepartment?.map(
    (department: any, index: number) => ({
      name: department?.name,
      value: department?.count,
      color: colors?.[index] || getRandomHexColor(),
    })
  );

  const circlesDataApplications =
    applicationData?.countApplicationsByDepartment?.map(
      (department: any, index: number) => {
        const foundShortlisted = circlesData?.find(
          (departmentShortlisted: any) =>
            departmentShortlisted?.name === department?.name
        );
        return {
          name: department.name,
          children: [
            {
              color: colors?.[index] || getRandomHexColor(),
              name: `Applications ${index}`,
              value: department?.count,
            },
            { ...foundShortlisted, name: `Shortlisted ${index}` },
          ],
        };
      }
    );

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Departments
          </h3>
          <p className="text-xs text-gray-400">
            Displays shortlisted by departments
          </p>
        </div>
        {loading && <MoonLoader size={20} />}
      </div>
      <CirclePackingChart
        data={{
          name: "main",
          color: "#E5E1EE",
          children: circlesDataApplications,
        }}
        idAccessor="name"
        valueAccessor="value"
        showLeavesOnly={true}
        chartLabel={(data) => `${toAbbreviation(data.id).replace(/\d+/g, '')}`}
        tooltipLabel={(data) => {
          return <div className="bg-white p-2 shadow">{data.path[1]} ({data.path?.[0]?.replace(/\d+/g, '')}): <span className="font-bold">{data?.value}</span></div>;
        }}
      />
    </>
  );
};

export default ShortlistedDepartmentCirclesChartContainer;
