import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Avatar, SelectInput, Shimmers, TableComponent } from 'components';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo } from 'react'
import { useMatch, useMatches, useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, classNames, useDownloadFile, withPermissions } from 'utils';
import config from 'config';
import { currentConfigVar } from 'apollo/cache/config';
import ActionButton, { Action } from 'components/buttons/action-button';
import lodash from 'lodash';
import { useToggle } from 'react-use';
import ViewAdmittedContainer from './view';
import AdmissionProgrammePickerContainer from 'containers/admission-programme-picker';
import { currentUserVar } from 'apollo/cache/auth';
import toast from 'react-hot-toast';
import CreateTuitionContainer from './create';
import UpdateVendorContainer from 'pages/vendors/update';
import UpdateTuitionContainer from './update';
// import StartTuitionContainer from './start-tuition';

const GET_TUITIONS = gql`
query GetTuitions($filter: TuitionFilter, $pagination: Pagination, $search: SearchOperator, $populate: [String]) {
  items:getTuitions(filter: $filter, pagination: $pagination, search: $search, populate: $populate) {
    _id
    code
    lastUpdatedBy {
      _id
      name
    }
    name
    updatedAt
    fees {
      type
    }
  }
  itemsCount:getTuitionsCount(filter: $filter, search: $search)
}
`


const TuitionPage: FC = () => {
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [admissionProgramme, setAdmissionProgramme] = useUrlState("admissionProgramme");
  const [school, setSchool] = useUrlState("school");
  const matches = useMatches<LocationGenerics>()


  useEffect(() => {
    setSchool(currentUser?.school?._id)
  }, [currentUser]);

  const variables = useMemo(() => ({
    pagination: {
      limit: searchParams.pageSize || 12,
      skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
    },
    ...((searchParams?.search || "")?.length > 0 ? {
      search: {
        query: searchParams?.search,
        options: ["CaseInsensitive"],
        fields: ["applicationName", "applicationEmail", "applicationCode"]
      }
    } : {}),
    filter: {
      cycle: {
        eq: params.admissionCycle
      },
    },
    sort: {

    },
    populate: ["lastUpdatedBy"]
  }), [searchParams]);

  const { data, loading, networkStatus, refetch } = useQuery(GET_TUITIONS, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
        ...(!lodash.isNumber(old?.month) ? { month: dayjs().month() } : {}),
        ...(!old?.year ? { year: dayjs().year() } : {}),
      })
    })
  }, [navigate])



  return (
    <main className="flex-1 flex flex-col overflow-hidden h-full bg-gray-50">

      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'tuition'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">


              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Fee Types
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Last Updated By
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Last Updated At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-600 dark:text-gray-100">{item?.code || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-center">
                  <div className="font-medium text-gray-600 dark:text-gray-100">{item?.name || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-center text-gray-500 border-b border-gray-200">
                  <div className="font-medium text-gray-600 dark:text-gray-100">{item?.fees.length ? item?.fees?.map((fee: any) => fee?.type).join(", ") : "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="flex flex-row justify-center">
                    {
                      item?.lastUpdatedBy ?
                        <>
                          <div className="h-10 w-10 flex-shrink-0">
                            <Avatar
                              disabled={true}
                              src={item?.lastUpdatedBy?.profileImageUrl}
                              alt={item?.lastUpdatedBy?.name || "N A"}
                            />
                          </div>
                          <div className="ml-4 self-center">
                            <div className="text-gray-600 dark:text-gray-100">{item?.lastUpdatedBy?.name || "-"}</div>
                          </div>
                        </>
                        :
                        <div className="text-gray-600 dark:text-gray-100">N/A</div>
                    }
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-400 border-b border-gray-200 text-center">
                  <div className="text-gray-400 dark:text-gray-100">{item?.updatedAt ? dayjs(item?.updatedAt).format("MMM DD, YYYY - HH:mm") : "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action='update'
                      onClick={dispatchAction(item?._id, "update")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(["*:*", "tuition:*", "tuition:create"])(
        <CreateTuitionContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}

      {!!searchParams.id?.length && (
        <>
          <ViewAdmittedContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />

          {withPermissions(["*:*", "tuition:*", "tuition:update"])(
            <UpdateTuitionContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
        </>
      )}
    </main>
  )
}

export default TuitionPage;
