import { Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { TextInput } from "components/core";
import { useFormik } from "formik"
import _ from "lodash";
import { FC } from "react"
import { classNames, wrapClick } from "utils";

interface ExaminationsInfoFormProps {
  handleNext: (values: any) => void
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}


const ExaminationsInfoForm: FC<ExaminationsInfoFormProps> = ({ initialValues, handleNext, handlePrevious, handleCancel }) => {

  const form = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Examinations Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">

            <div className="">
              <label htmlFor={"requiresExamination"} className="block text-sm font-medium text-gray-700">
                Requires Entrance Examinations?
              </label>
              <div className="mt-1">
                <Switch.Group as="div" className="">
                  <Switch
                    checked={_.get(form.values, `requiresExamination`)}
                    onChange={(_val: boolean) => form.setFieldValue(`requiresExamination`, _val)}
                    className={classNames(
                      _.get(form.values, "requiresExamination") ? "bg-primary-600" : "bg-gray-200 dark:bg-gray-700",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        _.get(form.values, "requiresExamination") ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-800 shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
            {_.get(form.values, "requiresExamination") && (
              <>
                <div className="col-start-1">
                  <TextInput
                    id="maximumExaminationScore"
                    label="Maximum Examination Score"
                    type="number"
                    placeholder="eg. 75"
                    required={true}
                    min={0}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="requiredExaminationScore"
                    label="Required Examination Score"
                    type="number"
                    placeholder="eg. 100"
                    required={true}
                    max={_.get(form.values, "maximumExaminationScore")}
                    min={0}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="examinationScoreWeight"
                    label="Examination Score Weight"
                    type="number"
                    placeholder="eg. 100"
                    required={true}
                    postText="%"
                    max={100}
                    min={0}
                    {...form}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Interview Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">

            <div className="">
              <label htmlFor={"requiresInterview"} className="block text-sm font-medium text-gray-700">
                Requires Entrance Interview?
              </label>
              <div className="mt-1">
                <Switch.Group as="div" className="">
                  <Switch
                    checked={_.get(form.values, `requiresInterview`)}
                    onChange={(_val: boolean) => form.setFieldValue(`requiresInterview`, _val)}
                    className={classNames(
                      _.get(form.values, "requiresInterview") ? "bg-primary-600" : "bg-gray-200 dark:bg-gray-700",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        _.get(form.values, "requiresInterview") ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-800 shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
            {_.get(form.values, "requiresInterview") && (
              <>
                <div className="col-start-1">
                  <TextInput
                    id="maximumInterviewScore"
                    label="Maximum Interview Score"
                    type="number"
                    placeholder="eg. 75"
                    required={true}
                    min={0}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="requiredInterviewScore"
                    label="Required Interview Score"
                    type="number"
                    placeholder="eg. 100"
                    required={true}
                    max={_.get(form.values, "maximumInterviewScore")}
                    min={0}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="interviewScoreWeight"
                    label="Interview Score Weight"
                    type="number"
                    placeholder="eg. 100"
                    required={true}
                    postText="%"
                    max={100}
                    min={0}
                    {...form}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default ExaminationsInfoForm