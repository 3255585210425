import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, SelectInput, Shimmers, TableComponent } from "components";
import _ from "lodash";
import dayjs from "dayjs";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, withPermissions } from "utils";
import config from "config";
import { PlusIcon } from "@heroicons/react/24/outline";
import CreateProgrammeContainer from "./create";
import UpdateProgrammeContainer from "./update";
import ViewProgrammeContainer from "./view";
import { DepartmentPicker, ExportDataContainer, SchoolPicker } from "containers";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import lodash from "lodash";
import { currentUserVar } from "apollo/cache/auth";

const GET_PROGRAMMES = gql`
  query GetProgrammes(
    $filter: ProgrammeFilter
    $search: SearchOperator
    $pagination: Pagination
    $sort: ProgrammeSort
    $populate: [String]
  ) {
    items: getProgrammes(
      filter: $filter
      search: $search
      pagination: $pagination
      sort: $sort
      populate: $populate
    ) {
      _id
      code
      description
      level
      name
      department {
        _id
        name
      }
      createdAt
      updatedAt
    }
    itemsCount: getProgrammesCount(filter: $filter, search: $search)
  }
`;

const ProgrammesPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [school, setSchool] = useUrlState("school");
  const [department, setDepartment] = useUrlState("department");
  const [level, setLevel] = useUrlState("level");

  useEffect(() => {
    if (currentUser?.school?._id) {
      setSchool(currentUser?.school?._id)
    }
    if (currentUser?.department?._id) {
      setDepartment(currentUser?.department?._id)
    }
  }, [currentUser]);

  const variables = useMemo(
    () => ({
      pagination: {
        limit: searchParams.pageSize || 12,
        skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
      },
      ...((searchParams?.search || "")?.length > 0
        ? {
          search: {
            query: searchParams?.search,
            options: ["CaseInsensitive"],
            fields: ["name", "code"],
          },
        }
        : {}),
      filter: {
        ...(school && { school: { eq: school } }),
        ...(level && { level: { eq: level } }),
        ...(department && { department: { eq: department } })
      },
      sort: {},
      populate: ['department'],
    }),
    [searchParams]
  );

  const { data, loading, refetch } = useQuery(GET_PROGRAMMES, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "programmes:*", "programmes:create"])(
              <button
                type="button"
                onClick={wrapClick(() => setModal("create"))}
                className="ml-3 inline-flex items-center rounded border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add New Programme
              </button>
            )}
          </>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"programmes"}
            refetch={refetch}
            loading={loading}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(
                lodash.get(data, "itemsCount", 0) /
                (searchParams?.pageSize || config.constants.pageSize)
              ),
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="Programme"
                variables={variables}
              />
            )}
            renderHeaderItems={() => (
              <div className="space-x-4 flex flex-row">

                {
                  school && (
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                      <DepartmentPicker
                        id="department"
                        labelHidden={true}
                        rawId={true}
                        setFieldValue={(field: any, value: string) =>
                          setDepartment(value)
                        }
                        filter={{}}
                        disabled={!!currentUser?.department?._id}
                        values={{ department: department, school: school }}
                      />
                    </div>
                  )
                }

                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                  <SchoolPicker
                    id="school"
                    labelHidden={true}
                    rawId={true}
                    setFieldValue={(field: any, value: string) =>
                      setSchool(value)
                    }
                    disabled={!!currentUser?.school?._id}
                    values={{ school: school }}
                  />
                </div>

                <SelectInput
                  handleBlur={() => null}
                  handleChange={(e: any) => setLevel(e.target.value)}
                  id="level"
                  label="Level"
                  placeholder='Filter Level'
                  labelHidden
                  options={[
                    { label: "Graduate", value: "GRADUATE" },
                    { label: "Undergraduate", value: "UNDERGRADUATE" },
                  ]}
                  values={{ level }}
                  required={true}
                />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Level
                </th>
                {!school && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Department
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Created At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                {!school && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.name || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-100">
                    {item?.level || "N/A"}
                  </div>
                </td>
                {!school && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="text-gray-500 dark:text-gray-100">
                      {item?.department?.name || "N/A"}
                    </div>
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-100">
                    {item?.createdAt
                      ? dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions([
                      "*:*",
                      "programmes:*",
                      "programmes:update",
                    ])(
                      <ActionButton
                        action="update"
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(["*:*", "programmes:*", "programmes:create"])(
        <CreateProgrammeContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "programmes:*", "programmes:update"])(
            <UpdateProgrammeContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewProgrammeContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ProgrammesPage;
