import { TextInput } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface CampusFormProps {
  form: FormikProps<{
    digitalAddress: string;
    email: string;
    name: string;
    officeAddress: string;
    phone: string;
  }>;
}

const CampusForm: FC<CampusFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Campus Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <TextInput
              id="code"
              label="Campus Code"
              type="text"
              placeholder="e.g. 07"
              disabled={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="name"
              label="Campus Name"
              type="text"
              placeholder="e.g. Accra West Campus"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">

          <div className="">
            <TextInput
              id="phone"
              label="Phone Number"
              type="text"
              placeholder="e.g. 0244923099"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="email"
              label="Email Address"
              type="text"
              placeholder="e.g. Solomon Nana Kweku Bekoe"
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="digitalAddress"
              label="Digital Address"
              type="text"
              placeholder="e.g. AE-0223-1090"
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="officeAddress"
              label="Office Address"
              type="text"
              placeholder="No. 13 Anunte Drive, Adenta Housing Down"
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default CampusForm