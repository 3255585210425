import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { FC, useEffect, useState } from 'react';
import { currentUserVar } from 'apollo/cache/auth';
import { PieChart } from 'components';
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from 'dayjs';
import numeral from 'numeral';
import { classNames } from 'utils';
import { MoonLoader } from 'react-spinners';
import { LocationGenerics } from 'router/location';
import { useSearch } from 'react-location';
import { currentConfigVar } from 'apollo/cache/config';
import BarChart from 'components/charts/bar-chart';
import { getRandomHexColor } from 'utils/get-random-color';
dayjs.extend(weekOfYear)

interface ApplicationSchoolChartContainerProps {
  cycle?: string
}

const GET_APPLICATIONS_STATS = gql`
query Query($filter: CountBySchoolFilter) {
  countApplicationsBySchool(filter: $filter)
}
`;

const ApplicationSchoolChartContainer: FC<ApplicationSchoolChartContainerProps> = ({ cycle }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = (cycle && { cycle: { eq: cycle } })


  const { loading, data } = useQuery(GET_APPLICATIONS_STATS, {
    variables: {
      filter: {
        ...cycleFilters
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
    pollInterval
  })

  const colors = [
    "#5E95FC",
    "#27d163",
    "#FFD36C",
    "#F8649F",
    "#72DBDB",
    "#A955A7",
  ].reverse()


  const barData = data?.countApplicationsBySchool
    ?.sort((a: any, b: any) => a?.count - b?.count)
    ?.map((school: any, index: number) => ({
      school: school?.name,
      [school?.name]: school?.count,
      [`${school?.name}Color`]: colors?.[index] || getRandomHexColor()
    }))


  const keys = data?.countApplicationsBySchool?.map((school: any) => school?.name)

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">Schools</h3>
          <p className='text-xs text-gray-400'>Displays school choices within applications</p>
        </div>
        {loading && <MoonLoader size={20} />}
      </div>

      <BarChart
        keys={keys}
        indexBy='school'
        yLabel='Schools'
        xLabel='No. of Applicants'
        layout='horizontal'
        data={barData || []}
        chartLabel={(data) => `${data?.indexValue}: ${data?.value}`}
      />

    </>

  )
}

export default ApplicationSchoolChartContainer