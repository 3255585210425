import { wrapClick, withPermissions } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Modal, AdmissionProgrammeViewLite } from "components";
import { useSearch, useMatch, useMatches } from "react-location";
import { LocationGenerics } from "router/location";
import { TuitionPicker, VoucherClassPicker } from "containers";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";


const GET_ADMISSION_PROGRAMME = gql`
query GetAdmissionProgramme($filter: AdmissionProgrammeFilter!, $populate: [String]) {
  admissionProgramme: getAdmissionProgramme(filter: $filter, populate: $populate) {
    _id
    code
    createdAt
    updatedAt
    name
    duration
    school {
      _id
      code
      name
    }
    level
    department {
      _id
      name
    }
    programme {
      _id
      code
      name
      description
      level
    }
    tracks
    offerings {
      campus {
        _id
        code
        name
      }
      types
    }
    requirements {
      type
      description
      specifications {
        subject
        requiredGrade
      }
    }
    requiresInterview
    maximumInterviewScore
    requiredInterviewScore
    interviewScoreWeight
    requiresExamination
    maximumExaminationScore
    requiredExaminationScore
    examinationScoreWeight
    createdBy {
      _id
      code
      name
      phone
      email
      profileImageUrl
      gender
      role
      meta {
        isFirstLogin
        isSuspended
      }
    }
    lastUpdatedBy {
      _id
      code
      name
      phone
      email
      profileImageUrl
      gender
      role
      meta {
        isFirstLogin
        isSuspended
      }
    }
    tuition {
      _id
      name
    }
    voucherClass {
      _id
      name
    }
  }
}
`

const CONFIGURE_ADMISSION_PROGRAMME = gql`
mutation ConfigureAdmissionProgramme($id: ID!, $input: ConfigureAdmissionProgrammeInput!) {
  configureAdmissionProgramme(id: $id, input: $input) {
    _id
  }
}
`

export default function ConfigureAdmissionProgrammeContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const [voucherClass, setVoucherClass] = useState<any>("");
  const [tuition, setTuition] = useState<any>("");

  const matches = useMatches<LocationGenerics>()
  const cycle = matches?.[0]?.params?.admissionCycle

  const { data, loading, refetch: refetchAdmissionProgramme } = useQuery(GET_ADMISSION_PROGRAMME, {
    variables: {
      filter: {
        ...(searchParams.id && ({ _id: { eq: searchParams.id } }))
      },
      populate: ["createdBy", "lastUpdatedBy", "school", "programme", "offerings.campus", "department"]
    },
    notifyOnNetworkStatusChange: false,
  });

  const [updateAdmissionProgrammeVoucherClass, { loading: loadingConfigure }] = useMutation(CONFIGURE_ADMISSION_PROGRAMME)

  const handleSubmit = async () => {
    await updateAdmissionProgrammeVoucherClass({
      variables: {
        id: searchParams.id,
        input: {
          voucherClass: voucherClass,
          tuition: tuition
        }
      }
    }).then(({ data }) => {
      if (data?.configureAdmissionProgramme?._id) {
        toast(JSON.stringify({ type: "success", title: "Voucher configured successfully" }))
        refetch?.();
        setOpen(false)
      }
      else {
        toast(JSON.stringify({ type: "error", title: "Could not configure programme" }))
      }
    })
  }

  useEffect(() => {
    setVoucherClass(data?.admissionProgramme?.voucherClass?._id)
    setTuition(data?.admissionProgramme?.tuition?._id)
  }, [data]);

  useEffect(() => {
    refetchAdmissionProgramme()
  }, [open]);

  const updateItem = () => {
    handleSubmit()
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Configure Class Details"
      description="Details of admission programme are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:configure"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              {loadingConfigure ? "Configuring" : "Configure"}
            </button>
          )}
        </>
      )}
    >
      <AdmissionProgrammeViewLite
        admissionProgramme={data?.admissionProgramme}
      />
      <div className="mt-4">
        <VoucherClassPicker
          id="voucherClass"
          label="Select Class"
          rawId
          setFieldValue={(field: any, value: string) =>
            setVoucherClass(value)
          }
          values={{ voucherClass: voucherClass }}
          filter={{
            cycle
          }}
        />
      </div>
      <div className="mt-4">
        <TuitionPicker
          id="tuition"
          label="Select Tuition"
          rawId
          setFieldValue={(field: any, value: string) =>
            setTuition(value)
          }
          values={{ tuition: tuition }}
          filter={{
            cycle
          }}
        />
      </div>
    </Modal>
  )
}