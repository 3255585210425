import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import ConfigureFXRatesForm from 'components/forms/fx-rates';
import { useEffect } from 'react';

const CONFIGURE_FX_RATES = gql`
mutation UpdateAdmissionCyclesFXRates($input: UpdateAdmissionCyclesFXRatesInput!) {
  updateAdmissionCyclesFXRates(input: $input) {
    _id
  }
}
`;

const GET_CYCLES_FX_RATES = gql`
query GetAdmissionCycles($filter: AdmissionCycleFilter, $populate: [String], $pagination: Pagination) {
  getAdmissionCycles(filter: $filter, populate: $populate, pagination: $pagination) {
    fxRates {
      USDXGHS
      lastUpdatedAt
      lastUpdatedBy {
        _id
        name
      }
    }
  }
}
`

export default function ConfigureFXRateContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const [configureFXRate, { loading }] = useMutation(CONFIGURE_FX_RATES)

  const { data, loading: loadingRates, refetch: refetchRates } = useQuery(GET_CYCLES_FX_RATES, {
    variables: {
      "populate": ["fxRates.lastUpdatedBy"],
      "pagination": {
        "limit": 1,
        "skip": 0
      }
    },
  })

  const fxRates = data?.getAdmissionCycles[0]?.fxRates

  const form = useFormik({
    initialValues: {
      USDXGHS: 1,
    },
    // validationSchema: InvoiceSchema,
    onSubmit: async ({ ...values }) => {
      await configureFXRate({
        variables: {
          input: {
            fxRates: {
              USDXGHS: values.USDXGHS
            }
          }
        }
      }).then(({ data }) => {
        if (data.updateAdmissionCyclesFXRates.length) {
          toast(JSON.stringify({ type: "success", title: "Rates configured successfully" }));
          refetch?.();
          refetchRates()
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Rates could not be configured" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    },
  })

  useEffect(() => {
    form.setValues({
      USDXGHS: fxRates?.USDXGHS
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fxRates, loadingRates, open])


  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Configure FX Rates"
      size='2xl'
      description="Provide the current rate you want invoices to be converted to"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 disabled:bg-gray-300 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Configuring Rates..." : "Configure Rates"}
          </button>
        </>
      )}>
      <ConfigureFXRatesForm
        form={form}
        data={{
          lastUpdatedAt: fxRates?.lastUpdatedAt,
          lastUpdatedBy: fxRates?.lastUpdatedBy?.name
        }}
      />
    </Modal>
  )
}