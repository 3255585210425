import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface TuitionPickerContainerProps {
  filter?: {
    cycle?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any
  rawId?: boolean;
}

const GET_TUITIONS = gql`
query GetTuitions($filter: TuitionFilter) {
  tuitions:getTuitions(filter: $filter) {
    _id
    name
  }
}
`;

const TuitionPickerContainer: FC<TuitionPickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_TUITIONS, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        ...(filter?.cycle && {
          cycle: {
            eq: filter?.cycle
          }
        })
      }
    },
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "tuition"}
      label={label ?? "Tuition"}
      placeholder="Select Tuition"
      optionsLoading={loading}
      options={(data?.tuitions ?? [])?.map((tuition: any) => ({
        label: {
          title: tuition.name as string
        },
        value: rawId ? tuition._id : tuition
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default TuitionPickerContainer