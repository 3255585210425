import * as Yup from 'yup';

export const TuitionSchema = Yup.object().shape({
  code: Yup.string(),
  name: Yup.string().required('Name is a required field'),
  description: Yup.string().required('Description is a required field'),
  fees: Yup.array().of(
    Yup.object().shape({
      type: Yup.string(),
      fileUrl: Yup.string(),
    })
  ),
});

export const TuitionInitialValues = {
  code: "",
  name: "",
  description: "",
  fees: [
    {
      type: "LOCAL",
      fileUrl: ""
    },
    {
      type: "INTERNATIONAL",
      fileUrl: ""
    },
  ],
}