import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC, useState } from "react";
import { currentUserVar } from "apollo/cache/auth";
import { PieChart } from "components";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
import numeral from "numeral";
import { classNames } from "utils";
import { MoonLoader } from "react-spinners";
import { LocationGenerics } from "router/location";
import { useMatch, useSearch } from "react-location";
import { currentConfigVar } from "apollo/cache/config";
import { ButtonGroup } from "components/buttons/button-group";
dayjs.extend(weekOfYear);

interface ApplicantsLevelChartContainerProps {
  status?: "PENDING" | "SUBMITTED";
  cycle?: string;
}

const LevelChart = ({ status, cycle }: { status?: string; cycle?: string }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();

  const cycleFilters = cycle && {
    cycle: { eq: cycle },
  };

  const statusFilters = status && {
    status: { eq: status },
  }

  const GET_APPLICATIONS_STATS = gql`
    query Query(
      $undergraduateFilter: ApplicationFilter
      $graduateFilter: ApplicationFilter
    ) {
      undergraduate: getApplicationsCount(filter: $undergraduateFilter)
      graduate: getApplicationsCount(filter: $graduateFilter)
    }
  `;

  const { loading, data } = useQuery(GET_APPLICATIONS_STATS, {
    variables: {
      undergraduateFilter: {
        level: {
          eq: "UNDERGRADUATE",
        },
        ...statusFilters,
        ...cycleFilters,
      },
      graduateFilter: {
        level: {
          eq: "GRADUATE",
        },
        ...statusFilters,
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
    pollInterval,
  });

  const pieData = [
    {
      color: "#017CB8",
      bgColor: "bg-blue-600",
      id: "Undergraduates",
      label: "Undergraduates",
      value: data?.undergraduate || 0,
    },
    {
      color: "#d352e9",
      bgColor: "bg-blue-400",
      id: "Graduates",
      label: "Graduates",
      value: data?.graduate || 0,
    },
  ];

  return (
    <>
      <PieChart data={pieData} />
      <div className="flex flex-row justify-center">
        {pieData?.map?.((item: any) => (
          <div
            key={item?._id}
            className="rounded-lg bg-white p-2 flex space-x-4"
          >
            <div>
              <div
                style={{ backgroundColor: item.color }}
                className={classNames(
                  "rounded-md flex items-center justify-center p-2.5"
                )}
              >
                <span className="text-lg text-white" aria-hidden="true"></span>
              </div>
            </div>
            <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
              <div>
                <dt className="truncate text-xs text-gray-500">
                  {item?.label}
                </dt>
                <dd className="flex items-baseline justify-between">
                  <p className="text-xl font-normal text-gray-900">
                    {numeral(item?.value).format("0,0")}
                  </p>
                </dd>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const ApplicationsLevelChartContainer: FC<ApplicantsLevelChartContainerProps> = ({
  status,
  cycle,
}) => {
  const [statusValue, setStatus] = useState(status || "");

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
        <h3 className="text-md font-medium leading-6 text-gray-900">{status === "SUBMITTED" ? "Submitted Applications" : status === "PENDING" ? "Pending Applications" :  "All Applications"}</h3>
          <p className="text-xs text-gray-400">Displays application count by level</p>
        </div>

        <div className="flex flex-col">
          <ButtonGroup
            buttons={[
              { label: "Total", value: "TOTAL" },
              { label: "Pending", value: "PENDING" },
              { label: "Submitted", value: "SUBMITTED" },
            ]}
            onChangeValue={(value) =>
              value === "TOTAL" ? setStatus("") : setStatus(value || "")
            }
            selectedValue={statusValue || "TOTAL"}
          />
        </div>
      </div>
      <LevelChart status={statusValue} cycle={cycle} />
    </>
  );
};

export default ApplicationsLevelChartContainer;
