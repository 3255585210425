import { wrapClick } from 'utils';
import { gql, useMutation, } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, CampusForm } from 'components';
import { CampusSchema } from 'components/forms/campus/schema';

const CREATE_CAMPUS = gql`
  mutation CreateCampus($input: CampusInput!) {
    createCampus(input: $input) {
      _id
    }
  }
`;

export default function CreateCampusContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void}) {
 
  const [createCampus, { loading }] = useMutation(CREATE_CAMPUS)

  const form = useFormik({
    initialValues: {
      digitalAddress: "",
      email: "",
      name: "",
      officeAddress: "",
      phone: "",
    },
    validationSchema: CampusSchema,
    onSubmit: async (values) => {
      await createCampus({
        variables: {
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data.createCampus._id) {
          toast(JSON.stringify({ type: "success", title: "Campus created successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Campus" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Campus"
      description="Provide the details to add a new campus"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Campus..." : "Add Campus"}
          </button>

        </>
      )}>
      <CampusForm
        form={form}
      />
    </Modal>
  )
}