import { FC, useRef } from 'react'
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import _ from 'lodash';
import { ApplicationDetailsView } from 'components';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';


const GET_APPLICATION = gql`
  query GetApplication($filter: ApplicationFilter!, $populate: [String]) {
    application: getApplication(filter: $filter, populate: $populate) {
      _id
      code
      personalInformation {
        lastName
        middleName
        firstName
        dateOfBirth
        placeOfBirth
        gender
        maritalStatus
        numberOfChildren
        nationality
        isPhysicallyChallenged
        physicalChallenges
        email
        phone
        secondaryPhone
        bioFileUrl
        bioFileUrls
        emergencyContacts {
          name
          relationship
          phone
          email
        }
        profileImageUrl
      }
      programmeInformation {
        preferredCampus {
          _id
          code
          name
          phone
          email
          digitalAddress
          officeAddress
          createdAt
          updatedAt
        }
        preferredOfferingType
        programmeSelections {
          programme {
            _id
            code
            name
            level
            tracks
            requiresInterview
            maximumInterviewScore
            requiredInterviewScore
            interviewScoreWeight
            requiresExamination
            maximumExaminationScore
            requiredExaminationScore
            examinationScoreWeight
            createdAt
            updatedAt
          }
          school {
            _id
            name
          }
        }
      }
      educationalHistoryInformation {
        type
        history {
          institution
          idNumber
          programme
          graduatingMonth
          graduatingYear
          cgpa
          gpaScale
          subjectGrades {
            subject
            grade
          }
          class
          certificateFileUrl
          transcriptFileUrl
          evaluationFileUrl
          essayFileUrl
          cvFileUrl
        }
      }
      employmentHistoryInformation {
        organization
        title
        startDate
        endDate
      }
      extraInformation {
        isExistingStudent
        existingStudentId
        existingStudentProgramme {
          _id
          code
          name
          description
          level
          createdAt
          updatedAt
        }
        sponsorType
        sponsorDetails {
          organization
          name
          title
          email
          phone
        }
      }
      referencesInformation {
        academic {
          organization
          name
          title
          address
          email
          phone
        }
        nonAcademic {
          organization
          name
          title
          address
          email
          phone
        }
      }
      status
      createdAt
      updatedAt
    }
  }
`

const formSteps = [
  {
    name: 'Personal Info',
    description: "Details",
    accessor: "personalInformation",
    SummaryComponent: ApplicationDetailsView.Personal,
  },
  {
    name: 'Programme Info',
    description: 'Details',
    accessor: "programmeInformation",
    SummaryComponent: ApplicationDetailsView.Programme,
  },
  {
    name: 'Education Info',
    description: 'Details',
    accessor: "educationalHistoryInformation",
    SummaryComponent: ApplicationDetailsView.Education,
  },
  {
    name: 'Employment Info',
    description: 'Details',
    accessor: "employmentHistoryInformation",
    SummaryComponent: ApplicationDetailsView.Employment,
  },
  {
    name: 'Reference Info',
    description: 'Details',
    accessor: "referencesInformation",
    SummaryComponent: ApplicationDetailsView.Reference,
  },
  {
    name: 'Extra Info',
    description: 'Details',
    accessor: "extraInformation",
    SummaryComponent: ApplicationDetailsView.Extra,
  },
]

const ApplicationDetailsContainerRaw: FC = () => {
  const currentConfig = useReactiveVar(currentConfigVar);
  const { params } = useMatch<LocationGenerics>();
  const printRef = useRef<any>();


  const { data: values, refetch, loading } = useQuery(GET_APPLICATION, {
    variables: {
      filter: {
        _id: {
          eq: params.application
        }
      },
      populate: [
        "programmeInformation",
        "programmeInformation.preferredCampus",
        "programmeInformation.programmeSelections.programme",
        "programmeInformation.programmeSelections.school",
        "referencesInformation.academic",
        "referencesInformation.nonAcademic",
        "extraInformation.existingStudentProgramme",
      ]
    },
    fetchPolicy: "no-cache"
  })

  return (
    <main className="h-screen print:h-full">
      <div className="flex-1 h-full overflow-y-scroll">
        <div className="space-y-6 divide-y divide-primary-500/50 p-6 print:p-0 overflow-y-auto flex-1 no-scrollbar">
          {formSteps.map(({ SummaryComponent, ...summary }, idx) => {
            return (
              <div key={idx} className={idx === 0 ? " py-4" : " py-4 pt-6"}>
                <>
                  <div className="flex justify-between items-center cursor-pointer">
                    <div>
                      <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">{summary?.name}</h3>
                      <p className="mt-1 text-xs text-gray-500">{summary?.description}</p>
                    </div>
                    <div />
                  </div>

                  <div className="mt-6">
                    <SummaryComponent
                      data={_.get(values?.application, summary?.accessor)}
                      values={values}
                      config={currentConfig}
                    />
                  </div>
                </>
              </div>
            )
          })}
        </div>
      </div>
    </main>
  )



}

export default ApplicationDetailsContainerRaw
