import dayjs from "dayjs";
import { pipe } from "ramda";
import { date } from "yup";

export function removeTypename(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  }

  const newObj: any = {};
  for (const key in obj) {
    if (key !== '__typename') {
      newObj[key] = removeTypename(obj[key]);
    }
  }

  return newObj;
}


export const EXAMINATION_GRADING = {
  ALEVEL: [
    { label: "A*", value: 1 },
    { label: "A", value: 2 },
    { label: "B", value: 3 },
    { label: "C", value: 4 },
    { label: "D", value: 5 },
    { label: "E", value: 6 },
    { label: "U", value: 7 },
  ],
  OLEVEL: [
    { label: "A*", value: 1 },
    { label: "A", value: 2 },
    { label: "B", value: 3 },
    { label: "C", value: 4 },
    { label: "D", value: 5 },
    { label: "E", value: 6 },
    { label: "F", value: 7 },
    { label: "G", value: 8 },
    { label: "U", value: 9 },
  ],
  WASSCE: [
    { label: "A1", value: 1 },
    { label: "B2", value: 2 },
    { label: "B3", value: 3 },
    { label: "C4", value: 4 },
    { label: "C5", value: 5 },
    { label: "C6", value: 6 },
    { label: "D7", value: 7 },
    { label: "E8", value: 8 },
    { label: "F9", value: 9 },
  ],
  SSSCE: [
    { label: "A", value: 1 },
    { label: "B", value: 2 },
    { label: "C", value: 3 },
    { label: "D", value: 4 },
    { label: "E", value: 5 },
    { label: "F", value: 6 },
  ],
};

export type ExaminationType = keyof typeof EXAMINATION_GRADING;



export const extractFirebaseName = (value: string) => {
  const fileNameWithExtension = decodeURIComponent(value.split("/").pop()?.split("?")[0] || "");
  const fileName = fileNameWithExtension.split("/").pop() || "";
  return fileName
}

const daysOfTheWeek = [
  'sun',
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
]

export const convertToDateFormat = ({ dateFormat, date }: { dateFormat?: string, date: Date }) => {
  return dayjs(date).format(dateFormat || "YYYY-MM-DD")
}

export const convertToDayRange = (date: Date) => {
  return {
    start: `${convertToDateFormat({ date: date, dateFormat: "YYYY-MM-DD" })}T00:00:00.000Z`,
    end: `${convertToDateFormat({ date: date, dateFormat: "YYYY-MM-DD" })}T23:59:59.000Z`
  }
}

export const convertAllDatesToDayRange = (dates: Date[]) => dates.map(convertToDayRange)

export function getDaysOfWeek(year: number, weekNumber: number): Date[] {
  const startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  
  // Ensure the week starts on Sunday (shift by one day if the start day is not Sunday)
  const dayOfWeek = startDate.getDay();
  if (dayOfWeek !== 0) {
    startDate.setDate(startDate.getDate() - dayOfWeek);
  }

  const daysOfWeek = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    daysOfWeek.push(currentDate);
  }

  return daysOfWeek;
}


export const generateApplicantsLineChartFilter = pipe(getDaysOfWeek,convertAllDatesToDayRange)


export function toAbbreviation(str: string) {
  // Split the string into an array of words
  const words = str.split(" ");
  
  // Map over the words array and get the first letter of each word
  const abbreviation = words.map(word => word.charAt(0)).join("");

  // Return the abbreviation
  return abbreviation.toUpperCase(); // Optionally, convert to uppercase
}


export function numberToWords(number: number) {
  const units = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  
  if (number >= 0 && number <= 9) {
    return units[number];
  } else {
    return "";
  }
}

export function makePositive(number: number): number {
  return Math.abs(number);
}

export function moveForwardFromDate(startDate: string, daysToMove: number, format?: string) {
  if (daysToMove === 0) {
    return dayjs(startDate).format(format || 'YYYY-MM-DD')
  }

  const newDate = dayjs(startDate).add(makePositive(daysToMove), 'day').format(format || 'YYYY-MM-DD');
  return newDate;
}

export function extractLastTwoNumbers(input: string): string {
  const parts = input.split('/');
  const stringToSlice = parts?.length === 1 ? parts?.[0] : parts?.[1]
  return stringToSlice?.slice(-2);
}

export function getTitle(sex: string, maritalStatus: string): string {
  if (sex === "MALE") {
    if (maritalStatus === "SINGLE") {
      return "Mr.";
    } else if (maritalStatus === "MARRIED") {
      return "Mr.";
    } else {
      return "Mr."; // Handle other marital statuses for males here
    }
  } else if (sex === "FEMALE") {
    if (maritalStatus === "SINGLE") {
      return "Miss";
    } else if (maritalStatus === "MARRIED") {
      return "Mrs.";
    } else {
      return "Ms."; // Handle other marital statuses for females here
    }
  } else {
    return sex === "MALE" ? "Mr." : "Miss."; // Handle unknown or other sex values here
  }
}

interface GenerateReferenceInputs {
  admissionDate: string;
  schoolName: string;
  programmeName: string;
}

const generateAbbreviation = (inputString: string) => {
  // Split the input string into words
  const words = inputString?.split(/\s+/);

  // Extract the first letter from each word
  const abbreviation = words?.map(word => word.charAt(0)).join('');

  // Convert the abbreviation to uppercase
  return abbreviation?.toUpperCase();
};


export const generateReference = (index: string, { schoolName, admissionDate, programmeName }: GenerateReferenceInputs) => {
  return [
    generateAbbreviation(schoolName),
    `G${generateAbbreviation(programmeName)}`,
    dayjs(admissionDate || new Date()).format("YYYY"),
    dayjs(new Date()).format("MMM").toUpperCase(),
    index
  ].join("/");
};

export function convertMonthsToYearsAndMonths(months: number): string {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  let result = '';
  if (years > 0) {
    result += years === 1 ? `${years} year` : `${years} years`;
    if (remainingMonths > 0) {
      result += remainingMonths === 1 ? ' and ' : ', ';
    }
  }
  if (remainingMonths > 0) {
    result += remainingMonths === 1 ? `${remainingMonths} month` : `${remainingMonths} months`;
  }

  return result;
}