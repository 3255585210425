import * as Yup from 'yup';

export const OfferProgrammeSchema = Yup.object().shape({
  school: Yup.string().required(),
  department: Yup.string().required(),
  campus: Yup.string().required(),
  programme: Yup.string().required(),
  level: Yup.string().required(),
  offeredProgrammeRemark: Yup.string().required(),
  offeringType: Yup.string().required(),
});

export const OfferProgrammeInitialValues = {
  school: "",
  department: "",
  campus: "",
  programme: "",
  level: "",
  offeredProgrammeRemark: "",
  offeringType: "",
}