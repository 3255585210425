import ActionButton, { Action } from 'components/buttons/action-button';
import { CalendarDaysIcon, PlusIcon } from '@heroicons/react/24/outline'
import { FC, useEffect, useMemo } from 'react'
import { Header, Shimmers, TableComponent } from 'components';
import { classNames, useUrlState, withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { useNavigate, useSearch } from 'react-location';

import CloneAdmissionCycleContainer from './clone';
import CreateAdmissionCycleContainer from './create';
import { ExportDataContainer } from 'containers';
import { LocationGenerics } from 'router/location';
import UpdateAdmissionCycleContainer from './update';
import ViewAdmissionCycleContainer from './view';
import _ from 'lodash';
import { calculateDateProgress } from 'utils/calculate-progress';
import config from 'config';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import { isAdmissionCloneAvailable } from 'utils/feature-flags';
import lodash from 'lodash';

const GET_ADMISSION_CYCLES = gql`
  query GetAdmissionCycles($filter: AdmissionCycleFilter, $pagination: Pagination, $search: SearchOperator, $sort: AdmissionCycleSort, $populate: [String]) {
    items: getAdmissionCycles(filter: $filter, pagination: $pagination, search: $search, sort: $sort, populate: $populate) {
      _id
      code
      name
      description
      openingDate
      closingDate
      createdAt
      updatedAt
    }
    itemsCount: getAdmissionCyclesCount(filter: $filter, search: $search)
  }
`

const AdmissionCyclesPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar)

  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }
  const navigateItem = (id: string) => () => {
    navigate({
      to: `./${id}/`
    })
  }

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const variables = useMemo(() => ({
    pagination: {
      limit: searchParams.pageSize || 12,
      skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
    },
    ...((searchParams?.search || "")?.length > 0 ? {
      search: {
        query: searchParams?.search,
        fields: ["name", "description", "code"]
      }
    } : {}),
    filter: {

    },
    sort: {

    },
    populate: []
  }), [searchParams])

  const { data, loading, networkStatus, refetch } = useQuery(GET_ADMISSION_CYCLES, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      })
    })
  }, [navigate])

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:create"])(
              <button
                type="button"
                onClick={wrapClick(() => setModal("create"))}
                className="ml-3 inline-flex items-center rounded border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add New Admission Cycle
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'admission cycles'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            defaultView={"grid"}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="AdmissionCycle"
                variables={variables}
              />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  opening date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  closing date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Created at
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => {
              const isPast = new Date(item?.closingDate) < new Date()
              const isOngoing = new Date(item?.openingDate) < new Date() && new Date(item?.closingDate) > new Date()
              const status = isOngoing ? "ON_GOING" : isPast ? "CLOSED" : "PENDING"

              return (
                <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">{item?.code || "N/A"}</div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">{item?.name || "N/A"}</div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">{item?.openingDate ? dayjs(item?.openingDate).format("MMM DD, YYYY") : "N/A"}</div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">{item?.closingDate ? dayjs(item?.closingDate).format("MMM DD, YYYY") : "N/A"}</div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <span
                      className={classNames(
                        status === "PENDING" ? `bg-cyan-100 text-cyan-800 dark:bg-cyan-600 dark:text-gray-50` : "",
                        status === "ON_GOING" ? `bg-primary-100 text-primary-800 dark:bg-primary-600 dark:text-gray-50` : "",
                        status === "CLOSED" ? `bg-red-100 text-red-800 dark:bg-red-600 dark:text-gray-50` : "",
                        status ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <span>
                        {_.startCase(status)}
                      </span>
                    </span>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">{item?.createdAt ? dayjs(item?.createdAt).format("MMM DD, YYYY") : "N/A"}</div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                    <div className='space-x-1'>
                      <ActionButton
                        action='view'
                        onClick={dispatchAction(item?._id, "view")}
                      />
                      {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:update"])(
                        <ActionButton
                          action='update'
                          onClick={dispatchAction(item?._id, "update")}
                        />
                      )}
                      {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:manage"])(
                        <ActionButton
                          action='expand'
                          onClick={navigateItem(item?._id)}
                        />
                      )}
                      {isAdmissionCloneAvailable() && withPermissions(["*:*", "admission-cycles:*", "admission-cycles:clone"])(
                        <ActionButton
                          action='clone'
                          onClick={dispatchAction(item?._id, "clone")}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              )
            }}
            renderGridLoader={() => (
              <>
                <div className="overflow-hidden rounded bg-white shadow">
                  <div className="flex pb-8 px-4 pt-4">
                    <div className="rounded h-12 w-12 bg-gray-400" />
                    <dd className="ml-4 flex-1 flex items-center">
                      <Shimmers.DoubleShimmer />
                    </dd>
                  </div>
                  <div className=" bg-gray-50 px-3 py-1.5 flex justify-between sm:px-4 space-x-10">
                    <Shimmers.SingleShimmer />
                    <Shimmers.ActionsShimmer actionsCount={4} />
                  </div>
                </div>
                <div className="overflow-hidden rounded bg-white shadow">
                  <div className="flex pb-8 px-4 pt-4">
                    <div className="rounded h-12 w-12 bg-gray-400" />
                    <dd className="ml-4 flex-1 flex items-center">
                      <Shimmers.DoubleShimmer />
                    </dd>
                  </div>
                  <div className=" bg-gray-50 px-3 py-1.5 flex justify-between sm:px-4 space-x-10">
                    <Shimmers.SingleShimmer />
                    <Shimmers.ActionsShimmer actionsCount={4} />
                  </div>
                </div>
                <div className="overflow-hidden rounded bg-white shadow">
                  <div className="flex pb-8 px-4 pt-4">
                    <div className="rounded h-12 w-12 bg-gray-400" />
                    <dd className="ml-4 flex-1 flex items-center">
                      <Shimmers.DoubleShimmer />
                    </dd>
                  </div>
                  <div className=" bg-gray-50 px-3 py-1.5 flex justify-between sm:px-4 space-x-10">
                    <Shimmers.SingleShimmer />
                    <Shimmers.ActionsShimmer actionsCount={4} />
                  </div>
                </div>
              </>
            )}
            renderGridItem={(item) => {
              const isPast = new Date(item?.closingDate) < new Date()
              const isOngoing = new Date(item?.openingDate) < new Date() && new Date(item?.closingDate) > new Date()
              const status = isOngoing ? "ON_GOING" : isPast ? "CLOSED" : "PENDING"
              const progressFrom = calculateDateProgress(new Date(item?.openingDate), new Date(item?.closingDate))

              return (
                <div
                  key={item?._id}
                  className="relative overflow-hidden rounded bg-white shadow"
                >
                  <div className="flex flex-col p-3 sm:p-4">
                    <p className="font-semibold text-md text-gray-900 line-clamp-2 mb-2">{item?.name}</p>
                    <div className='flex bg-gray-50 h-1 rounded mb-10'>
                      <div className='h-1 bg-tertiary-500 rounded' style={{ width: `${progressFrom(new Date())}%` }} />
                    </div>
                    <div className='text-xs text-gray-600 flex items-center space-x-3'>
                      <div className='flex flex-row space-x-1 items-center'>
                        <CalendarDaysIcon className='h-5 w-5' />
                        <span>Open: {dayjs(item?.openingDate).format("MMM DD, YYYY")}</span>
                      </div>
                      <div className='h-1.5 w-1.5 bg-tertiary-500 rounded-full mx-4' />
                      <div className='flex flex-row space-x-1 items-center justify-end'>
                        <CalendarDaysIcon className='h-5 w-5' />
                        <span>Close: {dayjs(item?.closingDate).format("MMM DD, YYYY")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-3 py-1.5 flex justify-between sm:px-4">
                    <span
                      className={classNames(
                        status === "PENDING" ? `bg-cyan-100 text-cyan-800 dark:bg-cyan-600 dark:text-gray-50` : "",
                        status === "ON_GOING" ? `bg-primary-100 text-primary-800 dark:bg-primary-600 dark:text-gray-50` : "",
                        status === "CLOSED" ? `bg-red-100 text-red-800 dark:bg-red-600 dark:text-gray-50` : "",
                        status ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                        "inline-flex rounded-full  px-3 py-0 text-xs items-center space-x-1"
                      )}
                    >
                      <span>
                        {_.startCase(isOngoing ? "Ongoing" : isPast ? "Closed" : "Pending")}
                      </span>
                    </span>

                    <div className='space-x-1'>
                      <ActionButton
                        action='view'
                        onClick={dispatchAction(item?._id, "view")}
                      />
                      {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:update"])(
                        <ActionButton
                          action='update'
                          onClick={dispatchAction(item?._id, "update")}
                        />
                      )}
                      {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:manage"])(
                        <ActionButton
                          action='expand'
                          onClick={navigateItem(item?._id)}
                        />
                      )}
                      {isAdmissionCloneAvailable() && withPermissions(["*:*", "admission-cycles:*", "admission-cycles:clone"])(
                        <ActionButton
                          action='clone'
                          onClick={dispatchAction(item?._id, "clone")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </div>

      </div>

      {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:create"])(
        <CreateAdmissionCycleContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:update"])(
            <UpdateAdmissionCycleContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:clone"])(
            <CloneAdmissionCycleContainer
              open={modal === "clone"}
              setOpen={(val: boolean) => setModal(val ? "clone" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewAdmissionCycleContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  )
}

export default AdmissionCyclesPage;
