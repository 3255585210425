import { AvatarUpload, RichTextArea, SelectInput, TextArea, TextInput } from "components/core"
import { FormikProps } from "formik"
import lodash from "lodash";
import dayjs from "dayjs";
import { FC } from "react"

interface AdmissionCycleFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    openingDate: string;
    description: string;
    closingDate: string
    academicCalendar: {
      matriculationDate: string;
      openingDate: string;
      orientationDate: string;
      year: string;
    },
    schoolFeesDates: {
      commitmentFeeDate: string,
      outstandingFeesDate: string,
      provisionalRegistrationDate: string
    },
  }>;
}

const CloneAdmissionCycleForm: FC<AdmissionCycleFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Admission Cycle Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <TextInput
              id="code"
              label="Code"
              type="text"
              placeholder="eg. 09"
              disabled={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="openingDate"
              label="Opening Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="closingDate"
              label="Closing Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Residential"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <TextArea
              id="description"
              label="Description"
              placeholder="eg. Special tariff for the needy"
              {...form}
            />
          </div>

        </div>
      </div>
      <div className="pt-6">
        <span className='text-xs font-light'>Academic Calendar Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <SelectInput
              id="academicCalendar.year"
              label="Academic Year"
              placeholder="eg. 09"
              options={[
                { label: "- select -", value: "" },
                ...lodash.times(3, (idx) => `${dayjs().add(idx, "years").year()}/${dayjs().add(idx + 1, "years").year()}`)
              ]}
              required={true}
              {...form} />
          </div>

          <div>
            <TextInput
              id="academicCalendar.openingDate"
              label="Opening Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="academicCalendar.orientationDate"
              label="Orientation Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="academicCalendar.matriculationDate"
              label="Maticulation  Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>

      <div className="pt-6">
        <span className='text-xs font-light'>School Fees Dates Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <TextInput
              id="schoolFeesDates.commitmentFeeDate"
              label="Commitment Fee Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="schoolFeesDates.provisionalRegistrationDate"
              label="Provisional Registration Fee Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="schoolFeesDates.outstandingFeesDate"
              label="Outstanding Fees Date"
              type="date"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default CloneAdmissionCycleForm