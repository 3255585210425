import { withPermissions, wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { AdmissionCycleView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import lodash from 'lodash';
import CloneAdmissionCycleForm from 'components/forms/clone-admission-cycle';
import { CloneAdmissionCycleFormSchema } from 'components/forms/clone-admission-cycle/schema';

const GET_ADMISSION_CYCLE = gql`
query GetAdmissionCycle($filter: AdmissionCycleFilter!, $populate: [String]) {
  admissionCycle: getAdmissionCycle(filter: $filter, populate: $populate) {
    _id
    code
    name
    description
    noticeToApplicants
    generalEntryRequirements
    openingDate
    closingDate
    academicCalendar {
      year
      openingDate
      orientationDate
      matriculationDate
    }
    createdBy {
      _id
      code
      name
      phone
      email
      profileImageUrl
      role
      meta {
        isSuspended
      }
    }
    lastUpdatedBy {
      _id
      code
      name
      phone
      email
      profileImageUrl
      role
      meta {
        isSuspended
      }
    }
    createdAt
    updatedAt
    schoolFeesDates {
      commitmentFeeDate
      outstandingFeesDate
      provisionalRegistrationDate
    }
  }
}
`

const CLONE_ADMISSION_CYCLE = gql`
mutation CloneAdmissionCycle($input: CloneAdmissionCycleInput!) {
  cloneAdmissionCycle(input: $input) {
    _id
  }
}
`

export default function CloneAdmissionCycleContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_ADMISSION_CYCLE, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      }
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false
  });

  const [cloneAdmissionCycle, { loading: cloning }] = useMutation<any>(CLONE_ADMISSION_CYCLE)

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      openingDate: "",
      description: "",
      closingDate: "",
      academicCalendar: {
        matriculationDate: "",
        openingDate: "",
        orientationDate: "",
        year: "",
      },
      schoolFeesDates: {
        commitmentFeeDate: "",
        outstandingFeesDate: "",
        provisionalRegistrationDate: ""
      },
    },
    validationSchema: CloneAdmissionCycleFormSchema,
    onSubmit: async (values) => {
      await cloneAdmissionCycle({
        variables: {
          input: {
            ...lodash.omit(values, ["code"]),
            cycleId: searchParams.id
          }
        }
      }).then(({ data }) => {
        if (data.cloneAdmissionCycle._id) {
          toast(JSON.stringify({ type: "success", title: "Admission Cycle Cloned Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not clone Admission Cycle" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size='4xl'
      loading={loading}
      title="Clone Admission Cycle Information"
      description="Details of admission cycle are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:clone"])(
            <button
              type="button"
              disabled={!form.isValid}
              className="w-full inline-flex justify-center rounded border disabled:bg-gray-300 border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(form.handleSubmit)}
            >
              {cloning ? "Cloning..." : "Clone"}
            </button>
          )}
        </>
      )}
    >
      {data?.admissionCycle?._id && (
        <CloneAdmissionCycleForm
          form={form}
        />
      )}
    </Modal>
  )
}