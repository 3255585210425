import * as Yup from 'yup';
import { phoneNumberValidation } from '../admin/schema';

export const UserSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required('Email Address is a required field'),
  phone: phoneNumberValidation(),
  staffId: Yup.string().required('Staff ID is a required field'),
  role: Yup.string().required('Role is a required field'),
  name: Yup.string().required('Name is a required field'),
  gender: Yup.string().required('Gender/sex is a required field'),
  school: Yup.string(),
  department: Yup.string(),
  campus: Yup.string(),
})

export const UserInitialValues = {
  email: "",
  phone: "",
  staffId: "",
  role: "",
  name: "",
  gender: "",
  school: "",
  department: "",
  campus: "",
}