import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { FC, useEffect } from 'react';
import { SearchSelectInput } from 'components/core';
import { currentUserVar } from 'apollo/cache/auth';

interface ProgrammePickerContainerProps {
  filter?: {
    school?: string;
    _id?:{
      notIn: string[]
    }
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any
  rawId?: boolean;
}

const GET_PROGRAMMES = gql`
  query GetProgrammesSelect($filter: ProgrammeFilter) {
    programmes: getProgrammes(filter: $filter, pagination: { skip: 0 limit: 0}) {
      _id
      code
      name
    }
  }
`;

const ProgrammePickerContainer: FC<ProgrammePickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const currentUser = useReactiveVar(currentUserVar);
  const { loading, data } = useQuery(GET_PROGRAMMES, {
    variables: {
      filter: {
      ...(filter ? filter : {}),
      ...(currentUser?.department?._id ? {department: {eq: currentUser.department._id}} : {})
      }
    },
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "programme"}
      label={label ?? "Programme"}
      placeholder="Select Programme"
      optionsLoading={loading}
      options={(data?.programmes ?? [])?.map((programme: any) => ({
        label: {
          title: programme.name as string
        },
        value: rawId ? programme._id : programme
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default ProgrammePickerContainer