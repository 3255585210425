import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Header, Shimmers, TableComponent } from 'components';
import numeral from 'numeral';
import { FC, useEffect, useMemo } from 'react'
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, withPermissions } from 'utils';
import config from 'config';
import { Avatar, SelectInput } from 'components/core';
import { PlusIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import CreateSchoolContainer from './create';
import UpdateSchoolContainer from './update';
import ViewSchoolContainer from './view';
import { currentConfigVar } from 'apollo/cache/config';
import { ExportDataContainer, ImportDataContainer } from 'containers';
import ActionButton, { Action } from 'components/buttons/action-button';
import lodash from 'lodash';
import DeleteSchoolContainer from './delete';

const GET_SCHOOLS = gql`
  query GetSchools($filter: SchoolFilter, $pagination: Pagination, $search: SearchOperator, $sort: SchoolSort, $populate: [String]) {
    items: getSchools(filter: $filter, pagination: $pagination, search: $search, sort: $sort, populate: $populate) {
      _id
      code
      dean {
        _id
        name
        profileImageUrl
        staffId
      }
      digitalAddress
      email
      name
      officeAddress
      phone
      meta {
        departmentsCount
        programmesCount
      }
      deleted
      createdAt
      updatedAt
    }
    itemsCount: getSchoolsCount(filter: $filter, search: $search)
  }
`

const SchoolsPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar)
  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [status, setStatus] = useUrlState('status');

  


  const variables = useMemo(() => ({
    pagination: {
      limit: searchParams.pageSize || 12,
      skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
    },
    ...((searchParams?.search || "")?.length > 0 ? {
      search: {
        query: searchParams?.search,
        fields: ["email", "name", "phone"]
      }
    } : {}),
    filter: {
      ...(status
        ? status === "ALL"
          ? null
          : { deleted: { eq: status === "ACTIVE" ? false : true } }
        : { deleted: { eq: false } }
      ),
    },
    sort: {

    },
    populate: ['dean']
  }), [searchParams]);

  const { data, loading, networkStatus, refetch } = useQuery(GET_SCHOOLS, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      })
    })
  }, [navigate])

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "schools:*", "schools:create"])(
              <button
                type="button"
                onClick={wrapClick(() => setModal("create"))}
                className="ml-3 inline-flex items-center rounded border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add New School
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'schools'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="School"
                variables={variables}
              />
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  School
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Department | Programmes
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Dean
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">{item?.name}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.code}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.officeAddress || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.digitalAddress || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.email || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.phone || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{numeral(item?.meta?.departmentsCount || 0).format("0,0")} departments</div>
                  <div className="text-gray-500 dark:text-gray-400">{numeral(item?.meta?.programmesCount || 0).format("0,0")} programmes</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="flex items-center">
                    {
                      item?.dean ?
                      <>
                        <div className="h-10 w-10 flex-shrink-0">
                          <Avatar
                            disabled={true}
                            src={item?.dean?.profileImageUrl}
                            alt={item?.dean?.name || "N A"}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-gray-900 dark:text-gray-100">{item?.dean?.name || "-"}</div>
                          <div className="text-gray-500 dark:text-gray-400">{item?.dean?.staffId || "-"}</div>
                        </div>
                      </>
                      : 
                      <div className="text-gray-500 dark:text-gray-100">Unassigned</div>
                    }
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["*:*", "schools:*", "schools:update"])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                    
                    {!item?.deleted && withPermissions(["*:*", "schools:*", "schools:delete"])(
                      <ActionButton
                        action='delete'
                        onClick={dispatchAction(item?._id, "delete")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <SelectInput
                  handleBlur={() => null}
                  handleChange={(e: any) => setStatus(e.target.value)}
                  id="status"
                  placeholder='Filter By Deleted'
                  labelHidden
                  options={[
                    { label: "All", value: "ALL" },
                    { label: "Active", value: "ACTIVE" },
                    { label: "Deleted", value: "DELETED" },
                  ]}
                  values={{ status }}
                />
              </div>
            )}
          />
        </div>

      </div>

      {withPermissions(["*:*", "schools:*", "schools:create"])(
        <CreateSchoolContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {withPermissions(["*:*", "schools:*", "schools:import"])(
        <ImportDataContainer
          open={modal === "import"}
          setOpen={(val: boolean) => setModal(val ? "import" : undefined)}
          entityType={"School"}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "schools:*", "schools:update"])(
            <UpdateSchoolContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "schools:*", "schools:delete"])(
            <DeleteSchoolContainer
              open={modal === "delete"}
              setOpen={(val: boolean) => setModal(val ? "delete" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewSchoolContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  )
}

export default SchoolsPage;
