import { classNames, wrapImage } from "utils";

import { Avatar } from "components/core";
import { FC } from "react";
import _ from "lodash";
import { currentConfigVar } from "apollo/cache/config";
import dayjs from "dayjs";
import { useReactiveVar } from "@apollo/client";

interface ApplicationViewProps {
  application: {
    _id: any;
    applicant: {
      code: any;
      email: any;
    }
    code: any;
    educationalHistoryInformation: {
      history: {
        programme: any;
        institution: any;
        cgpa: any;
        class: any;
        gpaScale: any;
        graduatingYear: any;
        graduatingMonth: any;
        idNumber: any;
      }[]
      type: any;
    }
    level: any;
    personalInformation :{
      firstName: any;
      email: any;
      dateOfBirth: any;
      gender: any;
      lastName: any;
      maritalStatus: any;
      middleName: any;
      nationality: any;
      phone: any;
      secondaryPhone: any;
      profileImageUrl: any;
    }
    programmeInformation: {
      preferredCampus: {
        _id: any;
        name: any;
      }
      preferredOfferingType: any
      programmeSelections: {
        programme: {
          department: {
            name: any;
          }
          school: {
            name: any;
          }
          name: any;
          requiresInterview: any;
          examinationScoreWeight: any;
          interviewScoreWeight: any;
          level: any;
        }
      }
    }
    createdAt: any;
    status: any;
    referencesInformation: {
      academic: {
        email: any;
        name: any;
        organization: any;
        phone: any;
        title: any;
      }
      nonAcademic: {
        email: any;
        name: any;
        organization: any;
        phone: any;
        title: any;
      }
    }
  },
  invoice: any;
}

const ApplicationView: FC<ApplicationViewProps> = ({ application, invoice }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  console.log({ invoice })

  const invoiceAmount = (invoice?.totalAmount / 100) || 0
  return (
    <>
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="py-4">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-6 font-normal text-primary-900 dark:text-gray-100">
                Application Information
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about application
              </p>
            </div>
            <div />
          </div>
          <div className="mt-6">
            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded p-3 space-7-6 divide-y divide-gray-200">
              <div className="">
                <span className="text-xs font-light text-primary-600">
                  Application Information
                </span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Code
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.code || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Application Date
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {dayjs(application?.createdAt).format(dateFormat)}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Application Status
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {_.startCase(application?.status) || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Qualification
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {_.startCase(application?.educationalHistoryInformation?.type) || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Institution
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {_.startCase(application?.educationalHistoryInformation?.history?.[0]?.institution) || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Index Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {_.startCase(application?.educationalHistoryInformation?.history?.[0]?.idNumber) || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            invoice &&
            <div className="mt-6">
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded p-3 space-7-6 divide-y divide-gray-200">
                <div className="">
                  <span className="text-xs font-light text-primary-600">
                    Invoice Information
                  </span>
                  <div className="grid grid-cols-3 gap-6 mt-2">
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Code
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {invoice?.code || "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Status
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {_.startCase(invoice?.status) || "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Paid Date
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {invoice?.paidAt ? dayjs(invoice?.paidAt).format(dateFormat) : "Not Paid"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Total Amount
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {invoiceAmount ? `GHC ${invoiceAmount}` : "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Mode
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {_.startCase(invoice?.mode) || "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Vendor
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {invoice?.mode === "PAYSTACK" ? "PAYSTACK" : _.startCase(invoice?.vendor?.name) || "Not Paid"}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          }

        </div>
        <div className="py-4">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
                Personal Information
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about applicant
              </p>
            </div>
            <div />
          </div>
          <div className="mt-6">
            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded p-3 space-7-6 divide-y divide-gray-200">
              <div className="pt-6">
                <span className="text-xs font-light text-primary-600">
                  Personal Information
                </span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Photo
                    </span>
                    <Avatar
                      alt={
                        `${application?.personalInformation?.firstName} ${application?.personalInformation?.lastName}` ||
                        "N A"
                      }
                      src={
                        application?.personalInformation?.profileImageUrl || ""
                      }
                      size="lg"
                    />
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Applicant Email
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.applicant?.email || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      First Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm uppercase">
                      {application?.personalInformation?.firstName || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Last Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm uppercase">
                      {application?.personalInformation?.lastName || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Other Name(s)
                    </span>
                    <div className="mt-1 block w-full sm:text-sm uppercase">
                      {application?.personalInformation?.middleName || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Email
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.personalInformation?.email || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Phone number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.personalInformation?.phone || "N/A"}
                    </div>
                  </div>
                  {application?.personalInformation?.secondaryPhone && (
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Secondary phone number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {application?.personalInformation?.phone || "N/A"}
                      </div>
                    </div>
                  )}
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Gender
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.personalInformation?.gender || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Date of birth
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.personalInformation?.dateOfBirth || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Nationality
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.personalInformation?.nationality || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Marital status
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.personalInformation?.maritalStatus || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded p-3 space-7-6 divide-y divide-gray-200">
              <div className="pt-6">
                <span className="text-xs font-light  text-primary-600">
                  Programme Information
                </span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Preferred Campus
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.programmeInformation?.preferredCampus
                        ?.name || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Preferred Offering Type
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {application?.programmeInformation
                        ?.preferredOfferingType || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationView;
