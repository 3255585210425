import * as Yup from 'yup';

export const CloneAdmissionCycleFormSchema = Yup.object().shape({
  code: Yup.string().notRequired(),
  name: Yup.string().required(),
  openingDate: Yup.date().required(),
  description: Yup.string().required(),
  closingDate: Yup.date().required(),
  academicCalendar: Yup.object().shape({
    matriculationDate: Yup.date().required(),
    openingDate: Yup.date().required(),
    orientationDate: Yup.date().required(),
    year: Yup.string().required(),
  }),
  schoolFeesDates: Yup.object().shape({
    commitmentFeeDate: Yup.date().required(),
    outstandingFeesDate: Yup.date().required(),
    provisionalRegistrationDate: Yup.date().required()
  }),
})