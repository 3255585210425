import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC } from "react";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
import { MoonLoader } from "react-spinners";
import { currentConfigVar } from "apollo/cache/config";
import BarChart from "components/charts/bar-chart";
import { getRandomHexColor } from "utils/get-random-color";
dayjs.extend(weekOfYear);

interface ApplicantsCampusChartContainerProps {
  cycle?: string;
}

const GET_APPLICATIONS_STATS = gql`
  query Query($fields: [String], $filter: CountByCampusFilter) {
    countApplicationsByCampus(fields: $fields, filter: $filter)
  }
`;

const ApplicationCampusChartContainer: FC<
  ApplicantsCampusChartContainerProps
> = ({ cycle }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = cycle && { cycle: { eq: cycle } };

  const { loading, data } = useQuery(GET_APPLICATIONS_STATS, {
    variables: {
      filter: {
        ...cycleFilters,
      },
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
    pollInterval,
  });

  const colors = [
    "#5E95FC",
    "#27d163",
    "#FFD36C",
    "#F8649F",
    "#72DBDB",
    "#A955A7",
  ];

  const barData = data?.countApplicationsByCampus
    ?.sort((a: any, b: any) => b?.count - a?.count)
    ?.map((school: any, index: number) => ({
      school: school?.name,
      [school?.name]: school?.count,
      [`${school?.name}Color`]: colors?.[index] || getRandomHexColor(),
    }));

  const keys = data?.countApplicationsByCampus?.map(
    (school: any) => school?.name
  );

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Campuses
          </h3>
          <p className="text-xs text-gray-400">
            Displays campus choices within applications
          </p>
        </div>
        {loading && <MoonLoader size={20} />}
      </div>

      <BarChart
        keys={keys}
        indexBy="school"
        xLabel="Campuses"
        yLabel="No. of Applicants"
        layout="vertical"
        data={barData || []}
        valueScale="symlog"
      />
    </>
  );
};

export default ApplicationCampusChartContainer;
