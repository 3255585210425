import { wrapClick } from 'utils';
import { gql, useMutation, } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, AdmissionTransferForm } from 'components';
import { AdmissionTransferInitialValues, AdmissionTransferSchema } from 'components/forms/admission-transfer/schema';
import { useMatches } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useEffect } from 'react';
import _ from 'lodash';

const CREATE_TRANSFER = gql`
mutation TransferAdmissionsToDifferentCycle($input: TransferAdmissionsInput!) {
  transferAdmissionsToDifferentCycle(input: $input) {
    transferCount
  }
}
`;

export default function CreateAdmissionTransferContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; }) {

  const [createAdmissionTransfer, { loading }] = useMutation(CREATE_TRANSFER);

  const matches = useMatches<LocationGenerics>();
  const cycle = matches?.[0]?.params?.admissionCycle;

  const form = useFormik({
    initialValues: AdmissionTransferInitialValues,
    validationSchema: AdmissionTransferSchema,
    onSubmit: async (values) => {
      await createAdmissionTransfer({
        variables: {
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data.transferAdmissionsToDifferentCycle.transferCount) {
          toast(JSON.stringify({ type: "success", title: "Transfer initialized successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Transfer" }));
        }
      });
    },
    onReset: () => {
      setOpen(false);
    }
  });

  useEffect(() => {
    form.setValues({
      ...form.values,
      sourceCycleId: cycle || ""
    });
  }, [cycle, open]);
  
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Transfer Admissions"
      description="Provide the details to transfer applicants"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || form.isSubmitting || !_.isEmpty(form.errors)}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 disabled:opacity-75 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm disabled:cursor-not-allowed"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Starting Transfer..." : "Start Transfer"}
          </button>
        </>
      )}>
      <AdmissionTransferForm
        form={form}
      />
    </Modal>
  );
}