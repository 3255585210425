import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, InvoiceForm } from 'components';
import { InvoiceSchema } from 'components/forms/invoice/schema';

const CREATE_INVOICE = gql`
mutation CreateInvoice($input: CreateInvoiceInput!) {
  createInvoice(input: $input) {
    _id
  }
}
`;

export default function CreateInvoiceContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const [createInvoice, { loading }] = useMutation(CREATE_INVOICE)

  const form = useFormik({
    initialValues: {
      name: "",
      applicationCode: "",
      currency: "",
      application: null as any,
      type: "",
      items: [],
    },
    // validationSchema: InvoiceSchema,
    onSubmit: async ({ applicationCode, application, ...values }) => {
      await createInvoice({
        variables: {
          input: {
            ...values,
            application: application?._id,
            cycle: application?.cycle?._id,
            items: values?.items?.map((item: any) => ({ ...item, unitPrice: parseInt(`${item?.unitPrice * 100}`), quantity: 1 }))
          }
        }
      }).then(({ data }) => {
        if (data.createInvoice._id) {
          toast(JSON.stringify({ type: "success", title: "Invoice created successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Invoice" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    },
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Invoice"
      size='4xl'
      description="Provide the details to add a new invoice"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || !!!form.values?.application || form.values?.application?.status !== "SUBMITTED" || form.values?.items.length === 0 || !form.values.currency || !form.values.type}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 disabled:bg-gray-300 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Invoice..." : "Add Invoice"}
          </button>
        </>
      )}>
      <InvoiceForm
        form={form}
      />
    </Modal>
  )
}