import ApolloProvider from 'apollo';
import LayoutProvider from 'layouts';
import NotificationProvider from 'notifications';
import { Tooltip } from 'react-tooltip';
import RoutesProvider from 'router';
import TranslationProvider from 'translation';

function App() {
  return (
    <TranslationProvider>
      <ApolloProvider>
        <NotificationProvider>
          <RoutesProvider>
            <LayoutProvider />
            <Tooltip />
          </RoutesProvider>
        </NotificationProvider>
      </ApolloProvider>
    </TranslationProvider>
  );
}

export default App;
