import { useUrlState, wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { ProgrammeForm, Modal } from 'components';
import { ProgrammeFormProps } from 'components/forms/programme';
import { useEffect } from 'react';
import { ProgrammeSchema } from 'components/forms/programme/schema';

const CREATE_PROGRAMME = gql`
mutation CreateProgramme($input: CreateProgrammeInput!) {
  createProgramme(input: $input) {
    _id
    code
  }
}
`

export default function CreateProgrammeContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const [createProgramme, { loading }] = useMutation(CREATE_PROGRAMME)
  const [school] = useUrlState("school");
  const [department] = useUrlState("department");

  const form = useFormik<ProgrammeFormProps["form"]["values"]>({
    initialValues: {
      code: "",
      name: "",
      description: "",
      level: "",
      school: "",
      department: "",
    },
    validationSchema: ProgrammeSchema,
    onSubmit: async ({ code, ...values }) => {
      await createProgramme({
        variables: {
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data.createProgramme._id) {
          toast(JSON.stringify({ type: "success", title: "Programme Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Programme" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({ ...form.values, department, school })
  }, [school,department]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Programme"
      description="Provide the details to add a new programme"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Programme..." : "Add Programme"}
          </button>
        </>
      )}>
      <ProgrammeForm
        form={form}
      />
    </Modal>
  )
}