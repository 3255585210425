import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { Header, SelectInput, Shimmers, TableComponent } from "components";
import _ from "lodash";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useState } from "react"
import { useMatches, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, wrapClick, useUrlState, useDownloadFile, withPermissions } from "utils";
import config from "config";
import ViewBlockContainer from "./view";
import { ExportDataContainer, ImportDataContainer } from "containers";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from 'components/buttons/action-button';
import lodash from "lodash";
import toast from "react-hot-toast";
import StartShortlistContainer from "./start-shortlisting";
import axios from "axios";

const GET_APPLICATIONS = gql`
query GetApplications($filter: ApplicationFilter, $pagination: Pagination, $search: SearchOperator, $sort: ApplicationSort, $populate: [String]) {
  items: getApplications(filter: $filter, pagination: $pagination, search: $search, sort: $sort, populate: $populate) {
    _id
    code
    level
    status
    createdAt
    updatedAt
    educationalHistoryInformation {
      type
    }
  }
  itemsCount: getApplicationsCount(filter: $filter, search: $search)
}
`

const ApplicationsPage: FC = () => {
  const { pollInterval, dateFormat } = useReactiveVar(currentConfigVar)

  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }
  const navigateItem = (id: string) => () => {
    navigate({
      to: `./${id}`
    })
  }

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [level, setLevel] = useUrlState("level");
  const [status, setStatus] = useUrlState("status");
  const [type, setType] = useUrlState("type");
  const matches = useMatches<LocationGenerics>()
  const cycle = matches?.[0]?.params?.admissionCycle

  const variables = useMemo(
    () => ({
      pagination: {
        limit: searchParams.pageSize || 12,
        skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
      },
      ...((searchParams?.search || "")?.length > 0
        ? {
          search: {
            query: searchParams?.search,
            fields: ["code", "level"],
            options: ["CaseInsensitive"]
          },
        }
        : {}),
      filter: {
        ...(cycle && { cycle: { eq: cycle } }),
        ...(level && { level: { eq: level } }),
        ...(status && { status: { eq: status } }),
        ...(type && { type: { eq: type } }),
      },
      sort: {},
      populate: [],
    }),
    [searchParams, cycle, status, level]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_APPLICATIONS, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      })
    })
  }, [navigate])


  const CREATE_EXPORT = gql`
  query GetExportFileUrl($filter: JSON){
    url: getWAECFileUrl(filter: $filter)
  }
`

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(JSON.stringify({ type: "error", title: "An error occurred downloading file" }))
    },
    getFileName: () => new Date().toISOString() + "ADMISSION_WAEC" + "_.csv",
  });


  const [createDataExport, { loading: loadingExportWaec }] = useLazyQuery(CREATE_EXPORT, {
    fetchPolicy: "no-cache"
  })

  const handleSubmit = (e: any) => {
    e.preventDefault()
    createDataExport({
      variables: {
        filter: variables?.filter,
      },
      fetchPolicy: "no-cache"
    }).then(({ data }) => {
      console.log("datum", data)
      if (data.url) {
        window.open(data.url, "_blank");
        // downloadAction(`/public/${data.url}`)
      }
      else {
        toast(JSON.stringify({ type: "error", title: "Could not export file" }))
      }
    })
  }

  const [loadingWAEC, setLoadingWAEC] = useState(false);

  const handleWAECGrades = (formData: FormData) => {

    formData.set("cycle", cycle || "")
    setLoadingWAEC(true)

    axios
      .post("/waec/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data) {
          console.log("handleWAECGrades", { data })
          toast(JSON.stringify({ type: "success", title: `Updated ${data?.updatedCount} results` }))
          setModal('')
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoadingWAEC(false)
      });
  }

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-full bg-gray-50">
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"applications"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="Application"
                variables={variables}
              />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
                <SelectInput
                  id="type"
                  label=""
                  placeholder="Filter Type"
                  options={["INTERNATIONAL", "LOCAL"]}
                  handleChange={(event: any) => {
                    setType(event.target.value)
                  }}
                  handleBlur={() => null}
                  values={{ type }}
                />
                <SelectInput
                  id="level"
                  label=""
                  placeholder="Filter Level"
                  options={["UNDERGRADUATE", "GRADUATE"]}
                  handleChange={(event: any) => {
                    setLevel(event.target.value)
                  }}
                  handleBlur={() => null}
                  values={{ level }}
                />
                <SelectInput
                  id="status"
                  label=""
                  placeholder="Filter Status"
                  options={["PENDING", "SUBMITTED", "SHORTLISTED", "SHORTLISTED_FAILED"]}
                  handleChange={(event: any) => {
                    setStatus(event.target.value)
                  }}
                  handleBlur={() => null}
                  values={{ status }}
                />
                {
                  withPermissions(["*:*", "application:*", "application:import-waec"])(
                    <button
                      type="button"
                      disabled={loading}
                      className="w-full mt-1 justify-center h-[38px] rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleSubmit}
                    >
                      {loadingExportWaec ? "Exporting..." : "WAEC Sheet"}
                    </button>
                  )
                }
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Start Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Level
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Qualification
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">{item?.code || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="text-gray-500 dark:text-gray-400">{dayjs(item?.createdAt).format(`${dateFormat}, HH:mm a`)}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      item?.level === "GRADUATE" ? `bg-orange-100 text-orange-800 dark:bg-[#F87A17] dark:text-gray-50` : "",
                      item?.level === "UNDERGRADUATE" ? `bg-primary-100 text-primary-800 dark:bg-[#657383] dark:text-gray-50` : "",
                      !item?.level ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                      <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                    </svg>
                    <span>
                      {item?.level || "Unknown"}
                    </span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-400">{item?.educationalHistoryInformation?.type || "NONE"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      item?.status === "SuperAdmin" ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50` : "",
                      item?.status === "Admin" ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50` : "",
                      item?.status === "SHORTLISTED_FAILED" ? `bg-red-100 text-red-700 dark:bg-[#FF4040] dark:text-gray-50` : "",
                      item?.status === "Reader" ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50` : "",
                      item?.status === "Queued" ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50` : "",
                      item?.status === "SHORTLISTED" ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50` : "",
                      item?.status === "SUBMITTED" ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50` : "",
                      !item?.status ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                      <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                    </svg>
                    <span>
                      {item?.status?.replaceAll("_", " ") || "Unknown"}
                    </span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["*:*", "application:*", "application:manage"])(
                      <ActionButton
                        action='expand'
                        onClick={navigateItem(item?._id)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>

      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewBlockContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
      {withPermissions(["*:*", "shortlist:*", "application:shortlist"])(
        <StartShortlistContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      <ImportDataContainer
        entityType="Education Grades"
        setOpen={(val: boolean) => setModal(val ? "upload" : undefined)}
        open={modal === "upload"}
        onSubmit={handleWAECGrades}
        loading={loadingWAEC}
      />
    </main>
  )
}

export default ApplicationsPage;
