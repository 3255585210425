import { AdmissionFormInputs } from "apollo/graphql/queries";
import dayjs from "dayjs";
import { convertMonthsToYearsAndMonths, numberToWords } from "utils/general";
import _ from "lodash"

export const UndergraduateStudent = ({ admissionData, paymentDates, applicationData, commitmentFee, credentials, startingYear }: AdmissionFormInputs) => {
  const isMoreThanAYear = (admissionData?.programme?.duration || 0) >= 12;

  return (
    <ol className="list-decimal mt-2 text-xs space-y-3 text-justify">
      <li className=" text-justify">
        I have the pleasure to inform you that the Ghana Institute of Management and Public Administration (GIMPA) has offered you
        admission to the <b>{admissionData?.programme?.school?.name}</b> to pursue a <b>{convertMonthsToYearsAndMonths(admissionData?.programme?.duration || 0)}</b> programme of study <b>{isMoreThanAYear && ` (starting from year ${numberToWords(startingYear || 1)})`}</b>{", "}<span className="capitalize font-bold"> In-person {`${admissionData?.offeringType}`.toLocaleLowerCase()} Session</span> {'['}{admissionData?.application?.programmeInformation?.preferredCampus?.name}{']'}, leading to the award of a  <b>{admissionData?.programme?.name}</b>.
      </li>
      <li className=" text-justify">
        <b>Despite the fact that this offer is for {_.capitalize(admissionData?.application?.programmeInformation?.preferredOfferingType)} Session at the {'['}{admissionData?.application?.programmeInformation?.preferredCampus?.name}{']'} based on the exigencies of the time, the school, may, with adequate notice to you, change the session and/or location or the mode of offering the programme.</b>
      </li>
      <li>
        Your Student Identification (ID) Number is <b>{credentials?.studentId}</b>. This is the number that you will use throughout your studies in the
        Institute. Your default PIN is <b>{credentials?.pin}</b>. You must change it immediately after the first use. Visit the Institutes website (www.gimpa.edu.gh) and click on &apos;<b>MIS Web</b>&apos; to access the student portal login page. You will be provided with a GIMPA e-mail account after your first registration
      </li>
      <li className="text-justify">
        The first semester will commence on <b>{dayjs(admissionData?.cycle?.academicCalendar?.openingDate).format("dddd, MMMM Do, YYYY")}</b>. An orientation will be held for all fresh students on <b>{dayjs(admissionData?.cycle?.academicCalendar?.orientationDate).format("dddd, MMMM Do, YYYY")}</b>. The venue will be communicated to you. <b> Please, keep your application email address and WhatsApp number active. Please note that attendance/participation in orientation programmes is compulsory.</b>
      </li>
      <li>
        Kindly note that you  <b>cannot defer</b> your programme of study after the second week during the first year. If for some reason you should decide to defer your programme to the next academic year, your application for deferment should be sent to the Dean of the <b>{'['}{admissionData?.programme?.school?.name.trim()}{']'}</b>, through your Head of Department. <b>Your application will only be considered after you have registered</b>.
      </li>
      <li>
        Should you decide to withdraw from the programme before the third week of registration, GIMPA shall refund your fees to you less than <b>30% of the total amount payable</b>, to be retained as administrative charges. Beyond this date, <b>NO REFUND</b> shall be made.
      </li>
      <li>
        It is mandatory for all fresh students to be matriculated and to sign the Matricula. You will be expected to participate in this ceremony when you enroll. The date and form will be communicated to you.
      </li>
      <li>
        <b> Information on Tuition fee and other institutional charges as well as the breakdown of the fees can be found on the attached
          supplementary sheet. The tuition fee may be revised in subsequent years.</b>
      </li>
      <li>
        <b>
          In order that the offer of admission made to you is confirmed, you are required to pay a non-refundable commitment fee of
          &nbsp;{commitmentFee} on or before {paymentDates?.commitment}  (this forms part of your first semester fees). You are to submit proof of
          payment together with the completed acceptance letter (attached), to your School/Faculty. The outstanding portion of the semester fee should be paid on or before {paymentDates?.outstanding}
        </b>.
      </li>
      <li>
        <b>
          {"["}Please note that graduate students can only register after paying hundred percent (100%) of their semester/session/trimester fees] </b>. Your ID number should be quoted on the pay-in-slip. <b> Undergraduate students can only register after paying fifty (50%) of their register semester/session/trimester fees{"]"}. </b>
      </li>
      <li>
        <b>
          A qualified applicant who has been offered admission but fails to meet the deadline for the payment of the commitment fee
          shall be deemed not to have taken the offer. </b>
      </li>
      <li>
        <b>You will go through registration formalities each semester for the duration of your programme.</b> Registration is done online
        via the Institute’s website, www.gimpa.edu.gh. You will need your student ID number and PIN to gain access to the student’s
        registration portal. You will be able to register only if you have paid the required fee. Student Registration Guidelines are available
        on the Institute’s website.
      </li>
      <li>
        <b>
          Students who fail to register by the close of a registration period will not receive any grades even if they attend lectures and
          take examinations. Registration is on semester basis.
        </b>
      </li>
      <li>
        The Institute requires that you are declared medically fit by its Head of Medical Services. In this regard, you will be medically examined at the Institutional clinic soon after the beginning of the First Semester. Note that you will be expected to pay for the chest X-ray at the Institute’s Clinic
      </li>
      <li>
        Kindly note that GIMPA reserves the right to revoke this offer in the event that discrepancies are detected in the statements and
        documentations submitted as part of your application.
      </li>

      <li>
        You may contact the following telephone numbers for any assistance when in difficulty:
        <b>
          {` ${admissionData?.department?.phone}`}
        </b>
      </li>
      <li>
        Please indicate your acceptance of this offer by completing the electronic acceptance form from your application dashboard. Step by step guideline is provided on your dashboard
      </li>
      <li>
        Please, accept our congratulations on your admission to the Ghana Institute of Management and Public Administration.
      </li>
    </ol>
  );
};

export const GraduateStudent = ({ admissionData, paymentDates, applicationData, commitmentFee, credentials, startingYear }: AdmissionFormInputs) => {
  const isMoreThanAYear = (admissionData?.programme?.duration || 0) >= 12;
  return (
    <ol className="list-decimal mt-2 text-xs space-y-3 text-justify">
      <li className=" text-justify">
        I have the pleasure to inform you that the Ghana Institute of Management and Public Administration (GIMPA) has offered you
        admission to the <b>{admissionData?.programme?.school?.name}</b> to pursue a <b>{convertMonthsToYearsAndMonths(admissionData?.programme?.duration || 0)}</b> programme of study <b>{isMoreThanAYear && ` (starting from year ${numberToWords(startingYear || 1)})`}{", "}</b><span className="capitalize font-bold"> In-person {`${admissionData?.offeringType}`.toLocaleLowerCase()} Session</span> {'['}{admissionData?.application?.programmeInformation?.preferredCampus?.name}{']'}, leading to the award of a  <b>{admissionData?.programme?.name}</b>.
      </li>
      <li className=" text-justify ">
        <b>Despite the fact that this offer is for {_.capitalize(admissionData?.application?.programmeInformation?.preferredOfferingType)} Session at the {'['}{admissionData?.application?.programmeInformation?.preferredCampus?.name}{']'} based on the exigencies of the time, the school, may, with adequate notice to you, change the session and/or location or the mode of offering the programme.</b>
      </li>
      <li>
        Your Student Identification (ID) Number is <b>{credentials?.studentId}</b>. This is the number that you will use throughout your studies in the
        Institute. Your default PIN is <b>{credentials?.pin}</b>. You must change it immediately after the first use. Visit the Institutes website (www.gimpa.edu.gh) and click on &apos;<b>MIS Web</b>&apos; to access the student portal login page. You will be provided with a GIMPA e-mail account after your first registration
      </li>
      <li className="text-justify">
        The first semester will commence on <b>{dayjs(admissionData?.cycle?.academicCalendar?.openingDate).format("dddd, MMMM Do, YYYY")}</b>. An orientation will be held for all fresh students on <b>{dayjs(admissionData?.cycle?.academicCalendar?.orientationDate).format("dddd, MMMM Do, YYYY")}</b>. The venue will be communicated to you. <b> Please, keep your application email address and WhatsApp number active. Please note that attendance/participation in orientation programmes is compulsory.</b>
      </li>
      <li>
        Kindly note that you  <b>cannot defer</b> your programme of study after the second week during the first year. If for some reason you should decide to defer your programme to the next academic year, your application for deferment should be sent to the Dean of the <b>{'['}{admissionData?.programme?.school?.name.trim()}{']'}</b>, through your Head of Department. <b>Your application will only be considered after you have registered</b>.
      </li>
      <li>
        Should you decide to withdraw from the programme before the third week of registration, GIMPA shall refund your fees to you less than <b>30% of the total amount payable</b>, to be retained as administrative charges. Beyond this date, <b>NO REFUND</b> shall be made.
      </li>
      <li>
        It is mandatory for all fresh students to be matriculated and to sign the Matricula. You will be expected to participate in this ceremony when you enroll. The date and form will be communicated to you.
      </li>
      <li>
        <b> Information on Tuition fee and other institutional charges as well as the breakdown of the fees can be found on the attached
          supplementary sheet. The tuition fee may be revised in subsequent years.</b>
      </li>
      <li>
        <b>
          In order that the offer of admission made to you is confirmed, you are required to pay a non-refundable commitment fee of
          &nbsp;{commitmentFee} on or before {paymentDates?.commitment}  (this forms part of your first semester fees). You are to submit proof of
          payment together with the completed acceptance letter (attached), to your School/Faculty. The outstanding portion of the semester fee should be paid on or before {paymentDates?.outstanding}
        </b>.
      </li>
      <li>
        <b>
          {"["}Please note that graduate students can only register after paying hundred percent (100%) of their semester/session/trimester fees] </b>. Your ID number should be quoted on the pay-in-slip. <b> Undergraduate students can only register after paying fifty (50%) of their register semester/session/trimester fees{"]"}. </b>
      </li>
      <li>
        <b>
          A qualified applicant who has been offered admission but fails to meet the deadline for the payment of the commitment fee
          shall be deemed not to have taken the offer. </b>
      </li>
      <li>
        <b>You will go through registration formalities each semester for the duration of your programme.</b> Registration is done online
        via the Institute’s website, www.gimpa.edu.gh. You will need your student ID number and PIN to gain access to the student’s
        registration portal. You will be able to register only if you have paid the required fee. Student Registration Guidelines are available
        on the Institute’s website.
      </li>
      <li>
        <b>
          Students who fail to register by the close of a registration period will not receive any grades even if they attend lectures and
          take examinations. Registration is on semester basis.
        </b>
      </li>
      <li>
        The Institute requires that you are declared medically fit by its Head of Medical Services. In this regard, you will be medically examined at the Institutional clinic soon after the beginning of the First Semester. Note that you will be expected to pay for the chest X-ray at the Institute’s Clinic
      </li>
      <li>
        Kindly note that GIMPA reserves the right to revoke this offer in the event that discrepancies are detected in the statements and
        documentations submitted as part of your application.
      </li>

      <li>
        You may contact the following telephone numbers for any assistance when in difficulty:
        <b>
          {` ${admissionData?.department?.phone}`}
        </b>
      </li>
      <li>
        Please indicate your acceptance of this offer by completing the electronic acceptance form from your application dashboard. Step by step guideline is provided on your dashboard
      </li>
      <li>
        Please, accept our congratulations on your admission to the Ghana Institute of Management and Public Administration.
      </li>
    </ol>
  );
};
