import { FC, useMemo, useRef } from 'react'
import { useUrlState, wrapClick } from 'utils';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { EnvelopeIcon, GlobeAltIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { ApplicationDetailsView } from 'components';

interface ApplicationDetailsContainerProps {
  values: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}


const ReferenceDetailsContainer: FC<ApplicationDetailsContainerProps> = ({ values, code }) => {
  const currentConfig = useReactiveVar(currentConfigVar);
  const [page, setPage] = useUrlState("page");
  const printRef = useRef<any>();

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div ref={printRef} className="flex-1 flex flex-col overflow-hidden">
        <div className="space-y-6 divide-y divide-primary-500/50 p-6 print:p-0 overflow-y-auto flex-1 no-scrollbar">
          <ApplicationDetailsView.Reference data={values} config={{}}  />
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(() => setPage(page + 1))}
          className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          onClick={wrapClick(() => setPage(page - 1))}
          className="w-full inline-flex justify-center rounded border border-gray-200 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-600 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Previous
        </button>
      </div>
    </div>
  )
}

export default ReferenceDetailsContainer
