import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface VendorPickerContainerProps {
  filter?: {
    school?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any
  rawId?: boolean;
}

const GET_VENDORS = gql`
query GetVendors {
  vendors:getVendors {
    _id
    name
  }
}
`;

const VendorPickerContainer: FC<VendorPickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_VENDORS, {
    variables: {
      filter: {
        ...(filter ? filter : {}),
      }
    },
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "vendor"}
      label={label ?? "Vendor"}
      placeholder="Select Vendor"
      optionsLoading={loading}
      options={(data?.vendors ?? [])?.map((vendor: any) => ({
        label: {
          title: vendor.name as string
        },
        value: rawId ? vendor._id : vendor
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default VendorPickerContainer