import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, VoucherClassForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';
import ShortlistForm from 'components/forms/shortlist';

const GET_SHORTLISTED = gql`
query GetShortlisting($filter: ShortlistFilter!, $populate: [String]) {
  shortlist: getShortlisting(filter: $filter, populate: $populate) {
    _id
    code
    createdAt
    updatedAt
    applicant {
      code
      email
      _id
    }
    cycle {
      _id
      code
      name
    }
    examinationScore
    interviewScore
    programme {
      _id
      name
      requiresInterview
      requiresExamination
      level
      maximumExaminationScore
      maximumInterviewScore
    }
    lastUpdatedBy {
      _id
      name
    }
  }
}       
`;

const UPDATE_SHORTLIST = gql`
mutation UpdateShortlisting($input: ShortlistInput!) {
  updateShortlisting(input: $input) {
    _id
    code
  }
}
`

export default function UpdateShortlistingContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SHORTLISTED, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ['programme']
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only"
  });
  const [updateShortlisting, { loading: updating }] = useMutation(UPDATE_SHORTLIST)

  const form = useFormik({
    initialValues: {
      shortlistId: "",
      examinationScore: "",
      interviewScore: "",
    },
    onSubmit: async (values) => {
      await updateShortlisting({
        variables: {
          id: searchParams.id,
          input: {
            shortlistId: values?.shortlistId,
            examinationScore: values?.examinationScore,
            interviewScore: values?.interviewScore,
          }
        }
      }).then(({ data }) => {
        if (data?.updateShortlisting?._id) {
          toast(JSON.stringify({ type: "success", title: "Shortlisted applicant updated successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not update shortlisted applicant" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      shortlistId: data?.shortlist?._id,
      examinationScore: data?.shortlist?.examinationScore || null,
      interviewScore: data?.shortlist?.interviewScore || null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.shortlist, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Update Shortlisted Information"
      description="Provide the details to update shortlisted applicant information"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {updating ? "Updating Scores..." : "Update Scores"}
          </button>
        </>
      )}>
      {data?.shortlist?._id && (
        <ShortlistForm
          form={form}
          data={{
            maximumExaminationScore: data?.shortlist?.programme?.maximumExaminationScore,
            maximumInterviewScore: data?.shortlist?.programme?.maximumInterviewScore,
            requiresInterview: !data?.shortlist?.programme?.requiresInterview,
            requiresExamination: !data?.shortlist?.programme?.requiresExamination
          }}
        />
      )}
    </Modal>
  )
}