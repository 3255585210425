import { FC, useMemo, useRef } from 'react'
import { useUrlState, wrapClick } from 'utils';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { EnvelopeIcon, GlobeAltIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { ApplicationDetailsView } from 'components';

interface ApplicationDetailsContainerProps {
  values: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}


const formSteps = [
  {
    name: 'Personal Info',
    description: "Details",
    accessor: "personalInformation",
    SummaryComponent: ApplicationDetailsView.Personal,
  },
  {
    name: 'Programme Info',
    description: 'Details',
    accessor: "programmeInformation",
    SummaryComponent: ApplicationDetailsView.Programme,
  },
  {
    name: 'Education Info',
    description: 'Details',
    accessor: "educationalHistoryInformation",
    SummaryComponent: ApplicationDetailsView.Education,
  },
  {
    name: 'Employment Info',
    description: 'Details',
    accessor: "employmentHistoryInformation",
    SummaryComponent: ApplicationDetailsView.Employment,
  },
  {
    name: 'Reference Info',
    description: 'Details',
    accessor: "referencesInformation",
    SummaryComponent: ApplicationDetailsView.Reference,
  },
  {
    name: 'Extra Info',
    description: 'Details',
    accessor: "extraInformation",
    SummaryComponent: ApplicationDetailsView.Extra,
  },
]

const ApplicationDetailsContainer: FC<ApplicationDetailsContainerProps> = ({ values, code }) => {
  const currentConfig = useReactiveVar(currentConfigVar);
  const [page, setPage] = useUrlState("page");
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: code,
    bodyClass: "w-[1000px]",
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div ref={printRef} className="flex-1 flex flex-col overflow-hidden">
      
        <div className="space-y-6 divide-y divide-primary-500/50 p-6 print:p-0 overflow-y-auto flex-1 no-scrollbar">
          {formSteps.map(({ SummaryComponent, ...summary }, idx) => {
            return(
              <div key={idx} className={idx === 0 ? " py-4" : " py-4 pt-6"}>
                <>
                  <div className="flex justify-between items-center cursor-pointer">
                    <div>
                      <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">{summary?.name}</h3>
                      <p className="mt-1 text-xs text-gray-500">{summary?.description}</p>
                    </div>
                    <div />
                  </div>
                  
                  <div className="mt-6">
                    <SummaryComponent
                      data={_.get(values, summary?.accessor)}
                      values={values}
                      config={currentConfig}
                    />
                  </div>
                </>
              </div>
            )
          })}
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(handlePrint)}
          className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Print
        </button>
        <button
          type="button"
          onClick={wrapClick(() => setPage(page - 1))}
          className="w-full inline-flex justify-center rounded border border-gray-200 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-600 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Previous
        </button>
      </div>
    </div>
  )
}

export default ApplicationDetailsContainer
