import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC, useMemo } from "react";
import { currentUserVar } from "apollo/cache/auth";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
import { generateApplicantsLineChartFilter } from "utils/general";
import { MoonLoader } from "react-spinners";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import BarChart from "components/charts/bar-chart";
import { currentConfigVar } from "apollo/cache/config";
dayjs.extend(weekOfYear);

interface ApplicantsBarChartContainerProps {
  cycle?: string;
  dates: {
    start: string;
    end: string;
  };
}

const GET_APPLICATIONS_STATS = gql`
  query Query($filter: CountApplicationsFilter) {
    countApplicationsByDay(filter: $filter)
  }
`;

const ApplicationsDayBarChartContainer: FC<
  ApplicantsBarChartContainerProps
> = ({ cycle, dates }) => {
  const filters = useMemo(() => {
    const end = dayjs(dates?.start || new Date()).add(1, "day");
    const start = dayjs(dates?.end || end.subtract(8, "day"));

    return {
      createdAt: {
        between: {
          start: start.format("YYYY-MM-DD"),
          end: end.format("YYYY-MM-DD"),
        },
      },
      ...(cycle && {
        cycle: {
          eq: cycle,
        },
      }),
    };
  }, [cycle]);

  const { loading, data } = useQuery(GET_APPLICATIONS_STATS, {
    variables: {
      filter: filters,
    },
  });

  const colorUnder = "rgb(1, 124, 184)";
  const colorGrad = "rgb(210, 82, 233)";

  const barData: any[] = data?.countApplicationsByDay?.map((record: any) => ({
    day: record.date,
    graduates: record?.graduate,
    graduatesColor: colorGrad,
    undergraduates: record?.undergraduate,
    undergraduatesColor: colorUnder,
  }));

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Applications (New)
          </h3>
          <p className="text-xs text-gray-400">
            Displays the dates of started applications
          </p>
        </div>

        {loading && <MoonLoader size={20} />}
      </div>
      <BarChart
        keys={["graduates", "undergraduates"]}
        indexBy="day"
        xLabel="Date"
        xLabelFormat={(value) => `${dayjs(value).format("ddd")}`}
        yLabel="No. of Applicants"
        data={barData || []}
      />
    </>
  );
};

export default ApplicationsDayBarChartContainer;
