import { FC } from "react"
import _ from "lodash"
import lodash from "lodash"
import { ICurrentConfig } from "apollo/cache/config";

interface ApplicationDetailsContainerProps {
  data: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const ReferenceInfoSummary: FC<{ data: any; config: ICurrentConfig }> = ({ data }) => {
  return (
    <div className="border border-dashed border-gray-300 dark:border-gray-600 rounded p-3 space-y-6 divide-y divide-gray-200">
      <div className=''>
        <span className='text-xs font-light'>Academic References</span>
        <div className="mt-2 space-y-6">
          {!data?.academic?.length &&
            <div className="mt-1 block w-full sm:text-sm">
              No references
            </div>
          }
          {data?.academic?.map((data: any, idx: any) => (
            <div className='relative grid grid-cols-4 gap-6 mt-2 border border-gray-200 p-3 rounded'>
              <span className="text-xs text-gray-700 font-light bg-white px-1 absolute -top-2 left-4">Reference {idx + 1}</span>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Full Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.name || "Not Set"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Organization
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.organization || "Not Set"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Role
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.title || "Not Set"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Email Address
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.email || "Not Set"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Phone Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.phone || "Not Set"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Address
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.address || "Not Set"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className=''>
        <span className='text-xs font-light'>Non-Academic References</span>
        <div className="mt-2 space-y-6">
          {!data?.nonAcademic?.length &&
            <div className="mt-1 block w-full sm:text-sm">
              No references
            </div>
          }
          {data?.nonAcademic?.map((data: any, idx: any) => (
            <div className='relative grid grid-cols-4 gap-6 mt-2 border border-gray-200 p-3 rounded'>
              <span className="text-xs text-gray-700 font-light bg-white px-1 absolute -top-2 left-4">Reference {idx + 1}</span>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Full Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.name || "Not Set"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Organization
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.organization || "Not Set"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Role
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.title || "Not Set"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Email Address
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.email || "Not Set"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Phone Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.phone || "Not Set"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Address
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.address || "Not Set"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ReferenceInfoSummary