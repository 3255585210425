import { StepWizard } from "components";
import { FormikProps } from "formik"
import { FC, useState } from "react"
import ExaminationsInfoForm from "./examinations-info";
import ProgrammeInfoForm from "./programme-info";
import RequirementsInfoForm from "./requirements-info";
import SummaryPage from "./summary";
import { ProgrammeSchema } from "./schema";

interface AdmissionProgrammeFormProps {
  form: FormikProps<{
    programmeInfo: {
      programme: any;
      department: any;
      school: any;
      tracks: ("SEMESTER" | "TRIMESTER" | "QUARTERLY" | "MODULAR")[];
      offerings: {
        campus: any;
        types: ("DAY" | "EVENING" | "WEEKEND")[];
      }[];
      duration: number
      hasAdmissionQuota: boolean
      admissionQuota: number
      requirementTypes: ("ALEVEL" | "OLEVEL" | "WASSCE" | "SSSCE" | "HND" | "DIPLOMA" | "LLB" | "DEGREE" )[]
    };
    requirementsInfo: {
      requirements: {
        description: string;
        specification: {
          mandatory: boolean;
          requiredGrade: number;
          subject: string;
        }[];
        type: ("ALEVEL" | "OLEVEL" | "WASSCE" | "SSSCE" | "HND" | "DIPLOMA" | "LLB" | "DEGREE" );
        defaultMinimumGrade: any;
        numberOfPassedSubjects: number;
      }[];
    };
    examinationInfo: {
      requiresExamination: boolean;
      requiresInterview: boolean;
      maximumInterviewScore: number;
      maximumExaminationScore: number;
      requiredExaminationScore: number;
      requiredInterviewScore: number;
      interviewScoreWeight: number;
      examinationScoreWeight: number;
    }
  }>;
  isCompleted?: boolean;
}

const steps = [
  { name: "Programme", href: 0 },
  { name: "Requirements", href: 1 },
  { name: "Examinations", href: 2 },
  { name: "Preview", href: 3 },
]

const AdmissionProgrammeForm: FC<AdmissionProgrammeFormProps> = ({ form, isCompleted = false }) => {

  const [step, setStep] = useState(0)
  const [lastStep, setLastStep] = useState(isCompleted ? (steps.length - 1) : 0)

  const onCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  }

  const handleNextStep = (_step?: string) => (_values: any) => {
    // check if last step
    if (_step) {
      form.setFieldValue(_step, _values)
    }
    setStep(step + 1)
    setLastStep(step === lastStep ? (lastStep + 1) : lastStep)
  }

  const handlePreviousStep = () => {
    setStep(step - 1)
  }

  const handleCancel = () => {
    form.resetForm();
    onCancel();
  }

  const renderForm = () => {

    switch (step) {
      case 0: {
        return <ProgrammeInfoForm
          handleNext={handleNextStep("programmeInfo")}
          initialValues={form.values["programmeInfo"]}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
      case 1: {
        return <RequirementsInfoForm
          handleNext={handleNextStep("requirementsInfo")}
          initialValues={form.values["requirementsInfo"]}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
      case 2: {
        return <ExaminationsInfoForm
          handleNext={handleNextStep("examinationInfo")}
          initialValues={form.values["examinationInfo"]}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
      case 3: {
        return <SummaryPage
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleStep={setStep}
          handleSubmit={form.handleSubmit}
          handleCancel={handleCancel}
          submitLoading={form.isSubmitting}
        />
      }
      default: {
        return <ProgrammeInfoForm
          handleNext={handleNextStep("requirementsInfo")}
          initialValues={form.values["requirementsInfo"]}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
    }
  }

  return (
    <div className="bg-white dark:bg-gray-900 shadow-xl flex flex-col flex-1 overflow-hidden relative h-[75vh]">
      <div className="px-6 pt-6">
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {renderForm()}
      </div>
    </div>
  )
}

export default AdmissionProgrammeForm