import { FC } from 'react'
import numeral from 'numeral';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { classNames } from 'utils';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import lodash from 'lodash';
dayjs.extend(localeData)

interface VoucherClassViewProps {
  voucherClass: {
    code: string;
    name: string;
    description: string;
    price: number;
    level: string;
    applicableProgrammes: {
      _id: string;
      code: string;
      name: string;
      school: {
        _id: string;
        code: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      level: string;
      programme: {
        _id: string;
        code: string;
        name: string;
        description: string;
        level: string;
        createdAt: string;
        updatedAt: string;
      };
    }[];
    createdBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    };
    lastUpdatedBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    }
    createdAt: string;
    updatedAt: string;
  };
}

const VoucherClassView: FC<VoucherClassViewProps> = ({ voucherClass }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Voucher Class Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {voucherClass?.code || "N/A"}
            </div>
          </div>

          <div className='col-span-'>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {voucherClass?.name || "N/A"}
            </div>
          </div>

          <div className='col-span-3'>
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {voucherClass?.description || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Price
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {voucherClass?.price ? `GHS ${numeral(voucherClass?.price/100).format("0,0.00")}` : "N/A"}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='pt-6'>
        <span className='text-xs font-light'>Applicable Programmes Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          {lodash.chain(voucherClass?.applicableProgrammes)?.sortBy(["name", "code"])?.map?.((applicableProgramme: any) => (
            <div
              key={applicableProgramme?._id}
              className="rounded border border-gray-300 bg-white p-4 shadow-sm flex items-center space-x-3"
            >
              <div className="flex-1 min-w-0">
                <span className="focus:outline-none">
                  <p className="text-xs text-gray-900 dark:text-gray-50 line-clamp-1">{applicableProgramme?.name}</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 truncate">{applicableProgramme?.code}</p>
                </span>
              </div>
            </div>
          )).value()}
        </div>
      </div> */}
      <div className='pt-6'>
        <span className='text-xs font-light'>Meta Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {voucherClass?.createdAt ? dayjs(voucherClass?.createdAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {voucherClass?.createdBy?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {voucherClass?.updatedAt ? dayjs(voucherClass?.updatedAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {voucherClass?.lastUpdatedBy?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoucherClassView;