import { Avatar } from 'components/core';
import { FC } from 'react'
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { useReactiveVar } from '@apollo/client';

dayjs.extend(localeData)

interface ShortlistViewProps {
  shortlist: {
    _id: string
    code: string
    createdAt: string
    updatedAt: string
    applicant: {
      code: string
      email: string
      _id: string
    }
    cycle: {
      _id: string
      code: string
      name: string
    }
    examinationScore: number
    interviewScore: number
    programme: {
      _id: string
      name: string
      level: string
      requiresInterview: boolean
      requiresExamination: boolean
    }
    lastUpdatedBy: {
      _id: string
      name: string
    }
    createdBy: {
      _id: string
      name: string
    }
    application: {
      code: string
      personalInformation: {
        profileImageUrl: string
        firstName: string
        lastName: string
      }
      educationalHistoryInformation: {
        history: {
          institution: string
          idNumber: string
          graduatingYear: string
          graduatingMonth: string
        }
        type: string
      }
    }
  };
}

const ShortlistView: FC<ShortlistViewProps> = ({ shortlist }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);
  console.log({ shortlist })

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Applicant Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>

          <div className=''>
            <Avatar
              alt={[(shortlist?.applicant?.email || "")?.trim()].join(" ").trim() || "N A"}
              src={shortlist?.application?.personalInformation?.profileImageUrl || ""}
              size="lg"
            />
          </div>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm uppercase">
              {[shortlist?.application.personalInformation?.lastName, shortlist?.application.personalInformation?.firstName].join(" ") || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.application?.code || "N/A"}
            </div>
          </div>

          <div className='col-span-'>
            <span className="block text-sm font-light text-gray-700">
              Email
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.applicant?.email || "N/A"}
            </div>
          </div>
        </div>
      </div>

      <div>
        <span className='text-xs font-light'>Programme Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.programme?.name || "N/A"}
            </div>
          </div>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Level
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.programme?.level || "N/A"}
            </div>
          </div>

          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Requires Interview
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.programme?.requiresInterview ? "Yes" : "No"}
            </div>
          </div>

          {
            shortlist?.programme?.requiresInterview &&
            <div className=''>
              <span className="block text-sm font-light text-gray-700">
                Interview Score
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {shortlist?.interviewScore || "N/A"}
              </div>
            </div>
          }


          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Requires Examination
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.programme?.requiresExamination ? "Yes" : "No"}
            </div>
          </div>

          {
            shortlist?.programme?.requiresExamination &&
            <div className=''>
              <span className="block text-sm font-light text-gray-700">
                Examination Score
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {shortlist?.examinationScore || "N/A"}
              </div>
            </div>
          }

        </div>
      </div>

      <div className='pt-6'>
        <span className='text-xs font-light'>Meta Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.createdAt ? dayjs(shortlist?.createdAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.createdBy?.name || ""}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.updatedAt ? dayjs(shortlist?.updatedAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {shortlist?.lastUpdatedBy?.name || ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShortlistView;