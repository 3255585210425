import { useRef } from 'react';
import { wrapClick } from 'utils';
import {
    gql,
    useMutation,
    useQuery,
} from '@apollo/client';
import { UserView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import toast from 'react-hot-toast';
import _ from "lodash";


const GET_USER = gql`
query GetUser($filter: UserFilter! , $populate: [String]) {
  user:getUser(filter: $filter,populate: $populate) {
    _id
    code
    createdAt
    email
    staffId
    gender
    meta {
      isFirstLogin
      isSuspended
    }
    name
    phone
    profileImageUrl
    role
    school {
      name
      _id
      code
    }
    campus {
      _id
      code
      name
    }
    department {
      name
      _id
      code
    }
    updatedAt
  }
}
`

const UNSUSPEND_USER = gql`
mutation UnsuspendUser($unsuspendUserId: ID!) {
  unsuspendUser(id: $unsuspendUserId) {
    _id
    code
  }
}
`
const SUSPEND_USER = gql`
mutation SuspendUser($suspendUserId: ID!) {
  suspendUser(id: $suspendUserId) {
    _id
    code
  }
}
`

export default function SuspendStaffContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; }) {
    // const theme = useReactiveVar(currentConfigVar);

    const cancelButtonRef = useRef(null);
    const searchParams = useSearch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const { data, loading } = useQuery(GET_USER, {
        variables: {
            filter: {
                _id: {
                    eq: searchParams.id
                }
            },
            populate: ["school", "department", "campus"]
        },
        notifyOnNetworkStatusChange: false
    });

    const isUserSuspended = data?.user?.meta?.isSuspended
    const actionLabel = isUserSuspended ? "Unsuspend" : "Suspend"

    const suspendVariables = {
        variables: {
            suspendUserId: searchParams.id
        }
    }
    const unsuspendVariables = {
        variables: {
            unsuspendUserId: searchParams.id
        }
    }

    const [suspendUser, { loading: suspending }] = useMutation(SUSPEND_USER, suspendVariables)
    const [unsuspendUser, { loading: unsuspending }] = useMutation(UNSUSPEND_USER, unsuspendVariables)

    const buttonLabel = (): string => {
        if (suspending) return "Suspending ";
        if (unsuspending) return "Unsuspending ";

        return actionLabel;
    }

    async function handleAction() {
        const action = isUserSuspended ? unsuspendUser : suspendUser
        const actionLabel = isUserSuspended ? "unsuspendUser" : "suspendUser"
        await action().then(({ data }) => {
            if (data?.[actionLabel]._id) {
                toast(JSON.stringify({ type: "success", title: `Staff ${isUserSuspended ? "Unsuspended" : "Suspended"} Successfully` }));
                refetch?.();
                setOpen(false);
            }
            else {
                toast(JSON.stringify({ type: "error", title: `Could not ${actionLabel?.toLowerCase()} Staff` }));
            }
        })
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title={`${isUserSuspended ? "Unsuspend" : "Suspend"} This User`}
            description="Details of user are shown below"
            renderActions={() => (
                <>
                    {
                        data?.user?.meta?.isSuspended ?
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={wrapClick(handleAction)}
                                disabled={suspending || unsuspending}
                            >
                                {buttonLabel()} User
                            </button>
                            :
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={wrapClick(handleAction)}
                                disabled={suspending || unsuspending}
                            >
                                {buttonLabel()} User
                            </button>
                    }

                </>
            )}
        >
            <UserView
                user={data?.user}
            />
        </Modal>
    );
}