import { FC } from "react"
import { ICurrentConfig } from "apollo/cache/config";
import dayjs from "dayjs";
import _ from "lodash";
import { wrapImage } from "utils";

const IdentityInfoSummary: FC<{ data: any; values: any; config: ICurrentConfig }> = ({ data, values, config: { dateFormat } }) => {
  console.log({ data })
  return (
    <div className="border border-dashed border-gray-300 dark:border-gray-600 rounded p-3 space-y-6 divide-y divide-gray-200">
      <div className=''>
        <span className='text-xs font-light'>Customer Identity Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Has GIMPA Student ID
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.isExistingStudent ? "Yes" : "No"}
            </div>
          </div>
          {
            data?.isExistingStudent &&
            <>
              <div className="col-span-1">
                <span className="block text-sm font-light text-gray-700">
                  Student ID
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.existingStudentId || "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Programme Studied
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.existingStudentProgramme?.name || "N/A"}
                </div>
              </div>
            </>
          }

        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Sponsorship Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Sponsor
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(data?.sponsorType) || "N/A"}
            </div>
          </div>
          {
            data?.sponsorType !== "SELF" && (
              <>
                <div className="col-start-1 col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Organization
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.organization || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Contact Person Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.name || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Contact Person Role
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.title || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Contact Person Email
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.email || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Contact Person Phone
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.phone || "N/A"}
                  </div>
                </div>
              </>
            )
          }

        </div>
      </div>
    </div>
  )
}

export default IdentityInfoSummary
