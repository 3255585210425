import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Modal } from "components";
import { Action } from "components/buttons/action-button";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { withPermissions, wrapClick } from "utils";
import { GraduateStudent, UndergraduateStudent } from "components/cards/admission-letter-view";
import { AdmittedProps, GET_ADMISSION, GET_INVOICES, GET_VENDORS, GetInvoicesInputProps, GetInvoicesOutputProps, GetVendorsOutputProps, VendorProps } from "apollo/graphql/queries";
import { extractLastTwoNumbers, generateReference, getTitle, moveForwardFromDate } from "utils/general";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";


export default function DownloadAdmissionContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; }) {
    const { pollInterval, dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);
    const printRef = useRef<any>();
    const navigate = useNavigate<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const { data: vendorData, loading: vendorLoading } = useQuery<GetVendorsOutputProps>(GET_VENDORS, { fetchPolicy: "no-cache" });
    const { data, loading } = useQuery(GET_ADMISSION, {
        variables: {
            filter: {
                _id: {
                    eq: searchParams.id
                }
            },
            populate: ["programme", "applicant", "programme.department", "programme.school", "application.personalInformation", "shortlist", "shortlist.offeredProgramme", "department", "school", "createdBy", "cycle", "application.programmeInformation.preferredCampus"]
        },
        notifyOnNetworkStatusChange: false,
        // pollInterval,
        fetchPolicy: 'network-only'
    });
    const [getInvoices, { data: invoicesData, loading: loadingInvoices }] = useLazyQuery<GetInvoicesOutputProps, GetInvoicesInputProps>(GET_INVOICES, {
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (data?.application) {
            getInvoices({
                variables: {
                    filter: {
                        application: {
                            eq: data?.application?._id || ""
                        },
                        status: {
                            eq: "PENDING"
                        }
                    }
                }
            });
        }
    }, [data?.application]);


    const navigateItem = (action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
        navigate({
            search: (old) => ({
                ...old,
                modal: action
            })
        });
    };

    const admitted = data?.admission as AdmittedProps
    const vendors = vendorData?.getVendors
    const invoices = invoicesData?.getInvoices


    const paymentDates = useMemo(() => {
        if (admitted?.application?.level === "UNDERGRADUATE") {
            return {
                commitment: moveForwardFromDate(admitted?.cycle?.schoolFeesDates?.commitmentFeeDate || "", 0, "dddd, MMMM Do, YYYY"),
                moreThanHalf: moveForwardFromDate(admitted?.cycle?.schoolFeesDates?.provisionalRegistrationDate || "", 0, "dddd, MMMM Do, YYYY"),
                outstanding: moveForwardFromDate(admitted?.cycle?.schoolFeesDates?.outstandingFeesDate || "", 0, "dddd, MMMM Do, YYYY")
            };
        } else {
            return {
                commitment: moveForwardFromDate(admitted?.cycle?.schoolFeesDates?.commitmentFeeDate || "", 0, "dddd, MMMM Do, YYYY"),
                moreThanHalf: moveForwardFromDate(admitted?.cycle?.schoolFeesDates?.provisionalRegistrationDate || "", 0, "dddd, MMMM Do, YYYY"),
                outstanding: moveForwardFromDate(admitted?.cycle?.schoolFeesDates?.outstandingFeesDate || "", 0, "dddd, MMMM Do, YYYY")
            };
        }
    }, [admitted]);

    const studentID = useMemo(() => {
        const lastNumbers = extractLastTwoNumbers(admitted?.cycle?.academicCalendar?.year || "");
        return admitted?.application?.gimpaIndexNumber || `2${lastNumbers}0XXXXX`;
    }, [admitted]);

    const applicantName = [
        admitted?.application?.personalInformation?.lastName,
        admitted?.application?.personalInformation?.firstName,
        admitted?.application?.personalInformation?.middleName,
    ].join(" ");

    const applicantNameAndTitle = `${getTitle(admitted?.application?.personalInformation?.gender, admitted?.application?.personalInformation?.maritalStatus)} ${applicantName}`;

    const director = admitted?.application?.level === "UNDERGRADUATE" ? {
        name: admitted?.cycle?.signatures?.undergraduateName,
        address: admitted?.cycle?.signatures?.undergraduatePortfolio,
        signature: admitted?.cycle?.signatures?.undergraduateUrl
    } : {
        name: admitted?.cycle?.signatures?.graduateName,
        address: admitted?.cycle?.signatures?.graduatePortfolio,
        signature: admitted?.cycle?.signatures?.graduateUrl
    };

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Admission Letter",
        bodyClass: "w-full",
    });

    const hasUnpaidInvoices = invoices && invoices?.length > 0;

    const handleSubmit = () => {
        handlePrint();
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading || vendorLoading || loadingInvoices}
            title="Download Admission Letter"
            description="The information below shows the admission letter of the applicant"
            renderActions={() => (
                <>

                    {withPermissions(["*:*", "admissions:*", "admissions:manage"])(
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
                            onClick={wrapClick(() => handleSubmit())}
                        >
                            Print
                        </button>

                    )}
                    {withPermissions(["*:*", "admissions:*", "admissions:manage"])(
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
                            onClick={wrapClick(navigateItem("view"))}
                        >
                            View Applicant
                        </button>

                    )}

                </>
            )}
        >

            {
                hasUnpaidInvoices && (
                    <div className="absolute z-50 top-0 left-0 w-full h-full flex flex-row justify-center  bg-white">

                        <div className="flex flex-col w-full">
                            <h3 className="text-xl font-semibold mt-10 text-center text-gray-700 m-0">This applicant has some unpaid invoices</h3>
                            <p className="text-center text-gray-500 mt-1">Their admission letter would be made available when they complete the following payments</p>

                            <div className="mt-10 w-full">
                                <div className="">
                                    <span className="block text-sm font-light text-gray-700">
                                        Items
                                    </span>
                                    <div className="mt-1 w-full ">
                                        <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr className="divide-x divide-gray-200">
                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[80%]"
                                                    >
                                                        Code
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[80%]"
                                                    >
                                                        Item
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[80%]"
                                                    >
                                                        Amount
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {invoices?.map((invoice, idx) => ((
                                                    <tr key={idx} className="divide-x divide-gray-200">
                                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{invoice?.code}</td>
                                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-center">{invoice?.name}</td>
                                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{`${invoice?.currency} ${invoice?.totalAmount / 100}`}</td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 flex flex-col space-y-2">
                                <h4 className="text-lg text-gray-600">Vendors payment information</h4>

                                <div className="grid grid-cols-2 gap-4">
                                    {
                                        vendors?.map((vendor) =>
                                            <div>
                                                <span className=" text-primary font-semibold">{vendor?.name} </span><br />
                                                <span className="text-gray-500">{vendor?.description || "No Vendor Description"}</span>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                )
            }

            <article style={{ fontFamily: `'Times New Roman', Times, serif` }} ref={printRef} id={"printAdmission"} className="space-y-10 times-font divide-y divide-gray-200 mt-0 mb-0 px-12 py-5 h-full print:border-0 border border-gray-300 rounded-lg relative z-0">
                <div className="grid grid-cols-6">
                    <div className="col-span-6 mb-0">
                        <h3 className="text-lg text-center font-bold mb-0">GHANA INSTITUTE OF MANAGEMENT AND PUBLIC ADMINISTRATION [GIMPA]</h3>
                        <h3 className="text-sm text-center text-gray-500">MOTTO: EXCELLENCE IN LEADERSHIP, MANAGEMENT AND ADMINISTRATION</h3>
                    </div>

                    <div className="col-span-6 grid grid-cols-6">

                        <div className="col-span-2 flex flex-col text-xs">
                            <span className="mb-5"><b>Our Ref:</b>  {generateReference(admitted?.code as string, { schoolName: admitted?.programme?.school?.name as string, admissionDate: admitted?.cycle?.academicCalendar?.openingDate as string, programmeName: admitted?.programme?.name as string })}</span>
                            <span><b>Your Ref:</b>........................</span>
                        </div>

                        <div className="col-span-2 flex flex-row justify-center">
                            <div
                                style={{ width: "120px", height: "120px" }}
                                className="group rounded-sm self-start relative object-cover flex flex-col justify-start mb-2"
                            >
                                <img
                                    className="w-full h-full flex-shrink-0 object-contain rounded-md"
                                    src={require("assets/logo.png")}
                                    alt="person"
                                />
                            </div>
                        </div>

                        <div className="col-span-2 flex flex-col text-xs">
                            <span className="text-left self-end font-semibold">P.O.BOX AH 50</span>
                            <span className="text-left self-end mb-2 font-semibold">Achimota, Accra</span>
                            <span className="text-left self-end">{dayjs(new Date(admitted?.createdAt || "")).format('MMMM DD, YYYY')}</span>
                        </div>

                    </div>

                    <div className="col-span-4 flex flex-col text-xs mb-1">
                        <span className="font-bold">{applicantNameAndTitle}</span>
                        <span>P. O. Box AH50</span>
                        <span className="mb-5">Achimota - Accra</span>
                        <span className="font-bold">Dear {applicantNameAndTitle},</span>
                    </div>

                    <div className="col-span-2 flex flex-row justify-center relative">
                        <div
                            style={{ width: "100px", height: "100px" }}
                            className="group cursor-pointer rounded-none absolute self-start -top-10  object-cover flex flex-col justify-start mb-2"
                        >
                            <img
                                className="w-full h-full flex-shrink-0 object-cover rounded-none"
                                src={admitted?.application?.personalInformation?.profileImageUrl}
                                alt="person"
                            />
                        </div>
                    </div>

                    <div className="col-span-6 text-center border-b border-black ">
                        <span className="font-semibold">
                            {admitted?.application?.level === "UNDERGRADUATE" ? "UNDERGRADUATE" : "GRADUATE"}  ADMISSION OFFER - {admitted?.cycle?.academicCalendar?.year} ACADEMIC YEAR
                        </span>
                    </div>

                    <div className="col-span-6 text-justify pl-5">
                        {
                            admitted?.application?.level === "UNDERGRADUATE" ?
                                <UndergraduateStudent
                                    admissionData={admitted}
                                    applicationData={data}
                                    paymentDates={paymentDates}
                                    startingYear={admitted?.shortlist?.startingYear}
                                    credentials={{ studentId: studentID, pin: "13579" }}
                                    commitmentFee={admitted?.application?.type === "INTERNATIONAL" ? "US$1000" : "GHc2,000.00"}
                                />
                                :
                                <GraduateStudent
                                    admissionData={admitted}
                                    applicationData={data}
                                    paymentDates={paymentDates}
                                    startingYear={admitted?.shortlist?.startingYear}
                                    credentials={{ studentId: studentID, pin: "13579" }}
                                    commitmentFee={admitted?.application?.type === "INTERNATIONAL" ? "US$2000" : "GHc5,000.00"}
                                />
                        }
                    </div>
                    <br></br>
                    <div className="col-span-6 mb-3 text-xs flex flex-col">
                        <span><i>We look forward to welcoming you to the Centre of Excellence.</i></span>
                        <span>Yours sincerely,</span>
                    </div>

                    <div className="col-span-3 flex flex-col text-xs">
                        <span><b>{director?.name}</b></span>
                        <div className="relative h-14">
                            <div style={{ zIndex: -1 }} className="absolute top-0 left-0  h-14 w-auto flex flex-col justify-center">
                                <img src={director?.signature} className="w-full h-full object-contain" />
                            </div>
                        </div>
                        <span><b>{director?.address}</b></span>
                        <span className="italic">(For: Secretary of the Institute)</span>
                        <span>Cc: <b>Dean, {admitted?.programme?.school?.name}</b></span>
                    </div>


                    <div className="col-span-3">
                        <div className="w-60 text-xs text-center">
                            <p className="mb-3">GHANA INSTITUTE OF MANAGEMENT AND PUBLIC ADMINISTRATION <br /> DIRECTOR OF ACADEMIC AFFAIRS <br /> ADMITTED</p>
                            <p>DATE: {dayjs(new Date(admitted?.createdAt || "")).format('MMMM DD, YYYY')}</p>
                        </div>
                    </div>

                    <div className="col-span-6 flex flex-row justify-center mt-10 text-xs">
                        <div className="flex flex-col">
                            <span className="self-center">Tel: (233) 033 209 5432 (Ext: 1074), 030 290 8076 (Ext: 1074), 050 162 0138</span>
                            <span className="self-center"><b>E-mail:</b> admissions@gimpa.edu.gh <b>Website:</b> www.gimpa.edu.gh</span>
                        </div>
                    </div>
                </div>
            </article>

        </Modal>
    );
}