import { FC, useEffect, useState } from "react"
import { ICurrentConfig, currentConfigVar } from "apollo/cache/config";
import dayjs from "dayjs";
import { Avatar } from "components/core";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { extractFirebaseName } from "utils/general";


type DocumentType = {
  name: string;
  size?: number;
  file: any;
  isUploaded?: boolean;
  downloadUrl?: string;
};

const DocumentComponent = ({ file }: { file: string }) => {
  const [fileDocument, setFileDocument] = useState<DocumentType>();

  useEffect(() => {
    if (file && file !== "") {
      const name = extractFirebaseName(file) || ""
      setFileDocument({
        name: name,
        downloadUrl: file,
        file: undefined,
        size: undefined,
        isUploaded: true
      })
    }
  }, [file]);

  return (
    <div className="w-full h-24 flex flex-row border border-gray-200 rounded-md px-3 py-4 mt-3">
      <span className="p-2 flex-shrink-0 bg-blue-100 self-start rounded-full mr-3">
        <DocumentIcon className="w-5 h-5 text-primary-500" />
      </span>

      <div className="flex flex-col flex-1 justify-between">
        <div className="flex flex-row justify-between w-full">
          <span className="font-semibold m-0 text-sm">{fileDocument?.name}  (<a className="text-primary-500 underline" target="_blank" href={fileDocument?.downloadUrl}>View</a>)</span>
        </div>
        <span className="text-gray-400">

        </span>

        <div className="w-full hidden relative">
          <div
            style={{ width: "100%" }}
            className="rounded-full absolute flex-shrink-0 bg-blue-100 z-10 h-2"
          ></div>
          <div
            style={{ width: fileDocument?.isUploaded ? "100%" : "0%" }}
            className="rounded-full absolute duration-300 flex-shrink-0 bg-primary h-2 z-30"
          ></div>
        </div>
      </div>
    </div>
  )
}

const PersonalInfoSummary: FC<{ data: any; config: ICurrentConfig }> = ({ data }) => {

  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="border border-dashed border-gray-300 dark:border-gray-600 rounded p-3 space-y-6 divide-y divide-gray-200">
      <div className=''>
        <span className="text-xs font-light">Customer Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Photo
            </span>
            <Avatar
              alt={[data?.lastName, data?.firstName].join(" ").trim() || "N A"}
              src={data?.profileImageUrl || ""}
              size="lg"
            />
          </div>
          <div className="col-start-1">
            <span className="block text-sm font-light text-gray-700">
              Last Name
            </span>
            <div className="mt-1 block w-full sm:text-sm uppercase">
              {data?.lastName || ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              First Name
            </span>
            <div className="mt-1 block w-full sm:text-sm uppercase">
              {data?.firstName || ""}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Other Names
            </span>
            <div className="mt-1 block w-full sm:text-sm uppercase">
              {data?.middleName || ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Gender
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.gender || ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Nationality
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.nationality || ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Date of Birth
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.dateOfBirth ? dayjs(data?.dateOfBirth).format(dateFormat) : ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Place of Birth
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.placeOfBirth || ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Marital Status
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.maritalStatus || ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Number of Children
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.numberOfChildren || ""}
            </div>
          </div>

          <div className="col-span-4">
            <span className="text-xs font-light">Bio</span>
            {
              data?.bioFileUrls?.map((bioFile: any) => {
                return (
                  <DocumentComponent file={bioFile} />
                )
              })
            }
          </div>

        </div>
      </div>
      <div className='pt-6'>
        <span className="text-xs font-light">Contact Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.email || ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.phone || ""}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Secondary Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.secondaryPhone || ""}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className="text-xs font-light">Emergency Contact Information</span>
        <div className="mt-2 space-y-6">
          {data?.emergencyContacts.map((_: any, idx: any) => {
            return (
              <div className='relative grid grid-cols-4 gap-6 mt-2 border border-gray-200 p-3 rounded'>
                <span className="text-xs text-gray-700 font-light bg-white px-1 absolute -top-2 left-4">Emergency Contact {idx + 1}</span>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Full Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.emergencyContacts?.[idx]?.name || ""}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Relationship
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.emergencyContacts?.[idx]?.relationship || ""}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Email Address
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.emergencyContacts?.[idx]?.email || ""}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Phone Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.emergencyContacts?.[idx]?.phone || ""}
                  </div>
                </div>
              </div>
            )
          })

          }
        </div>
      </div>
      <div className='pt-6'>
        <span className="text-xs font-light">Physical Challenges Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Has Physical Challenges
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.isBoolean(data?.isPhysicallyChallenged) ? (data?.isPhysicallyChallenged ? "Yes" : "No") : ""}
            </div>
          </div>
          <div className="col-start-1 col-span-4">
            <span className="block text-sm font-light text-gray-700">
              Physical Challenges
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.physicalChallenges || "None"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalInfoSummary