export const calculateDateProgress = (start: Date, end: Date) => (current: Date): number => {
  const startTime = start.getTime();
  const endTime = end.getTime();
  const currentTime = current.getTime();

  if (currentTime < startTime) {
    return 0; // If current date is before start date, progress is 0%
  } else if (currentTime > endTime) {
    return 100; // If current date is after end date, progress is 100%
  } else {
    const totalDuration = endTime - startTime;
    const elapsedDuration = currentTime - startTime;
    const progress = (elapsedDuration / totalDuration) * 100;

    return Math.round(progress); // Return progress rounded to the nearest whole number
  }
}