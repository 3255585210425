import { FC } from 'react'
import { Avatar } from 'components/core';
import { classNames } from 'utils';

interface UserViewProps {
  user: {
    code: string;
    staffId: string;
    name: string;
    gender: string;
    phone: string;
    email: string;
    profileImageUrl: string;
    role: string;
    school: {
      id: string;
      name: string;
      code: string;
    };
    meta: {
      isFirstLogin: boolean;
      lastLoginAt: string;
    };
    campus: {
      _id: string
      code: string
      name: string
    }
    department: {
      name: string
      _id: string
      code: string
    }
  };
}

const UserView: FC<UserViewProps> = ({ user }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Staff Information</span>
        <div className='grid grid-cols-4 gap-4 mt-2'>
          <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={user?.name || "user_image"}
              src={user?.profileImageUrl || ""}
              size="xl"
            />

            <span
              className={classNames(
                user?.role === "SuperAdmin" ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50` : "",
                user?.role === "Admin" ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50` : "",
                user?.role === "Supervisor" ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50` : "",
                user?.role === "Reader" ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50` : "",
                user?.role === "Queued" ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50` : "",
                user?.role === "Success" ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50` : "",
                user?.role === "Reversed" ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50` : "",
                !user?.role ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
              )}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
              </svg>
              <span>
                {user?.role || "Unknown"}
              </span>
            </span>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Staff ID
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.staffId || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Gender
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.gender || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.name || "N/A"}
            </div>
          </div>

          {
            user?.school &&
            <div>
              <span className="block text-sm font-light text-gray-700">
                School
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {user?.school?.name || "N/A"} ({user?.school?.code || "N/A"})
              </div>
            </div>
          }

          {
            user?.campus &&
            <div>
              <span className="block text-sm font-light text-gray-700">
                Campus
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {user?.campus.name || "N/A"}
              </div>
            </div>
          }

          {
            user?.department &&
            <div>
              <span className="block text-sm font-light text-gray-700">
                Department
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {user?.department.name || "N/A"} ({user?.department.code || "N/A"})
              </div>
            </div>
          }
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contact Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.email || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {user?.phone || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserView