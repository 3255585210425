import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { FC } from 'react';
import { currentUserVar } from 'apollo/cache/auth';
import { PieChart, SemiPieChart } from 'components';
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from 'dayjs';
import numeral from 'numeral';
import { classNames } from 'utils';
import { MoonLoader } from 'react-spinners';
import { LocationGenerics } from 'router/location';
import { useSearch } from 'react-location';
import { currentConfigVar } from 'apollo/cache/config';
dayjs.extend(weekOfYear)

interface ApplicantsLevelChartContainerProps {
  cycleId?: string
}

const GET_INVOICES_STATS = gql`
query Query($filterPending: InvoiceFilter, $filterPaid: InvoiceFilter) {
  pending:getInvoicesCount(filter: $filterPending)
  paid:getInvoicesCount(filter: $filterPaid)
}
`;

const InvoiceChartContainer: FC<ApplicantsLevelChartContainerProps> = ({ cycleId }) => {
  const currentUser = useReactiveVar(currentUserVar);
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const cycleFilters = (cycleId && { cycle: { eq: cycleId } })

  const { loading, data } = useQuery(GET_INVOICES_STATS, {
    variables: {
      filterPending: {
        status: {
          eq: "PENDING"
        },
        ...cycleFilters
      },
      filterPaid: {
        status: {
          eq: "PAID"
        },
        ...cycleFilters
      }
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
    pollInterval
  })

  const pieData = [
    {
      color: "#27d163",
      bgColor: "bg-blue-600",
      id: "Paid",
      label: "Paid",
      value: data?.paid || 0,
    },
    {
      color: "#c0c0c0",
      bgColor: "bg-blue-400",
      id: "Pending",
      label: "Pending",
      value: data?.pending || 0,
    },
  ]

  const pieTotal = pieData?.reduce((acc, curr) => acc + curr.value, 0)

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">Invoices</h3>
          <p className='text-xs text-gray-400'>These are the invoices generated and the status</p>
        </div>
        {loading && <MoonLoader size={20} />}
      </div>

      <div className='flex flex-row w-full h-full justify-center'>
        <div className='w-4/5 h-full relative'>
          <div className="absolute bottom-12 z-40 inset-x-0  flex flex-row justify-center">
            <div>
              <dt className="truncate text-xs text-center text-gray-500">
                Total
              </dt>
              <dd className="flex items-baseline justify-between">
                <p className="text-xl font-medium text-gray-900">
                  {numeral(pieTotal).format("0,0")}
                </p>
              </dd>
            </div>
          </div>
          <SemiPieChart
            config={{
              innerRadius: 0.7
            }}
            data={pieData}
          />
        </div>
      </div>

      <div className="flex flex-row justify-center">
        {pieData?.map?.((item: any) => (
          <div
            key={item?._id}
            className="rounded-lg bg-white p-2 flex space-x-4"
          >
            <div>
              <div
                style={{ backgroundColor: item.color }}
                className={classNames(
                  "rounded-md flex items-center justify-center p-2.5"
                )}
              >
                <span
                  className="text-lg text-white"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
            <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
              <div>
                <dt className="truncate text-xs text-gray-500">
                  {item?.label}
                </dt>
                <dd className="flex items-baseline justify-between">
                  <p className="text-xl font-normal text-gray-900">
                    {numeral(item?.value).format("0,0")}
                  </p>
                </dd>
              </div>
            </div>
          </div>
        ))}
      </div>

    </>

  )
}

export default InvoiceChartContainer