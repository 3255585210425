import { UserPermission } from "apollo/cache/auth";
import { Action } from "components/buttons/action-button";
import { ReactElement } from "react";
import { ReactLocation, MakeGenerics } from "react-location";
import { parseSearch, stringifySearch } from "react-location-jsurl";

export type LocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
    fromDate?: string;
    toDate?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
    modal?: (
      "create" | "update" | "view"
      | "configure" | "calculate"
      | "assign" | "schedule" | "send"
      | "resolve" | "approve" | "reject"
      | "reassign" | "export" | "import"
      | "investigate" | "delete" | "clone" | "suspend"
      | "transfer" | "download"
    );
    id?: string;
    school?: string;
    programme?: string;
    block?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    property?: string;
    cycle?: string;
    role?: ("SuperAdmin" | "Admin" | "Supervisor" | "Reader");
    month?: number;
    year?: number;
    tariffClass?: string;
    customerType?: string;
    serviceType?: string;
    serviceClass?: string;
    view?: ("day" | "week" | "month" | "quarter" | "year" | "custom");
    orderStatus?: string;
    orderType?: string;
  },
  Params: {
    school?: string;
    programme?: string;
    admissionCycle?: string;
    application?: string;
  },
  RouteMeta: {
    layout?: ("App" | "Auth");
    breadcrumb: (params: LocationGenerics['Params']) => ReactElement;
    section?: ("General" | "Structure" | "Configuration" | "Users & Profiles");
    name?: string;
    actions?: { name: string; icon: any; action: Action; permissions: UserPermission[], theme?: ('green' | 'primary'); }[]
  }
}>;

const location = new ReactLocation({
  parseSearch,
  stringifySearch
})

export default location;
