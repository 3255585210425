import { FC, useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, AdmissionProgrammeForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';
import { currentConfigVar } from 'apollo/cache/config';

const GET_ADMISSION_PROGRAMME = gql`
query GetAdmissionProgrammeLean($filter: AdmissionProgrammeFilter!, $populate: [String]) {
  admissionProgramme: getAdmissionProgramme(filter: $filter, populate: $populate) {
    _id
    name
    hidden
    programme {
      _id
    }
  }    
}    
`;

const UPDATE_ADMISSION_PROGRAMME = gql`
  mutation UpdateAdmissionProgramme($id: ID!, $input: UpdateAdmissionProgrammeInput!) {
    admissionProgramme: updateAdmissionProgramme(id: $id, input: $input) {
      _id
    }
  }
`



interface DeleteAdmissionProgrammeContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void
}

const SuspendAdmissionProgrammeContainer: FC<DeleteAdmissionProgrammeContainerProps> = ({ open, setOpen, refetch }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_ADMISSION_PROGRAMME, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: []
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });
  const [updateAdmissionProgramme, { loading }] = useMutation(UPDATE_ADMISSION_PROGRAMME)

  const hasBeenSuspended = data?.admissionProgramme?.hidden

  const form = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      await updateAdmissionProgramme({
        variables: {
          id: searchParams.id,
          input: {
            programme: data?.admissionProgramme?.programme?._id,
            hidden: !hasBeenSuspended ? true : false
          }
        }
      }).then(({ data }) => {
        if (data?.admissionProgramme?._id) {
          toast(JSON.stringify({ type: "success", title: "Programme updated successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not updated programme" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })



  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={!hasBeenSuspended ? "Suspend Programme" : "Resume Programme"}
      description={!hasBeenSuspended ? "Suspend this programme from this admission cycle" : "Resume the programme in this admission cycle"}
      size='xl'
      renderActions={() => (
        <>
          {
            !hasBeenSuspended ?
              <button
                type="button"
                disabled={loading}
                className="w-full inline-flex justify-center rounded text-white border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium  hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={wrapClick(form.handleSubmit)}
              >
                {loading ? "Suspending Programme..." : "Suspend Programme"}
              </button>
              :
              <button
                type="button"
                disabled={loading}
                className="w-full inline-flex justify-center rounded text-white border border-transparent shadow-sm px-4 py-2 bg-green-700 text-base font-medium  hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={wrapClick(form.handleSubmit)}
              >
                {loading ? "Resuming Programme..." : "Resuming Programme"}
              </button>
          }

        </>
      )}>
      <div>
        {
          !hasBeenSuspended ?
            <p className="text-sm text-gray-500 text-center">
              Are you sure you want to suspend this admission programme <span className='whitespace-nowrap font-medium text-gray-700'>({data?.admissionProgramme?.name})</span>?
              Once you confirm this programme will not be visible to any applicant until you undo this.
            </p>
            :
            <p className="text-sm text-gray-500 text-center">
              Are you sure you want to resume this admission programme <span className='whitespace-nowrap font-medium text-gray-700'>({data?.admissionProgramme?.name})</span>?
            </p>
        }
      </div>
    </Modal>
  )
}

export default SuspendAdmissionProgrammeContainer;