import { FC, Fragment, useMemo } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Popover, Transition } from "@headlessui/react";
import { classNames, useUrlState, wrapClick } from "utils";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import DatePickerMonth from "./date-picker-month";
dayjs.extend(objectSupport);

const CALENDAR_FORMAT = "MMMM, YYYY";

const MonthPicker: FC = () => {
  const [month, setMonth] = useUrlState("month");
  const [year, setYear] = useUrlState("year");

  const currentMonthYearDate = useMemo(() => dayjs({ month, year, date: 1 }), [month, year])

  const previousMonth = () => {
    const newDate = currentMonthYearDate.subtract(1, "month")
    setYear(newDate.year())
    setMonth(newDate.month())
  }
  const nextMonth = () => {
    const newDate = currentMonthYearDate.add(1, "month")
    setYear(newDate.year())
    setMonth(newDate.month())
  }
  const setCurrentMonth = ({ year, month }: { year: number; month: number }) => {
    setYear(year)
    setMonth(month)
  }

  return (
    <div className="relative flex items-center rounded shadow-sm md:items-stretch">
      <button
        type="button"
        onClick={wrapClick(previousMonth)}
        disabled={month === 0 && year === 2023}
        className={classNames(
          month === 0 && year === 2023 ? "cursor-not-allowed" : "cursor-pointer",
          "flex items-center justify-center rounded-l border border-r-0 border-gray-200 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
        )}
      >
        <span className="sr-only">Previous month</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      <Popover as={Fragment}>
        <Popover.Button
          as="button"
          type="button"
          className="w-36 hidden border-t border-b border-gray-200 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block outline-none"
        >
          {dayjs(currentMonthYearDate).format(CALENDAR_FORMAT)}
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity z-10" />
        </Transition>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <Popover.Panel as="div" className="absolute top-12 right-0 z-50 bg-white p-4 rounded h-48 flex flex-col overflow-hidden">
            {({ close }) => (
              <DatePickerMonth
                date={{ year, month }}
                setDate={({ year, month }: { year: number; month: number }) => {
                  setCurrentMonth({ year, month });
                  close();
                }}
                min={{ year: 2023, month: 0 }}
                max={{ year: dayjs().year(), month: dayjs().month() }}
              />
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
      <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
      <button
        type="button"
        onClick={wrapClick(nextMonth)}
        disabled={month === dayjs().month() && year === dayjs().year()}
        className={classNames(
          month === dayjs().month() && year === dayjs().year() ? "cursor-not-allowed" : "cursor-pointer",
          "flex items-center justify-center rounded-r border border-l-0 border-gray-200 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
        )}
      >
        <span className="sr-only">Next month</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  )
}

export default MonthPicker