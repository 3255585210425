import { FC, useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, AdmissionProgrammeForm, ReassignAdmissionProgrammeForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import lodash from 'lodash';
import { currentConfigVar } from 'apollo/cache/config';
import { removeTypename } from 'utils/general';

export const GET_ADMISSION_PROGRAMME = gql`
  query GetAdmissionProgrammeLean($filter: AdmissionProgrammeFilter!, $populate: [String]) {
    admissionProgramme: getAdmissionProgramme(filter: $filter, populate: $populate) {
      _id
      code
      name
      school {
        _id
        code
        name
      }
      level
      programme {
        _id
        code
        name
      }
      department {
        _id
        code
        name
      }
      tracks
      offerings {
        campus {
          _id
          code
          name
        }
        types
      }
      requirements {
        type
        description
        specifications {
          subject
          requiredGrade
          mandatory
        }
        defaultMinimumGrade
        numberOfPassedSubjects
      }
      duration
      requiresInterview
      maximumInterviewScore
      requiredInterviewScore
      interviewScoreWeight
      requiresExamination
      maximumExaminationScore
      requiredExaminationScore
      examinationScoreWeight
    }
  }        
`;

export const GET_PROGRAMME = gql`
query GetProgramme($populate: [String], $filter: ProgrammeFilter!) {
  getProgramme(populate: $populate, filter: $filter) {
    _id
    name
    school {
      _id
      name
    }
    department {
      _id
      name
    }
  }
}
`

const UPDATE_ADMISSION_PROGRAMME = gql`
  mutation UpdateAdmissionProgramme($id: ID!, $input: UpdateAdmissionProgrammeInput!) {
    admissionProgramme: updateAdmissionProgramme(id: $id, input: $input) {
      _id
    }
  }
`

interface UpdateAdmissionProgrammeContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void
}

const ReassignAdmissionProgrammeContainer: FC<UpdateAdmissionProgrammeContainerProps> = ({ open, setOpen, refetch }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { data, loading } = useQuery(GET_ADMISSION_PROGRAMME, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["school", "programme", "offerings.campus"]
    },
    notifyOnNetworkStatusChange: false,
    pollInterval,
    fetchPolicy: 'no-cache'
  });
  const [updateAdmissionProgramme, { loading: loadingUpdate }] = useMutation(UPDATE_ADMISSION_PROGRAMME)

  const [getProgramme, { data: programmeData, loading: loadingProgramme }] = useLazyQuery(GET_PROGRAMME, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        _id: {
          eq: data?.admissionProgramme?.programme._id || ""
        }
      }
    }

  })

  useEffect(() => {
    if (data?.admissionProgramme?.programme?._id) {
      getProgramme({
        variables: {
          filter: {
            _id: {
              eq: data?.admissionProgramme?.programme._id || ""
            }
          }
        }
      })
    }
  }, [data]);


  const form = useFormik({
    initialValues: {
      name: null as any,
      admissionProgramme: null as any,
      programme: null as any,
      school: null as any,
      department: null as any,
    },
    onSubmit: async (values) => {
      console.log({ ...values })
      await updateAdmissionProgramme({
        variables: {
          id: searchParams.id,
          input: {
            name: values?.name,
            school: values?.school,
            department: values?.department,
            programme: values?.programme,
          }
        }
      }).then(({ data }) => {
        if (data?.admissionProgramme?._id) {
          toast(JSON.stringify({ type: "success", title: "Admission Programme updated successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not update programme" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    console.log("form", form?.values)
  }, [form?.values]);

  useEffect(() => {

    const fetchedData = removeTypename(data?.admissionProgramme)

    form.setValues({
      name: "",
      programme: fetchedData?.programme?._id,
      admissionProgramme: fetchedData?._id || "",
      school: fetchedData?.school?._id || "",
      department: fetchedData?.department?._id || "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.admissionProgramme, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Reassign Admission Programme Information"
      description="Provide the details to update programme"
      size='4xl'
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loadingUpdate}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving Admission Programmes..." : "Save Admission Programme"}
          </button>
        </>
      )}
    >
      {data?.admissionProgramme?._id && (
        <ReassignAdmissionProgrammeForm
          form={form}
          programme={programmeData?.getProgramme}
        />
      )}
    </Modal>
  )
}

export default ReassignAdmissionProgrammeContainer;