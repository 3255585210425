import { SelectInput, TextArea, TextInput } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface VoucherClassFormProps {
  form: FormikProps<{
    name: any;
    description: string;
    price: number;
    level: string
  }>;
}

const VoucherClassForm: FC<VoucherClassFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Class Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <TextInput
              id="code"
              label="Code"
              type="text"
              placeholder="e.g. 07"
              disabled={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Bachelors for Undergraduate"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <TextArea
              id="description"
              label="Description"
              placeholder="e.g. For any law school applicant"
              {...form}
            />
          </div>

          <div className="space-y-1">
            <SelectInput
              id="level"
              label="Level"
              placeholder="e.g. Graduate"
              options={[
                { label: "- select -", value: "" },
                { label: "Graduate", value: "GRADUATE" },
                { label: "Undergraduate", value: "UNDERGRADUATE" },
              ]}
              required={true}
              {...form}
            />
          </div>

          <div className="">
            <TextInput
              id="price"
              label="Price"
              type="text"
              placeholder="e.g. 300"
              required={true}
              postText="GHS"
              {...form}
            />
          </div>

        </div>
      </div>

    </div>
  )
}

export default VoucherClassForm