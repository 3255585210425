import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Modal, SelectInput, TextArea } from "components";
import ShortlistReferenceReviewView from "components/cards/review-references-view";
import ShortlistReviewView from "components/cards/review-view";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, useUrlState, withPermissions, wrapClick } from "utils";


const GET_SHORTLISTED = gql`
query GetShortlisting($filter: ShortlistFilter!, $populate: [String]) {
  shortlist: getShortlisting(filter: $filter, populate: $populate) {
    _id
    code
    createdAt
    updatedAt
    applicant {
      code
      email
      _id
    }
    cycle {
      _id
      code
      name
    }
    examinationScore
    interviewScore
    programme {
      _id
      name
      requiresInterview
      requiresExamination
      level
    }
    lastUpdatedBy {
      _id
      name
    }
    application {
      _id
      personalInformation {
        profileImageUrl
        firstName
        lastName
      }
    }
    status
    declineComments
    startingYear
  }
}
`

const UPDATE_SHORTLIST = gql`
mutation UpdateShortlisting($input: ShortlistInput!) {
  updateShortlisting(input: $input) {
    _id
    code
  }
}
`

const GET_ADMITTED = gql`
query GetAdmission($filter: AdmissionFilter!) {
  getAdmissions(filter: $filter) {
    _id
    shortlist {
      _id
    }
  }
}
`



export default function ReviewShortlistedContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const [status, setStatus] = useState("");
  const [startYear, setStartYear] = useState(1);
  const [applicationLoading, setApplicationLoading] = useState(false);
  const [declineComments, setDeclineComments] = useState("");
  const [_, setModal] = useUrlState("modal");
  const [tab, setOrderTab] = useState("General");

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_SHORTLISTED, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: [
        "lastUpdatedBy",
        "programme",
        "applicant",
        "cycle",
        "application.personalInformation",
        "application.educationalHistoryInformation",
        "application.referencesInformation",
        "application.referencesInformation.academic.recommendation"
      ]
    },
    notifyOnNetworkStatusChange: false,
    // pollInterval,
    fetchPolicy: 'network-only'
  });

  // console.log({ data })


  const [getAdmission, { data: admittedData, loading: loadingAdmittedData }] = useLazyQuery(GET_ADMITTED)

  const [updateShortlisting, { loading: updating }] = useMutation(UPDATE_SHORTLIST)




  useEffect(() => {
    setStatus(data?.shortlist?.status)
    setStartYear(data?.shortlist?.startingYear || 1)
    if (data?.shortlist?.status === "DECLINED") {
      setDeclineComments(data?.shortlist?.declineComments)
    }
    if (data?.shortlist?._id) {
      getAdmission({
        variables: {
          filter: { shortlist: { eq: data?.shortlist?._id } }
        }
      })
    }
  }, [data]);

  // console.log({ admittedData })


  const handleSubmit = async (e: any) => {
    e.preventDefault()

    await updateShortlisting({
      variables: {
        id: searchParams.id,
        input: {
          shortlistId: data?.shortlist?._id,
          status: status || "PENDING",
          startingYear: startYear || 1,
          declineComments: declineComments
        }
      }
    }).then(({ data }) => {
      if (data?.updateShortlisting?._id) {
        toast(JSON.stringify({ type: "success", title: "Shortlisted applicant updated successfully" }))
        refetch?.();
        setOpen(false)
      }
      else {
        toast(JSON.stringify({ type: "error", title: "Could not update shortlisted applicant" }))
      }
    })
  }

  const __setTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  useEffect(() => {
    __setTab("General")
  }, [open]);

  const ModalTabs = [
    {
      name: "General Information",
      href: "General",
    },
    {
      name: "Reference Information",
      href: "Reference",
    },
    {
      name: "Decision and Finalization",
      href: "Decision",
    },
  ];

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="4xl"
      loading={loading || applicationLoading || loadingAdmittedData}
      title="Shortlisted Application Review Information"
      description="Details of the application are shown below"
      hidePadding
      renderActions={() => (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white disabled:bg-gray-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit}
            disabled={tab !== "Decision"}
          >
            {updating ? "Saving..." : "Save"}
          </button>
          {withPermissions(["*:*", "shortlist:*", "shortlist:programme-offer"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium disabled:bg-gray-400 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => setModal('offer')}
            >
              Offer Programme
            </button>
          )}
        </>
      )}
    >
      <>
        <div className='block'>
          <div className='border-b border-gray-200 bg-white px-6'>
            <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
              {ModalTabs.map((tabData) => (
                <button
                  key={tabData.name}
                  onClick={wrapClick(__setTab(tabData.href))}
                  className={classNames(
                    tab === tabData.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    tab === tabData.href ? "page" : undefined
                  }
                >
                  {tabData.name}
                </button>
              ))}
            </nav>
          </div>
        </div>

        <div className='flex-1 w-full min-h-[65vh] max-h-[55vh] overflow-y-auto  sm:p-6'>
          {tab === "General" && (
            <ShortlistReviewView
              shortlist={data?.shortlist}
            />
          )}
          {tab === "Reference" && (
            <ShortlistReferenceReviewView shortlist={data?.shortlist} />
          )}
          {tab === "Decision" && (
            <div className="space-y-3">
              <SelectInput
                id="startYear"
                label="Set Starting Year"
                placeholder="Choose start year"
                options={["1", "2", "3"]}
                handleChange={(event: any) => {
                  setStartYear(parseInt(event.target.value))
                }}
                handleBlur={() => null}
                values={{ startYear }}
              />
              <SelectInput
                id="status"
                label="Set Status"
                placeholder="Choose status"
                options={["PENDING", "APPROVED", "DECLINED"]}
                handleChange={(event: any) => {
                  setStatus(event.target.value)
                }}
                handleBlur={() => null}
                values={{ status }}
              />
              {status === "DECLINED" &&
                <TextArea
                  id="declineComments"
                  label="Declined Comments"
                  placeholder="Enter reason for decline"
                  handleChange={(event: any) => {
                    setDeclineComments(event.target.value)
                  }}
                  handleBlur={() => null}
                  values={{ declineComments }}
                />
              }
            </div>
          )}
        </div>

      </>
    </Modal>
  )
}