import { XMarkIcon } from "@heroicons/react/24/outline";
import { RichTextArea } from "components/core"
import { useFormik } from "formik"
import _ from "lodash";
import { FC, useEffect } from "react"
import { classNames, wrapClick } from "utils";

const EXAMINATION_GRADING = {
  "ALEVEL": [
    { label: "- select -", value: "" },
    { label: "A*", value: 1 },
    { label: "A", value: 2 },
    { label: "B", value: 3 },
    { label: "C", value: 4 },
    { label: "D", value: 5 },
    { label: "E", value: 6 },
    { label: "U", value: 7 },
  ],
  "OLEVEL": [
    { label: "- select -", value: "" },
    { label: "A*", value: 1 },
    { label: "A", value: 2 },
    { label: "B", value: 3 },
    { label: "C", value: 4 },
    { label: "D", value: 5 },
    { label: "E", value: 6 },
    { label: "F", value: 7 },
    { label: "G", value: 8 },
    { label: "U", value: 9 },
  ],
  "WASSCE": [
    { label: "- select -", value: "" },
    { label: "A1", value: 1 },
    { label: "B2", value: 2 },
    { label: "B3", value: 3 },
    { label: "C4", value: 4 },
    { label: "C5", value: 5 },
    { label: "C6", value: 6 },
    { label: "D7", value: 7 },
    { label: "E8", value: 8 },
    { label: "F9", value: 9 },
  ],
  "SSSCE": [
    { label: "- select -", value: "" },
    { label: "A", value: 1 },
    { label: "B", value: 2 },
    { label: "C", value: 3 },
    { label: "D", value: 4 },
    { label: "E", value: 5 },
    { label: "F", value: 6 },
  ]
}

const subjects = [
  "ANIMAL HUSBANDRY",
  "BASKETRY",
  "BIOLOGY",
  "BUILDING CONST",
  "BUSINESS MANAGEMENT",
  "CERAMICS",
  "CHEMISTRY",
  "CHRISTIAN RELIGIOUS STUDIES",
  "CLERICAL OFFICE DUTIES",
  "CLOTHING & TEXTILES",
  "CROP HUSB & HORTICULTURE",
  "DAGBANI",
  "DANGME",
  "ECONOMICS",
  "ENGLISH LANGUAGE",
  "EWE",
  "FANTE",
  "FINANCIAL ACCOUNTING",
  "FOODS & NUTRITION",
  "FRENCH",
  "GA",
  "GENERAL KNOWLEDGE IN ART",
  "GENERAL AGRICULTURE",
  "GEOGRAPHY",
  "GOVERNMENT",
  "GRAPHIC DESIGN",
  "HISTORY",
  "INFO. & COMM. TECHNOLOGY",
  "INTEGRATED SCIENCE",
  "ISLAMIC REL STUD",
  "JEWELLERY",
  "LEATHERWORK",
  "WOODWORK",
  "LITERATURE-IN-ENGLISH",
  "MANAGEMENT IN LIVING",
  "MATHEMATICS(CORE)",
  "MATHEMATICS(ELECT)",
  "MGT IN LIVING",
  "MUSIC",
  "PHYSICS",
  "PICTURE MAKING",
  "PRIN OF COST ACCOUNTING",
  "SCULPTURE",
  "SOCIAL STUDIES",
  "TECHNICAL DRAWING",
  "TEXTILES",
  "TWI(AKUAPEM)",
  "TWI(ASANTE)",
  "TYPEWRITING (40 W.P.M.)",
  "GONJA",
  "NZEMA",
];


const EXAMINATION_SUBJECTS = {
  "ALEVEL": [
    { label: "- select -", value: "" },
    ...subjects.map(subject => ({ label: subject, value: subject }))
  ],
  "OLEVEL": [
    { label: "- select -", value: "" },
    ...subjects.map(subject => ({ label: subject, value: subject }))
  ],
  "WASSCE": [
    { label: "- select -", value: "" },
    ...subjects.map(subject => ({ label: subject, value: subject }))
  ],
  "SSSCE": [
    { label: "- select -", value: "" },
    ...subjects.map(subject => ({ label: subject, value: subject }))
  ],
}

interface RequirementsInfoFormProps {
  handleNext: (values: any) => void
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const RequirementsInfoForm: FC<RequirementsInfoFormProps> = ({ initialValues, values, handleNext, handlePrevious, handleCancel }) => {

  const form = useFormik({
    initialValues,
    onSubmit: (values) => {
      // normalise items here
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })

  useEffect(() => {
    console.log({ vals: form?.values })
  }, [form]);

  console.log({ requirements: values })

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter((val: string, _id: number) => _id !== id),
    ])
  }

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        {values?.programmeInfo?.requirementTypes?.sort()?.map((requirementType: string, idx: number) => {
          const id = `requirements.${idx}`;
          console.log("ID: ", id)
          const specifications = _.get(form.values, `${id}.specifications`, []);
          console.log("SPECIFICATIONS: ", specifications)
          if (_.get(form.values, `${id}.type`) !== requirementType) {
            form.setFieldValue(`${id}.type`, requirementType);
          }

          return (
            <div className="pt-6 first:pt-0">
              <span className="text-xs font-light uppercase">{requirementType} Requirements</span>
              <div className="grid gap-6 mt-2">
                <div className="">
                  <RichTextArea
                    id={`${id}.description`}
                    label="Description"
                    placeholder="eg. 02.0304"
                    required={true}
                    {...form}
                  />
                </div>
                {["WASSCE", "SSSCE"].includes(requirementType) && (
                  <>
                    <div className="p-0">
                      <span className="text-xs font-light uppercase">Minimum Required Grade for {Math.abs(_.get(form.values, `${id}.numberOfPassedSubjects`, ""))} subjects ({requirementType})</span>
                      <div className="grid grid-cols-5">
                        <div className="col-span-4">
                          <select
                            id={`${id}.defaultMinimumGrade`}
                            name={`${id}.defaultMinimumGrade`}
                            value={Math.abs(_.get(form.values, `${id}.defaultMinimumGrade`, ""))}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            className={classNames(
                              _.get(form.errors, `${id}.defaultMinimumGrade`) && _.get(form.touched, `${id}.defaultMinimumGrade`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                              " block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-1 border-gray-200 focus:border"
                            )}
                          >
                            {_.get(EXAMINATION_GRADING, requirementType, []).map((option: any, iddxx) => (
                              <option key={iddxx} value={parseInt(option.value)}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <input
                            type="number"
                            name={`${id}.numberOfPassedSubjects`}
                            id={`${id}.numberOfPassedSubjects`}
                            value={Math.abs(_.get(form.values, `${id}.numberOfPassedSubjects`, ""))}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            placeholder="No. of subjects"
                            className={classNames(
                              _.get(form.errors, `${id}.numberOfPassedSubjects`) && _.get(form.touched, `${id}.numberOfPassedSubjects`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                              " block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-1 border-gray-200 focus:border"
                            )}
                          />
                        </div>

                      </div>

                    </div>
                    <div className="py">
                      <span className="block text-sm font-medium text-gray-700">
                        Specifications *
                      </span>
                      <div className="mt-1 w-full ">
                        <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded">
                          <thead className="bg-gray-50">
                            <tr className="divide-x divide-gray-200">
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-full"
                              >
                                Subject
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center"
                              >
                                Minimum Grade
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center"
                              >
                                Mandatory
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                              >
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {_.times(specifications?.length + 1, (iddx) => {
                              const idd = `${id}.specifications.${iddx}`;
                              const isLast = (specifications?.length || 0) === iddx
                              const specificationSubjects = specifications?.map((spec: any) => spec?.subject)
                              console.log({ specificationSubjects })
                              return (
                                <tr key={iddx} className="divide-x divide-gray-200">
                                  <td className="p-0">
                                    <select
                                      key={iddx}
                                      id={`${idd}.subject`}
                                      name={`${idd}.subject`}
                                      value={_.get(form.values, `${idd}.subject`, "")}
                                      onChange={form.handleChange}
                                      onBlur={form.handleBlur}
                                      className={classNames(
                                        _.get(form.errors, `${idd}.subject`) && _.get(form.touched, `${idd}.subject`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                        "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                      )}
                                    >
                                      {_.get(EXAMINATION_SUBJECTS, requirementType, []).map((option: any, iddxx) => (
                                        <option disabled={specificationSubjects.includes(option.value)} key={iddxx} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td className="p-0">
                                    <select
                                      key={iddx}
                                      id={`${idd}.requiredGrade`}
                                      name={`${idd}.requiredGrade`}
                                      value={_.get(form.values, `${idd}.requiredGrade`, "")}
                                      onChange={form.handleChange}
                                      onBlur={form.handleBlur}
                                      className={classNames(
                                        _.get(form.errors, `${idd}.requiredGrade`) && _.get(form.touched, `${idd}.requiredGrade`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                        "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                      )}
                                    >
                                      {_.get(EXAMINATION_GRADING, requirementType, []).map((option: any, iddxx) => (
                                        <option key={iddxx} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td className="p-0">
                                    <select
                                      key={iddx}
                                      id={`${idd}.mandatory`}
                                      name={`${idd}.mandatory`}
                                      value={_.get(form.values, `${idd}.mandatory`, "no")}
                                      onChange={form.handleChange}
                                      onBlur={form.handleBlur}
                                      className={classNames(
                                        _.get(form.errors, `${idd}.mandatory`) && _.get(form.touched, `${idd}.mandatory`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                        "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                      )}
                                    >
                                      <option value={'yes'}>
                                        Yes
                                      </option>
                                      <option value={'no'}>
                                        No
                                      </option>
                                    </select>
                                  </td>
                                  <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                                    {!isLast && (
                                      <button
                                        type="button"
                                        onClick={wrapClick(() => removeItem(`${id}.specifications`, iddx))}
                                        className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                      >
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        })}
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default RequirementsInfoForm