
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCpDZVCLhhHKaRIS8-lnudSsv5Bd8JlBg0",
  authDomain: "studio-onyx.firebaseapp.com",
  projectId: "studio-onyx",
  storageBucket: "studio-onyx.appspot.com",
  messagingSenderId: "830377847475",
  appId: "1:830377847475:web:5445837439fc671dbd5ec6",
  measurementId: "G-CTJ47SWE9Z",
  vapidKey: "BEX6QHihKWwOe2V4eBI8FwEN0sw8qWH0RP7lti-cgE_faSM33Igz8n0nS48e1SwZwqOToad9j51ManCL10V-y2M",
};

// Initialize Firebase
export default firebaseConfig;