import { AvatarUpload, SelectInput, TextInput } from "components/core";
import { CampusPicker, DepartmentPicker, SchoolPicker } from "containers";
import { FormikProps } from "formik";
import { userRoles } from "pages/users";
import { FC } from "react";

interface UserFormProps {
  form: FormikProps<{
    staffId: string;
    name: string;
    gender: string;
    phone: string;
    email: string;
    profileImageUrl: string;
    role: string;
    school: string;
    department: string;
    campus: string;
  }>;
}

const genders = [
  { label: "--- Select Gender ---", value: "" },
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
];

const UserForm: FC<UserFormProps> = ({ form }) => {
  console.log(form.initialValues)
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Staff Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div className="col-span-2">
            <AvatarUpload id="profileImageUrl" label="Photo" {...form} />
          </div>
          <div>
            <TextInput
              id="staffId"
              label="Staff ID"
              type="text"
              placeholder="eg. 101314"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="name"
              label="Full Name"
              type="text"
              placeholder="e.g. Enoch Nana Nyankah Mensah"
              {...form}
              required={true}
            />
          </div>

          <div>
            <TextInput
              id="phone"
              label="Phone Number"
              type="text"
              placeholder="e.g. 0550123292"
              {...form}
              required={true}
            />
          </div>

          <div>
            <TextInput
              id="email"
              label="Email Address"
              type="email"
              placeholder="e.g. nyankahmensah@gmail.com"
              {...form}
              required={true}
            />
          </div>
          <div>
            <SelectInput
              id="role"
              label="Role"
              placeholder="Role"
              required={true}
              options={userRoles}
              {...form}
            />
          </div>
          <div>
            <SelectInput
              id="gender"
              label="Gender"
              placeholder="eg. MALE"
              required={true}
              options={genders}
              {...form}
            />
          </div>
          {["DEAN", "DEPARTMENT_HEAD", "SCHOOL_ADMINISTRATOR", "SCHOOL_REGISTRAR"].includes(form.values.role) && (
            <div>
              <SchoolPicker
                id="school"
                label="School"
                placeholder="School Name"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue(field, value);
                  //form.setFieldValue("programme", null);
                }}
                rawId={true}
              />
            </div>
          )}

          {["DEPARTMENT_HEAD"].includes(form.values.role) && (
            <div>
              <DepartmentPicker
                id="department"
                label="Department"
                placeholder="Department Name"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue(field, value);
                  //form.setFieldValue("programme", null);
                }}
                rawId={true}
              />
            </div>
          )}

          {["DEAN", "DEPARTMENT_HEAD", "SCHOOL_REGISTRAR", "CAMPUS_OFFICER", "SCHOOL_ADMINISTRATOR"].includes(form.values.role) && (
            <div>
              <CampusPicker
                id="campus"
                label="Campus"
                placeholder="Campus Name"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue(field, value);
                  //form.setFieldValue("programme", null);
                }}
                rawId={true}
              />
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default UserForm;
