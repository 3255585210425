import { wrapClick, withPermissions } from "utils";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import ShortlistView from "components/cards/shortlist-applicant-view";

const GET_SHORTLISTED = gql`
query GetShortlisting($filter: ShortlistFilter!, $populate: [String]) {
  shortlist: getShortlisting(filter: $filter, populate: $populate) {
    _id
    code
    createdAt
    updatedAt
    applicant {
      code
      email
      _id
    }
    cycle {
      _id
      code
      name
    }
    examinationScore
    interviewScore
    programme {
      _id
      name
      requiresInterview
      requiresExamination
      level
    }
    lastUpdatedBy {
      _id
      name
    }
    createdBy {
      _id
      name
    }
    application {
      code
      personalInformation {
        profileImageUrl
        firstName
        lastName
      }
    }
  }
}
`

export default function ViewShortlistedContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_SHORTLISTED, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["lastUpdatedBy","createdBy", "programme", "applicant", "cycle", "application.personalInformation"]
    },
    notifyOnNetworkStatusChange: false,
    // pollInterval,
    fetchPolicy: 'network-only'
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  const reviewItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "investigate"
      })
    })
  }

  const disableReview = ({ requiresInterview, interviewScore, requiresExamination, examinationScore }: { requiresInterview: any, interviewScore: any, requiresExamination: any, examinationScore: any }) => {
    if (requiresInterview) {
      return !interviewScore
    }
    if (requiresExamination) {
      return !examinationScore
    }
    return false
  }

  const isReviewDisabled = disableReview({
    examinationScore: data?.shortlist?.examinationScore,
    interviewScore: data?.shortlist?.interviewScore,
    requiresExamination: data?.shortlist?.programme?.requiresExamination,
    requiresInterview: data?.shortlist?.programme?.requiresInterview
  })


  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Shortlisted Applicant Information"
      description="Details of the shortlisted applicant are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "admission-cycles:*", "admission-cycles:update"]) && (
            (data?.shortlist?.programme?.requiresInterview || data?.shortlist?.programme?.requiresExamination) && (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={wrapClick(updateItem)}
              >
                Enter Score
              </button>
            )
          )}
          {withPermissions(["*:*", "application:*", "shortlist:approve"])(
            <button
              type="button"
              disabled={isReviewDisabled}
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 disabled:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(reviewItem)}
            >
              Review Application
            </button>
          )}
        </>
      )}
    >
      <ShortlistView
        shortlist={data?.shortlist}
      />
    </Modal>
  )
}