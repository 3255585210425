import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { InvoiceView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_INVOICE = gql`
query GetInvoice($filter: InvoiceFilter!, $populate: [String]) {
  invoice:getInvoice(filter: $filter, populate: $populate) {
    _id
    code
    createdAt
    paidAt
    vendor {
      _id
      name
    }
    totalAmount
    name
    mode
    currency
    application {
      _id
      personalInformation {
        lastName
        firstName
        middleName
      }
      code
      cycle {
        name
        _id
      }
      level
      type
    }
    status
    applicant {
      email
      _id
    }
    vendorReference
    fxRates {
      USDXGHS
    }
  }
}
`

export default function ViewCampusContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_INVOICE, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["application", "application.personalInformation", "application.cycle", "applicant", "vendor"]
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "approve"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Invoice Information"
      description="Details of invoice are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "invoices:*", "invoices:approve"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Approve
            </button>
          )}
        </>
      )}
    >
      <InvoiceView
        invoice={data?.invoice}
      />
    </Modal>
  )
}