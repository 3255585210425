import * as Yup from 'yup';
import { phoneNumberValidation } from '../admin/schema';

export const SchoolSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is a required field'),
  phone: phoneNumberValidation(),
  name: Yup.string().required('School Name is a required field'),
  officeAddress: Yup.string().required('Office Address is a required field'),
  digitalAddress: Yup.string().required('Digital Address is a required field'),
  dean: Yup.object({
    _id: Yup.string().required('Dean is a required field')
  }),
});

// .when({
//   is: (value: string) => value && value[0] === '0',
//   then: Yup.string().length(10),
//   otherwise: Yup.string().length(12),
// })

export const SchoolInitialValues = {
  email: "",
  phone: "",
  name: "",
  officeAddress: "",
  digitalAddress: "",
  dean: "",
}