import { ResponsiveCirclePacking, ComputedDatum } from "@nivo/circle-packing";


type CirclePackingChartProps = {
  data: any;
  valueAccessor?: string;
  idAccessor?: string;
  showLeavesOnly?: boolean;
  chartLabel?: (data: ComputedDatum<any>) => string
  tooltipLabel?: (data: ComputedDatum<any>) => any;
};

const CirclePackingChart = ({
  showLeavesOnly,
  data,
  idAccessor,
  valueAccessor,
  chartLabel = (data) => `${data?.id}`, 
  tooltipLabel = undefined
}: CirclePackingChartProps) => (
  <ResponsiveCirclePacking
    data={data}
    margin={{ top: 10, right: 20, bottom: 10, left: 20 }}
    id={idAccessor}
    value={valueAccessor}
    colors={{ datum: "data.color" }}
    colorBy="id"
    padding={0}
    enableLabels={true}
    leavesOnly={showLeavesOnly}
    labelsSkipRadius={10}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 2]],
    }}
    tooltip={tooltipLabel}
    borderWidth={1}
    borderColor={{
      from: "color",
      modifiers: [["darker", 0.5]],
    }}
    label={chartLabel}
  />
);

export default CirclePackingChart;
