import { classNames, wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { DownloadProfileForm, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import AdmittedView from 'components/cards/admitted-applicant-view';

const EMAIL_PROFILE = gql`
mutation EmailApplicationDocuments($input: EmailApplicationDocumentInput!) {
  emailApplicationDocuments(input: $input) {
    _id
  }
}
`

const GET_ADMISSION = gql`
query GetAdmission($filter: AdmissionFilter!, $populate: [String]) {
  admission: getAdmission(filter: $filter, populate: $populate) {
   _id
    code
    createdAt
    updatedAt
    applicant {
      code
      email
      _id
    }
    application {
      code
      personalInformation {
        firstName
        lastName
        profileImageUrl
      }
      _id
    }
    programme {
      _id
      name
      level
      requiresInterview 
      requiresExamination 
    }
    shortlist {
      startingYear
      offeredProgramme {
        _id
        name
      }
      offeredProgrammeRemark
    }
    department {
      name
      _id
    }
    school {
      _id
      name
    }
    createdBy {
      _id
      name
    }
    applicationName
    bankBranch
    bankName
    bankTransactionCode
    bankTransactionImage
    offeringType
    enrolled
    dateOfPayment
    applicationCode
    campus {
      _id
      name
    }
   
  }
}
`

export default function DownloadProfile({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();

  const [emailProfile, { loading }] = useMutation(EMAIL_PROFILE)

  const { data, loading: loadingAdmission } = useQuery(GET_ADMISSION, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["programme", "applicant", "programme.department", "application.personalInformation", "department", "createdBy", "school", "campus", "shortlist"]
    },
    notifyOnNetworkStatusChange: false,
    // pollInterval,
    fetchPolicy: 'network-only'
  });

  const form = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      await emailProfile({
        variables: {
          input: {
            applicationId: data?.admission?.application?._id,
            ...(values?.email && { email: values?.email })
          }
        }
      }).then(({ data }) => {
        if (data?.emailApplicationDocuments) {
          toast(JSON.stringify({ type: "success", title: `The Profile will be emailed to you shortly` }))
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not start process profile" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Download Profile"
      description="Enter an email to send the profile to"
      loading={loadingAdmission}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className={classNames(
              loading ? "cursor-not-allowed" : "cursor-pointer",
              "w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 disabled:bg-gray-400 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            )}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Processing Profile..." : "Download Profile"}
          </button>
        </>
      )}
    >
      <AdmittedView
        admitted={data?.admission}
      />

      <div className='border border-dashed border-gray-400 mt-10 px-5 py-5 rounded-md'>
        <DownloadProfileForm
          form={form}
        />
      </div>

    </Modal>
  )
}