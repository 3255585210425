import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, StepWizardStatus } from "components";
import _ from "lodash";
import dayjs from "dayjs";
import { FC, useEffect, useState, useMemo } from "react"
import { useNavigate, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { currentConfigVar } from "apollo/cache/config";
import ApplicationDetailsContainer from "./application-details";
import ProgrammeDetailsContainer from "./programme-info";
import PersonalDetailsContainer from "./personal-info";
import EducationDetailsContainer from "./education-info";
import EmploymentDetailsContainer from "./employment-info";
import AdditionalDetailsContainer from "./additional-info";
import ReferenceInfoSummary from "components/cards/application-details-view/reference";
import ReferenceDetailsContainer from "./reference-info";
import { useUrlState } from "utils";
import Loader from "components/layouts/loader";


const GET_APPLICATION = gql`
  query GetApplication($filter: ApplicationFilter!, $populate: [String]) {
    application: getApplication(filter: $filter, populate: $populate) {
      _id
      code
      personalInformation {
        lastName
        middleName
        firstName
        dateOfBirth
        placeOfBirth
        gender
        maritalStatus
        numberOfChildren
        nationality
        isPhysicallyChallenged
        physicalChallenges
        email
        phone
        secondaryPhone
        bioFileUrl
        bioFileUrls
        emergencyContacts {
          name
          relationship
          phone
          email
        }
        profileImageUrl
      }
      programmeInformation {
        preferredCampus {
          _id
          code
          name
          phone
          email
          digitalAddress
          officeAddress
          createdAt
          updatedAt
        }
        preferredOfferingType
        programmeSelections {
          programme {
            _id
            code
            name
            level
            tracks
            requiresInterview
            maximumInterviewScore
            requiredInterviewScore
            interviewScoreWeight
            requiresExamination
            maximumExaminationScore
            requiredExaminationScore
            examinationScoreWeight
            createdAt
            updatedAt
          }
          school {
            _id
            name
          }
        }
      }
      educationalHistoryInformation {
        type
        history {
          institution
          idNumber
          programme
          graduatingMonth
          graduatingYear
          cgpa
          gpaScale
          subjectGrades {
            subject
            grade
          }
          class
          certificateFileUrl
          transcriptFileUrl
          evaluationFileUrl
          essayFileUrl
          cvFileUrl
        }
      }
      employmentHistoryInformation {
        organization
        title
        startDate
        endDate
      }
      extraInformation {
        isExistingStudent
        existingStudentId
        existingStudentProgramme {
          _id
          code
          name
          description
          level
          createdAt
          updatedAt
        }
        sponsorType
        sponsorDetails {
          organization
          name
          title
          email
          phone
        }
      }
      referencesInformation {
        academic {
          organization
          name
          title
          address
          email
          phone
        }
        nonAcademic {
          organization
          name
          title
          address
          email
          phone
        }
      }
      status
      createdAt
      updatedAt
    }
  }
`

const applicationSteps = [
  {
    name: "Personal Information",
    description: "Details",
    accessor: "personalInformation",
    Component: PersonalDetailsContainer,
  },
  {
    name: "Choice of School",
    description: "Details",
    accessor: "programmeInformation",
    Component: ProgrammeDetailsContainer,
  },
  {
    name: "Education History",
    description: "Details",
    accessor: "educationalHistoryInformation",
    Component: EducationDetailsContainer,
  },
  {
    name: "Employment History",
    description: "Details",
    accessor: "employmentHistoryInformation",
    Component: EmploymentDetailsContainer,
  },
  {
    name: "Additional Details",
    description: "Details",
    accessor: "extraInformation",
    Component: AdditionalDetailsContainer,
  },
  {
    name: "Reference Details",
    description: "Details",
    accessor: "referencesInformation",
    Component: ReferenceDetailsContainer,
  },
  {
    name: "Admission Letter",
    description: "Details",
    accessor: null,
    Component: ApplicationDetailsContainer,
  },
]

const ApplicationPage: FC = () => {
  const { pollInterval, dateFormat } = useReactiveVar(currentConfigVar);
  const [page, setPage] = useUrlState("page");

  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(8);

  const { params } = useMatch<LocationGenerics>();

  const { data, refetch, loading } = useQuery(GET_APPLICATION, {
    variables: {
      filter: {
        _id: {
          eq: params.application
        }
      },
      populate: [
        "programmeInformation",
        "programmeInformation.preferredCampus",
        "programmeInformation.programmeSelections.programme",
        "programmeInformation.programmeSelections.school",
        "referencesInformation.academic",
        "referencesInformation.nonAcademic",
        "extraInformation.existingStudentProgramme",
      ]
    },
    pollInterval,
    notifyOnNetworkStatusChange: false
  });

  useEffect(() => {
    setPage(1)
    setStep(1)
  }, []);

  useEffect(() => {
    setStep(page)
  }, [page]);

  const filteredFormSteps = useMemo(() => applicationSteps.filter((_step) => true), [])

  const steps = useMemo(() => [
    ...filteredFormSteps,
  ].map((rawStep, href) => ({ ...rawStep, href: href + 1 }))
    , [filteredFormSteps])


  const renderView = () => {
    const currentStep = step - 1 <= 0 ? 0 : (step - 1) || 0
    const { Component, accessor } = steps[currentStep];
    return <Component
      values={accessor ? _.get(data?.application, accessor) : data?.application}
      id={_.get(data?.application, "_id")}
      code={_.get(data?.application, "code")}
      status={_.get(data?.application, "status")}
      createdAt={_.get(data?.application, "createdAt")}
      updatedAt={_.get(data?.application, "updatedAt")}
    // refetch={refetch}
    />
  }

  console.log({ steps })

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-full bg-gray-50">
      <div className="bg-gray-100 shadow-xl flex-1 flex p-6 overflow-hidden relative">
        <div className="w-96 justify-center flex flex-col space-y-6">
          <div className="p-4 border border-dashed border-gray-300 rounded grid grid-cols-2 gap-6 mr-12">
            <div>
              <span className="block text-md font-light text-gray-700">
                Application Code
              </span>
              <div className="mt-1 block w-full text-md">
                {data?.application?.code || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-md font-light text-gray-700">
                Submission Date
              </span>
              <div className="mt-1 block w-full text-md">
                {dayjs(data?.application?.createdAt).format(dateFormat)}
              </div>
            </div>

          </div>
          <StepWizardStatus
            steps={steps}
            step={step}
            setStep={setStep}
            lastStep={lastStep}
          />
        </div>
        <div className="flex flex-1 overflow-hidden bg-white rounded">
          {loading ? <Loader /> : renderView()}
        </div>
      </div>
    </main>
  )
}

export default ApplicationPage;
