import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { AdmissionProgrammeView, Modal } from 'components';
import { Action } from 'components/buttons/action-button';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { withPermissions, wrapClick } from 'utils';

const GET_ADMISSION_PROGRAMME = gql`
query GetAdmissionProgramme($filter: AdmissionProgrammeFilter!, $populate: [String]) {
  admissionProgramme: getAdmissionProgramme(filter: $filter, populate: $populate) {
    _id
    code
    createdAt
    updatedAt
    name
    duration
    school {
      _id
      code
      name
    }
    level
    department {
      _id
      name
    }
    programme {
      _id
      code
      name
      description
      level
    }
    tracks
    offerings {
      campus {
        _id
        code
        name
      }
      types
    }
    requirements {
      type
      description
      specifications {
        subject
        requiredGrade
        mandatory
      }
      defaultMinimumGrade
      numberOfPassedSubjects
    }
    requiresInterview
    maximumInterviewScore
    requiredInterviewScore
    interviewScoreWeight
    requiresExamination
    maximumExaminationScore
    requiredExaminationScore
    examinationScoreWeight
    admissionQuota
    hasAdmissionQuota
    createdBy {
      _id
      name
      email
      profileImageUrl
    }
    lastUpdatedBy {
      _id
      name
      email
      profileImageUrl
    }
  }
}
`

const GET_PROGRAMME = gql`
query GetProgramme($populate: [String], $filter: ProgrammeFilter!) {
  getProgramme(populate: $populate, filter: $filter) {
    _id
    name
    school {
      _id
      name
    }
    department {
      _id
      name
    }
  }
}
`

interface ViewAdmissionProgrammeContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void
}

const ViewAdmissionProgrammeContainer: FC<ViewAdmissionProgrammeContainerProps> = ({ open, setOpen, refetch }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_ADMISSION_PROGRAMME, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["createdBy", "lastUpdatedBy", "school", "programme", "offerings.campus", "department"]
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });

  const [getProgramme, { data: programmeData, loading: loadingProgramme }] = useLazyQuery(GET_PROGRAMME, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        _id: {
          eq: data?.admissionProgramme?.programme._id || ""
        }
      }
    }
  })

  useEffect(() => {
    if (data?.admissionProgramme?.programme?._id) {
      getProgramme({
        variables: {
          filter: {
            _id: {
              eq: data?.admissionProgramme?.programme._id || ""
            }
          }
        }
      })
    }
  }, [data]);

  const hasDifferentReferences = (programmeData?.getProgramme?.school?._id !== data?.admissionProgramme?.school._id) || (programmeData?.getProgramme?.department?._id !== data?.admissionProgramme?.department._id)

  const hasDifferentNames = programmeData?.getProgramme?.name !== data?.admissionProgramme?.name

  const disableReconfigureAdmissionProgramme = useMemo(() => hasDifferentNames && (!hasDifferentReferences || loading || loadingProgramme), [hasDifferentReferences, hasDifferentNames, loading, loadingProgramme])

  const navigateItem = (action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: action
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Admission Programme Information"
      loading={loading}
      description="Details of programme are shown below"
      size='4xl'
      renderActions={() => (
        <div className='space-x-2 ml-2'>
          {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
              onClick={wrapClick(navigateItem("update"))}
            >
              Edit
            </button>
          )}

          {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:delete"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
              onClick={wrapClick(navigateItem("delete"))}
            >
              Delete
            </button>
          )}
          {withPermissions(["*:*", "admission-programmes:*", "admission-programmes:configure"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
              onClick={wrapClick(navigateItem("configure"))}
            >
              Configure
            </button>
          )}
          <button
            type="button"
            disabled={!disableReconfigureAdmissionProgramme}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none disabled:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
            onClick={wrapClick(navigateItem("assign"))}
          >
            Reconfigure Programme
          </button>

        </div>
      )}
    >
      <AdmissionProgrammeView
        admissionProgramme={data?.admissionProgramme}
      />
    </Modal>
  )
}

export default ViewAdmissionProgrammeContainer