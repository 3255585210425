import { wrapClick } from 'utils';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Modal, ApplicationView } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useEffect } from 'react';

const GET_APPLICATION = gql`
query GetApplication($filter: ApplicationFilter!, $populate: [String]) {
  application :getApplication(filter: $filter, populate: $populate) {
    _id
    applicant {
      code
      email
    }
    createdAt
    code
    educationalHistoryInformation {
      history {
        programme
        institution
        cgpa
        class
        gpaScale
        graduatingYear
        graduatingMonth
        idNumber
      }
      type
    }
    level
    personalInformation {
      firstName
      email
      dateOfBirth
      gender
      lastName
      maritalStatus
      middleName
      nationality
      phone
      secondaryPhone
      profileImageUrl
    }
    programmeInformation {
      preferredCampus {
        _id
        name
      }
      preferredOfferingType
      programmeSelections {
        programme {
          department {
            name
          }
          school {
            name
          }
          name
          requiresInterview
          examinationScoreWeight
          interviewScoreWeight
          level
        }
      }
    }
    status
    referencesInformation {
      academic {
        email
        name
        organization
        phone
        title
      }
      nonAcademic {
        email
        name
        organization
        phone
        title
      }
    }
  }
}
`

const GET_INVOICE = gql`
query GetInvoice($filter: InvoiceFilter!, $populate: [String]) {
  invoices:getInvoices(filter: $filter, populate: $populate) {
    _id
    code
    status
    paidAt
    vendor {
      _id
      name
    }
    totalAmount
    mode
  }
}
`

export default function ViewServiceRequestContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();

  const { data, loading } = useQuery(GET_APPLICATION, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["programmeInformation", "programmeInformation.preferredCampus", "applicant"]
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: false
  });
  const [getInvoices, { data: invoiceData, loading: loadingInvoice }] = useLazyQuery(GET_INVOICE)

  const expandItem = () => {
    navigate({
      to: `./${searchParams.id}`,
    })
  }

  useEffect(() => {
    if (data?.application?._id) {
      getInvoices({
        variables: {
          filter: {
            application: {
              eq: data?.application?._id
            }
          },
          populate: ['vendor']
        }
      })
    }
  }, [data]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Application Information"
      description="Details of application are shown below"
      renderActions={() => (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(expandItem)}
          >
            View More
          </button>
        </>
      )}
    >
      <ApplicationView
        application={data?.application}
        invoice={{ ...invoiceData?.invoices?.[0], loading: loadingInvoice }}
      />
    </Modal>
  )
}
