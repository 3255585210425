import { FC } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { OverviewContainer } from "./containers";
import { useReactiveVar } from "@apollo/client";
import { currentUserVar } from "apollo/cache/auth";
import ApplicantsBarChartContainer from "containers/applicants-bar-chart";
import ApplicantsLevelChartContainer from "containers/applications-pie-chart";
import ApplicationSchoolChartContainer from "containers/applications-school-chart";
import ApplicationCampusChartContainer from "containers/applications-bar-campus";
import InvoiceChartContainer from "containers/invoices-chart";

const DashboardPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>()

  const cycle = searchParams?.cycle;

  return (
    <div className="flex-1 min-h-full mx-auto  p-4 sm:p-6 overflow-y-auto bg-gray-50">
      <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
        <div className="col-span-12">
          <OverviewContainer />
        </div>

        <div className="w-full col-span-12">
          <div className="w-full h-[400px] grid grid-cols-12 gap-4">
            <div className="col-span-8 bg-white rounded shadow w-full h-full flex flex-col p-5">
              <ApplicantsBarChartContainer
                cycle={cycle}
                dates={{ start: "", end: "" }}
              />
            </div>
            <div className="col-span-4 bg-white rounded shadow w-full h-full flex flex-col p-5">
              <ApplicantsLevelChartContainer />
            </div>
          </div>
        </div>

        <div className="w-full col-span-12">
          <div className="w-full h-[400px] grid grid-cols-12 gap-4">
            <div className="col-span-4 bg-white rounded shadow w-full h-full flex flex-col p-5">
              <ApplicationSchoolChartContainer cycle={cycle} />
            </div>
            <div className="col-span-4 bg-white rounded shadow w-full h-full flex flex-col p-5">
              <ApplicationCampusChartContainer cycle={cycle} />
            </div>
            <div className="col-span-4 bg-white rounded shadow w-full h-full flex flex-col p-5">
              <InvoiceChartContainer cycleId={cycle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
