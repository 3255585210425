import { Avatar } from "components/core";
import { FC } from "react";
import { currentConfigVar } from "apollo/cache/config";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { useReactiveVar } from "@apollo/client";

dayjs.extend(localeData);

interface AdmittedViewProps {
  admitted: {
    _id: string;
    code: string;
    createdAt: string;
    updatedAt: string;
    lastUpdatedBy: {
      _id: string;
      name: string;
    };
    applicant: {
      code: string;
      email: string;
      _id: string;
    };
    application: {
      code: string;
      personalInformation: {
        firstName: string;
        lastName: string;
        profileImageUrl: string;
      };
      _id: string;
    };
    campus: {
      _id: string
      name: string
    };
    programme: {
      _id: string;
      name: string;
      level: string;
      requiresInterview: boolean;
      requiresExamination: boolean;
    };
    shortlist: {
      startingYear: string;
      offeredProgramme: {
        _id: string;
        name: string;
      };
      offeredProgrammeRemark: string;
    };
    department: {
      name: string;
      _id: string;
    };
    school: {
      _id: string;
      name: string;
    };
    createdBy: {
      _id: string;
      name: string;
    };
    bankTransactionImage: string;
    applicationName: string;
    bankBranch: string;
    bankName: string;
    bankTransactionCode: string;
    offeringType: string;
    enrolled: boolean;
    dateOfPayment: string;
    applicationCode: string;
    indexNumber: string;
  };
}

const AdmittedView: FC<AdmittedViewProps> = ({ admitted }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);
  console.log({ admitted })

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Applicant Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="">
            <Avatar
              alt={
                [
                  (
                    admitted?.application?.personalInformation?.lastName || ""
                  )?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"
              }
              src={admitted?.application?.personalInformation?.profileImageUrl}
              size="xl"
            />
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {admitted?.application?.code || "N/A"}
            </div>
          </div>

          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">Name</span>
            <div className="mt-1 block w-full sm:text-sm uppercase">
              {[
                admitted?.application?.personalInformation?.lastName,
                admitted?.application?.personalInformation?.firstName,
              ].join(" ") || "N/A"}
            </div>
          </div>

          <div></div>

          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Email
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admitted?.applicant?.email || "N/A"}
            </div>
          </div>
          {
            admitted?.indexNumber &&
            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Index Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {admitted?.indexNumber || "N/A"}
              </div>
            </div>
          }
        </div>
      </div>

      <div>
        <span className="text-xs font-light">Programme Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Programme
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admitted?.programme?.name || "N/A"}
            </div>
          </div>

          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Department
            </span>
            <div className="mt-1 block w-full sm:text-sm capitalize">
              {admitted?.department?.name || "N/A"}
            </div>
          </div>

          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              School
            </span>
            <div className="mt-1 block w-full sm:text-sm capitalize">
              {admitted?.school?.name || "N/A"}
            </div>
          </div>

          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Campus
            </span>
            <div className="mt-1 block w-full sm:text-sm capitalize">
              {admitted?.campus?.name || "N/A"}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Level
            </span>
            <div className="mt-1 block w-full sm:text-sm capitalize">
              {admitted?.programme?.level.toLocaleLowerCase() || "N/A"}
            </div>
          </div>

          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Starting Year
            </span>
            <div className="mt-1 block w-full sm:text-sm capitalize">
              {admitted?.shortlist?.startingYear || "1"}
            </div>
          </div>
        </div>
      </div>

      {admitted?.dateOfPayment && (
        <div>
          <span className="text-xs font-light">Bank Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Bank Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {admitted?.bankName || "N/A"}
              </div>
            </div>

            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Bank Branch
              </span>
              <div className="mt-1 block w-full sm:text-sm capitalize">
                {admitted?.bankBranch || "N/A"}
              </div>
            </div>

            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Transaction Code
              </span>
              <div className="mt-1 block w-full sm:text-sm capitalize">
                {admitted?.bankTransactionCode || "N/A"}
              </div>
            </div>

            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Date of Payment
              </span>
              <div className="mt-1 block w-full sm:text-sm capitalize">
                {admitted?.dateOfPayment
                  ? dayjs(admitted?.dateOfPayment).format(
                    dateFormat + " hh:mm A"
                  )
                  : "N/A"}
              </div>
            </div>
            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Bank Transaction Image
              </span>
              <div className="mt-1 block w-full sm:text-sm capitalize">
                {admitted?.bankTransactionImage
                  ? (<a href={admitted?.bankTransactionImage} className="text-blue-500 underline" target="_blank" rel="noreferrer">View Image</a>
                  )
                  : "N/A"}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pt-6">
        <span className="text-xs font-light">Meta Information</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admitted?.createdAt
                ? dayjs(admitted?.createdAt).format(dateFormat + " hh:mm A")
                : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admitted?.createdBy?.name ? admitted?.createdBy?.name : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admitted?.updatedAt
                ? dayjs(admitted?.updatedAt).format(dateFormat + " hh:mm A")
                : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {admitted?.lastUpdatedBy?.name ? admitted?.lastUpdatedBy?.name : "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmittedView;
