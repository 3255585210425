export default [
    {"name": "", "value": "*:*"},

    {"name": "", "value": "admission-cycles:*"},
    {"name": "", "value": "admission-cycles:manage"},
    {"name": "", "value": "admission-cycles:create"},
    {"name": "", "value": "admission-cycles:update"},
    {"name": "", "value": "admission-cycles:import"},
    {"name": "", "value": "admission-cycles:delete"},
    {"name": "", "value": "admission-cycles:clone"},

    {"name": "", "value": "admission-programmes:*"},
    {"name": "", "value": "admission-programmes:manage"},
    {"name": "", "value": "admission-programmes:create"},
    {"name": "", "value": "admission-programmes:configure"},
    {"name": "", "value": "admission-programmes:update"},
    {"name": "", "value": "admission-programmes:assign"},
    {"name": "", "value": "admission-programmes:import"},
    {"name": "", "value": "admission-programmes:delete"},
    {"name": "", "value": "admission-programmes:suspend"},

    {"name": "", "value": "application:*"},
    {"name": "", "value": "application:manage"},
    {"name": "", "value": "application:import"},
    {"name": "", "value": "application:import-waec"},
    {"name": "", "value": "application:upload-grades"},
    {"name": "", "value": "application:shortlist"},

    {"name": "", "value": "admissions:*"},
    {"name": "", "value": "admissions:manage"},
    {"name": "", "value": "admissions:import"},
    {"name": "", "value": "admissions:export"},
    {"name": "", "value": "admissions:transfer"},
    {"name": "", "value": "admissions:upload-admissions"},

    {"name": "", "value": "enrollment:*"},
    {"name": "", "value": "enrollment:create"},
    {"name": "", "value": "enrollment:manage"},
    {"name": "", "value": "enrollment:import"},
    {"name": "", "value": "enrollment:export"},

    {"name": "", "value": "shortlist:*"},
    {"name": "", "value": "shortlist:manage"},
    {"name": "", "value": "shortlist:create"},
    {"name": "", "value": "shortlist:update"},
    {"name": "", "value": "shortlist:import"},
    {"name": "", "value": "shortlist:approve"},
    {"name": "", "value": "shortlist:review"},
    {"name": "", "value": "shortlist:initiate"},
    {"name": "", "value": "shortlist:programme-offer"},

    {"name": "", "value": "campuses:*"},
    {"name": "", "value": "campuses:manage"},
    {"name": "", "value": "campuses:create"},
    {"name": "", "value": "campuses:update"},
    {"name": "", "value": "campuses:import"},
    {"name": "", "value": "campuses:delete"},

    {"name": "", "value": "mailing:*"},
    {"name": "", "value": "mailing:manage"},
    {"name": "", "value": "mailing:create"},
    {"name": "", "value": "mailing:update"},
    {"name": "", "value": "mailing:import"},
    {"name": "", "value": "mailing:delete"},

    {"name": "", "value": "departments:*"},
    {"name": "", "value": "departments:manage"},
    {"name": "", "value": "departments:create"},
    {"name": "", "value": "departments:update"},
    {"name": "", "value": "departments:import"},
    {"name": "", "value": "departments:delete"},

    {"name": "", "value": "programmes:*"},
    {"name": "", "value": "programmes:manage"},
    {"name": "", "value": "programmes:create"},
    {"name": "", "value": "programmes:update"},
    {"name": "", "value": "programmes:import"},
    {"name": "", "value": "programmes:delete"},

    {"name": "", "value": "schools:*"},
    {"name": "", "value": "schools:manage"},
    {"name": "", "value": "schools:create"},
    {"name": "", "value": "schools:update"},
    {"name": "", "value": "schools:import"},
    {"name": "", "value": "schools:delete"},


    {"name": "", "value": "users:*"},
    {"name": "", "value": "users:manage"},
    {"name": "", "value": "users:create"},
    {"name": "", "value": "users:update"},
    {"name": "", "value": "users:import"},
    {"name": "", "value": "users:delete"},
    {"name": "", "value": "users:suspend"},

    {"name": "", "value": "reporting:*"},
    {"name": "", "value": "reporting:manage"},
    {"name": "", "value": "reporting:create"},
    {"name": "", "value": "reporting:update"},
    {"name": "", "value": "reporting:import"},
    {"name": "", "value": "reporting:delete"},

    {"name": "", "value": "invoices:*"},
    {"name": "", "value": "invoices:manage"},
    {"name": "", "value": "invoices:create"},
    {"name": "", "value": "invoices:approve"},
    {"name": "", "value": "invoices:configure"},

    {"name": "", "value": "vendors:*"},
    {"name": "", "value": "vendors:manage"},
    {"name": "", "value": "vendors:create"},
    {"name": "", "value": "vendors:update"},
    {"name": "", "value": "vendors:import"},
    {"name": "", "value": "vendors:delete"},

    {"name": "", "value": "tuition:*"},
    {"name": "", "value": "tuition:manage"},
    {"name": "", "value": "tuition:create"},
    {"name": "", "value": "tuition:update"},
    {"name": "", "value": "tuition:import"},
    {"name": "", "value": "tuition:delete"},

    {"name": "", "value": "voucher-classes:*"},
    {"name": "", "value": "voucher-classes:manage"},
    {"name": "", "value": "voucher-classes:create"},
    {"name": "", "value": "voucher-classes:update"},
    {"name": "", "value": "voucher-classes:import"},
    {"name": "", "value": "voucher-classes:delete"},
    {"name": "", "value": "voucher-classes:schedule"},
    {"name": "", "value": "voucher-classes:assign"},

] as const;