import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import { FC } from 'react'

type User = {
  _id: string;
  code: string;
  name: string;
  phone: string;
  email: string;
  profileImageUrl: string;
  role: string;
  meta: {
    isSuspended: boolean;
  };
}

interface DepartmentViewProps {
  department: {
    _id: string
    code: string
    digitalAddress: string
    email: string
    name: string
    officeAddress: string
    phone: string;
    school: {
      _id: string;
      code: string;
      name: string;
    };
    createdAt: string;
    updatedAt: string;
    createdBy: User;
    lastUpdatedBy: User;
  };
}

const DepartmentView: FC<DepartmentViewProps> = ({ department }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Department Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.code || "N/A"}
            </div>
          </div>


          <div>
            <span className="block text-sm font-light text-gray-700">
              School
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.school?.name || "N/A"}
            </div>
          </div>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.name || "N/A"}
            </div>
          </div>

        
        </div>
      </div>

      <div>
        <span className='text-xs font-light'>Contact & Location Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>

          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Email
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.email || "N/A"}
            </div>
          </div>

          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Phone
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.phone || "N/A"}
            </div>
          </div>

          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Digital Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.digitalAddress || "N/A"}
            </div>
          </div>

          <div className='col-span-1'>
            <span className="block text-sm font-light text-gray-700">
              Office Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.officeAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>

      <div className='pt-6'>
        <span className='text-xs font-light'>Meta Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.createdAt ? dayjs(department?.createdAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.createdBy?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.updatedAt ? dayjs(department?.updatedAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {department?.lastUpdatedBy?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default DepartmentView