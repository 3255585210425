import { FC, useEffect, useMemo } from 'react'
import { Link, Outlet, useMatch, useMatches } from 'react-location';

import { classNames, useUrlState, withPermissions, wrapClick } from 'utils'
import _ from "lodash"
import { Header } from 'components';
import { LocationGenerics } from 'router/location';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import { currentUserVar } from 'apollo/cache/auth';

const GET_ADMISSION_PROGRAMME_STATS = gql`
query Query($admissionProgrammeFilter: AdmissionProgrammeFilter, $applicationFilter: ApplicationFilter, $shortlistFilter: ShortlistFilter, $admissionsFilter: AdmissionFilter, $admissionsEnrolledFilter: AdmissionFilter, $voucherClassesFilter: VoucherClassFilter, $tuitionFilter: TuitionFilter) {
  programmesCount: getAdmissionProgrammesCount(filter: $admissionProgrammeFilter)
  applicationsCount: getApplicationsCount(filter: $applicationFilter)
  shortlistedCount:getShortlistCount(filter: $shortlistFilter)
  admissionCount:getAdmissionsCount(filter: $admissionsFilter)
  admissionEnrolledCount:getAdmissionsCount(filter: $admissionsEnrolledFilter)
  voucherClassesCount:getVoucherClassesCount(filter: $voucherClassesFilter)
  tuitionsCount:getTuitionsCount(filter: $tuitionFilter)
}
`

const AdmissionCycleLayout: FC = () => {
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);
  const { route, pathname } = useMatch<LocationGenerics>();


  const [modal, setModal] = useUrlState("modal");
  const matches = useMatches<LocationGenerics>()

  const cycle = matches?.[0]?.params?.admissionCycle

  const variables = useMemo(
    () => ({
      applicationFilter: {
        ...(cycle && { cycle: { eq: cycle } }),
        status: {
          notEq: "SHORTLISTED"
        }
      },
      shortlistFilter: {
        ...(cycle && { cycle: { eq: cycle } }),
      },
      admissionsFilter: {
        ...(cycle && { cycle: { eq: cycle } }),
      },
      admissionProgrammeFilter: {
        ...(cycle && { cycle: { eq: cycle }, deleted: { eq: false } }),
      },
      admissionsEnrolledFilter: {
        ...({ enrolled: { eq: true } }),
        ...(cycle && { cycle: { eq: cycle } }),
      },
      voucherClassesFilter: {
        ...(cycle && { cycle: { eq: cycle } }),
      },
      tuitionFilter: {
        ...(cycle && { cycle: { eq: cycle } }),
      },
    }),
    [cycle, matches]
  );

  const { data } = useQuery(GET_ADMISSION_PROGRAMME_STATS, {
    variables,
    pollInterval
  })

  const tabCounters = useMemo(() => {
    return [
      { tabName: "Overview", count: undefined },
      { tabName: "Applications", count: data?.applicationsCount || 0 },
      { tabName: "Tuition", count: data?.tuitionsCount || 0 },
      { tabName: "Programmes", count: data?.programmesCount || 0 },
      { tabName: "Shortlisted", count: data?.shortlistedCount || 0 },
      { tabName: "Admitted", count: data?.admissionCount || 0 },
      { tabName: "Accepted", count: data?.admissionEnrolledCount || 0 },
      { tabName: "Classes", count: data?.voucherClassesCount || 0 },
    ]
  }, [data])

  const currentUser = useReactiveVar(currentUserVar);

  const themeColors = {
    green: "bg-green-700 hover:bg-green-800",
    primary: "bg-primary-600 hover:bg-primary-700"
  }

  return (
    <div className="h-full flex flex-1 flex-col overflow-hidden ">
      <Header
        renderActions={() => (
          <>
            {_.last(matches)?.route.meta?.actions?.map((action) =>
              withPermissions(action?.permissions)(
                <button
                  type="button"
                  onClick={wrapClick(() => setModal(action.action))}
                  className={`ml-3 inline-flex items-center rounded border border-transparent  px-3 py-2 text-sm leading-4 text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 ${themeColors[action.theme || 'primary']} `}
                >
                  <action.icon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {action?.name}
                </button>
              )
            )}
          </>
        )}
      />
      <div className="block mt-4">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 px-4" aria-label="Tabs">
            {route.children?.map((tab: any) => {

              const countData = tabCounters.find(
                (_tab) => _tab.tabName === tab?.meta?.name
              );

              return withPermissions(tab.withPermissions || [])(
                <Link
                  key={tab.id}
                  to={pathname + "" + tab.path}
                  getInactiveProps={() => ({
                    className:
                      "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  })}
                  getActiveProps={() => ({
                    className: "border-primary-500 text-primary-600",
                    "aria-current": "page",
                  })}
                  className="flex whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                >
                  {tab.meta?.name}
                  {tab.meta && countData?.count ? (
                    <span
                      className={classNames(
                        true
                          ? "bg-primary-100 text-primary-600"
                          : "bg-gray-100 text-gray-900",
                        "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                      )}
                    >
                      {countData?.count || 0}
                    </span>
                  ) : null}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
      <div className="flex-1 w-full flex overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
}

export default AdmissionCycleLayout;