import { FC } from 'react'
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import _ from 'lodash';
import Loader from 'components/layouts/loader';
import ReferenceInfoView from './reference-view';
dayjs.extend(localeData)

const GET_APPLICATION = gql`
  query GetApplication($filter: ApplicationFilter!, $populate: [String]) {
    application: getApplication(filter: $filter, populate: $populate) {
      _id
      code
      referencesInformation {
        academic {
          organization
          name
          title
          address
          email
          phone
          recommendation {
            generalAssessment
            knownAs
            knownDuration
            knownDurationDetails
            knownDurationTo
            leadership
            maturity
            motivation
            overallRecommendation
            personalStrengths
            selfConfidence
            selfDiscipline
            weakness
            academicMaturity
          }
          approved
        }
        nonAcademic {
          organization
          name
          title
          address
          email
          phone
        }
      }
      status
      createdAt
      updatedAt
    }
  }
`

interface ShortlistViewProps {
  shortlist: {
    _id: string
    code: string
    createdAt: string
    updatedAt: string
    applicant: {
      code: string
      email: string
      _id: string
    }
    cycle: {
      _id: string
      code: string
      name: string
    }
    examinationScore: number
    interviewScore: number
    programme: {
      _id: string
      name: string
      level: string
      requiresInterview: boolean
      requiresExamination: boolean
    }
    lastUpdatedBy: {
      _id: string
      name: string
    }
    application: {
      _id: string
      personalInformation: {
        profileImageUrl: string
        firstName: string
        lastName: string
      }
    }
  };
  application?: any
  onLoadingChange?: (value: any) => void
}


const formSteps: any[] = [
  {
    name: 'Reference Info',
    description: 'Details',
    accessor: "referencesInformation",
    SummaryComponent: ReferenceInfoView,
  },
]

const ShortlistReferenceReviewView: FC<ShortlistViewProps> = ({ shortlist, application, onLoadingChange }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  const { data, loading } = useQuery(GET_APPLICATION, {
    variables: {
      filter: {
        _id: {
          eq: shortlist?.application?._id
        }
      },
      populate: [
        "referencesInformation.academic",
        "referencesInformation.academic.recommendation",
      ]
    },
    notifyOnNetworkStatusChange: false
  });


  return loading ?
    <Loader />
    :
    (
      <div className="space-y-6 divide-y divide-gray-200">
        {formSteps.map(({ SummaryComponent, ...summary }, idx) => {
          return (
            <div key={idx} className={idx === 0 ? " py-4" : " py-4 pt-6"}>
              <>
                <div className="flex justify-between items-center cursor-pointer">
                  <div>
                    <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">{summary?.name}</h3>
                    <p className="mt-1 text-xs text-gray-500">{summary?.description}</p>
                  </div>
                  <div />
                </div>

                <div className="mt-6">
                  <SummaryComponent
                    data={_.get(data?.application, summary.accessor)}
                    values={data?.application}
                    config={currentConfig}
                  />
                </div>
              </>
            </div>
          )
        })}
      </div>
    )
}

export default ShortlistReferenceReviewView;