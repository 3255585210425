import { wrapClick, withPermissions } from "utils";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { VoucherClassView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const GET_VOUCHER_CLASS = gql`
  query GetVoucherClass($filter: VoucherClassFilter!, $populate: [String]) {
    voucherClass: getVoucherClass(filter: $filter, populate: $populate) {
      _id
      code
      name
      description
      price
      createdBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        gender
        role
        createdAt
        updatedAt
      }
      lastUpdatedBy {
        _id
        code
        name
        phone
        email
        profileImageUrl
        gender
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`

export default function ViewVoucherClassContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_VOUCHER_CLASS, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["createdBy", "lastUpdatedBy"]
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Voucher Class Information"
      description="Details of voucher class are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "voucher-classes:*", "voucher-classes:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <VoucherClassView
        voucherClass={data?.voucherClass}
      />
    </Modal>
  )
}