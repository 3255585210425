import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { FC } from 'react'

interface CampusViewProps {
  campus: {
    code: string;
    name: string;
    phone: string;
    email: string;
    officeAddress: string;
    digitalAddress: string;
    createdBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    };
    lastUpdatedBy: {
      _id: string;
      code: string;
      name: string;
      phone: string;
      email: string;
      profileImageUrl: string;
      role: string;
      meta: {
        isSuspended: boolean;
      };
    }
    createdAt: string;
    updatedAt: string;
  };
}

const CampusView: FC<CampusViewProps> = ({ campus }) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Campus Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Campus Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.code || "N/A"}
            </div>
          </div>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Campus Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.name || "N/A"}
            </div>
          </div>

        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contact Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.phone || "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.email || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Digital Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.digitalAddress || "N/A"}
            </div>
          </div>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Office Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.officeAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Meta Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.createdAt ? dayjs(campus?.createdAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.createdBy?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.updatedAt ? dayjs(campus?.updatedAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Updated By
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {campus?.lastUpdatedBy?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampusView